import React,{useState} from 'react'
import Chart from 'react-apexcharts'
import { Col, Row } from 'react-bootstrap'
import { Segment } from "semantic-ui-react";

function Subscriptiongraph(props) {
    var userGraph=[]
    props.body.userGraph.forEach(element => {
        userGraph.push(element.month)
    })
    var userGraphdata=[]
    props.body.userGraph.forEach(element => {
        userGraphdata.push(element.count)
    })
    const [data,setDate]=useState({
        options: {
          chart: {
            id: 'apexchart-example',
            // width:"100%"
          },
          // color:"#fff",
        //   yaxis:{

        //   },
          xaxis: {
            categories:['january','february','march','april','may','june','july','august','september','october','november','december'],
          
          }
        },
        series: [
          {
            name: 'Subscribed users',
            color: '#e65174',
            data: userGraphdata
            //  data: [1,4,5,8,1000,3,5,1600,9,100,5,2000]
          },
          // {
          //   name: 'Users data',
          //   color: '#6c91rb',
          //   data: [1,4,5,8,1,3,5,7,9,0,5,6]
          // },
        ],
        // color:"#fff",
        
      })
      return (
            <>
             <Segment style={{width: "100%",
    height: "auto",
    marginTop: "20px",
    maxHeight: "100vh",
    minHeight: "55vh",backgroundColor:"transperant"}}>
            <Row>
            <h4 >Subscription Overview</h4>
            </Row>
             <Row>
            
             <Chart  options={data.options} series={data.series} type="bar" height={300} />
            
                

             </Row>
             </Segment>

           
        </>
      );
}

export default Subscriptiongraph