import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
// import "./Dashboard.css";
// import Server from "../../server/Server";

import Server from "../server/Server";
import { toast } from "react-toastify";
import { Row, Container, Col } from "react-bootstrap";
// import Card from 'react-bootstrap/Card'
// import { Col, Row, Form } from "react-bootstrap";
// import Button from 'react-bootstrap/Button';
// import Typography from "@mui/material/Typography";
// import { MDBCard, MDBCardTitle, MDBCardText, MDBCardBody, MDBCardHeader } from 'mdb-react-ui-kit';
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import ReactLoading from "react-loading";

import {
  // Link,
  // NavLink,
  // BrowserRouter,
  // Route,
  // Routes,
  // Switch,
  useNavigate,
} from "react-router-dom";
import UserCard from "./UserCard";
import ReportedUsers from "./ReportedUsers";
import PostCard from "./PostCard";
import Subscription from "./Subscription";
import { Segment } from "semantic-ui-react";
import UserGraph from "./UserGraph";
import Subscriptiongraph from "./Subscriptiongraph";

function Dashboard() {
  const token = localStorage.getItem("token");
  const history = useNavigate();
  const [data, setData] = useState(undefined);

  useEffect(() => {
    const fetchDatas = async () => {
      const res = await axios.get(Server.server.link + "dashboard", {
        headers: { "x-token": token },
      });

      if (res.data.code === 203) {
        localStorage.clear();
        history("/");
        toast.error(res.data.message);
      } else {
        let a = [];
        a.push(res.data.results);
        console.log(res.data.results, "daaaaaaa");
        setData(res.data.results)
      }
    };
    fetchDatas();
  }, []);
  return (
    <>
      {!data ? (
        <div className="hey">
          <ReactLoading
            type={"spokes"}
            color={"rgb(9 167 167)"}
            height={50}
            width={50}
          />
        </div>
      ) : (
        <Container fluid="sm">
          <Row>
            <Col lg={3} md={4} sm={6}>
              <UserCard body={data} />
            </Col>
            <Col lg={3} md={4} sm={6}>
              <ReportedUsers body={data} />
            </Col>
            <Col lg={3} md={4} sm={6}>
              <PostCard body={data} />
            </Col>
            <Col lg={3} md={4} sm={6}>
              <Subscription body={data} />
            </Col>
          </Row>
          <Row>
            <Col lg={6} md={12}>
              <UserGraph body={data} />
            </Col>
            <Col lg={6} md={12}>
            <Subscriptiongraph body={data} />
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
}

export default Dashboard;
