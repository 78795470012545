import React, { useEffect, useState, useRef } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Server from "../server/Server";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import CancelIcon from "@mui/icons-material/Cancel";
import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp';
import Button from "@mui/material/Button";
import DoneIcon from "@mui/icons-material/Done";
// import EditSharpIcon from '@mui/icons-material/EditSharp';
// import DeleteIcon from '@mui/icons-material/Delete';
import Stack from "@mui/material/Stack";
// import Popup from "../../../additional/Popup";
import TextField from "@mui/material/TextField";
// import Box from '@mui/material/Box';
import CloseIcon from "@mui/icons-material/Close";
// import ButtonGroup from '@mui/material/ButtonGroup';
// import BlockRoundedIcon from '@mui/icons-material/BlockRounded';
import LockOpenRoundedIcon from "@mui/icons-material/LockOpenRounded";
import BlockIcon from "@mui/icons-material/Block";
// import AppRegistrationRoundedIcon from '@mui/icons-material/AppRegistrationRounded';
// import Chip from '@mui/material/Chip';
import HttpsRoundedIcon from "@mui/icons-material/HttpsRounded";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
// import SearchIcon from '@mui/icons-material/Search';
// import Card from '@mui/material/Card';
// import CardContent from '@mui/material/CardContent';
// import CardMedia from '@mui/material/CardMedia';
// import Typography from '@mui/material/Typography';
// import { CardActionArea } from '@mui/material';
// import Grid from '@mui/material/Grid';
import Modal from "react-bootstrap/Modal";
import ReactLoading from "react-loading";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Tooltip } from "@mui/material";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function ReportList() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const history = useNavigate();
  const token = localStorage.getItem("token");
  const [users, setUsers] = useState(undefined);
  const [totalDoc, setTotaldoc] = useState("");
  const [id_neededTodelete, setIddelete] = useState([]);
  const [blockPopup, setBlockPopup] = useState(false);
  const [idNeedtoView, setIdView] = useState([]);
  const [detailsPopup, setDetailsPopup] = useState(false);
  const [number, setNumber] = useState(0);
  const [unBlockId, setUids] = useState([]);
  const [unBlockPopup, setunBlockPopup] = useState(false);
  const [searchs, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [shows, setShows] = useState(false);
  const [unblockHover, setunblockHover] = useState("");
  // const [number,]
  useEffect(() => {
    const fetchDatas = async () => {
      console.log(page, "this is current page");
      const res = await axios.get(Server.server.link + "reportedlist", {
        headers: {
          pageNumber: page,
          limit: rowsPerPage,
          search: searchs,
          "x-token": token,
        },
      });

      if (res.data.code === 203) {
        localStorage.clear();
        history("/");
        toast.error(res.data.message);
      } else {
        // toast.success(res.data.message)
        setUsers(res.data.data);
        setTotaldoc(res.data.totalDocuments);
        setNumber(res.data.totalDocuments);
        console.log(res.data, "daaaaaaa");
        // setTotalPages(res.data.totalPages)
      }
    };
    fetchDatas();
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  console.log("this the docs", rowsPerPage);

  const handleChangeRowsPerPage = (event, newPage) => {
    let a7 = totalDoc;
    console.log(event.target.value, "dataaa");
    if (event.target.value !== "all") {
      setRowsPerPage(+event.target.value);
      setPage(0);
    }
    if (event.target.value === "all") {
      setNumber(a7);
      setRowsPerPage(totalDoc);
      setPage(0);
    }
  };

  const handleMouseOver = () => {
    var id = "unblockbutton";
    setunblockHover(id);
    //  ?
  };

  const handleMouseOut = () => {
    var id = "";
    setunblockHover(id);
  };

  const handleMouseOverblock = () => {
    var id = "block";
    setunblockHover(id);
    //  ?
  };

  const handleMouseOutblock = () => {
    var id = "";
    setunblockHover(id);
  };

  const handleMouseOverView = () => {
    var id = "view";
    setunblockHover(id);
    //  ?
  };

  const handleMouseOutView = () => {
    var id = "";
    setunblockHover(id);
  };

  let a = 1;
  const h = () => {
    setShow(false);
  };
  const adminBlock = async (id) => {
    console.log("......asdfhwerf", id);
    let arr = [];
    arr.push({ userId: id });
    setIddelete(arr);
    setShow(true);
  };
  const onHide = () => {
    setShow(false);
  };

  const adminBlockUser = async (id) => {
    axios
      .post(
        Server.server.link + "blockUser",
        { userId: id },
        { headers: { "x-token": token } }
      )
      .then(async (respo) => {
        if (respo.data.code == 203) {
          toast.error(respo.data.message);
          history("/");
        } else {
          toast.success(respo.data.message);
          setShow(false);
          setIddelete([]);
          const res = await axios.get(Server.server.link + "reportedlist", {
            headers: {
              pageNumber: page,
              limit: rowsPerPage,
              "x-token": token,
              search: searchs,
            },
          });
          if (res.data.code === 203) {
            localStorage.clear();
            history("/");
            toast.error(res.data.message);
          } else {
            setTotaldoc(res.data.totalDocuments);
            setUsers(res.data.data);
            history("/home/reportTable");
          }
        }
      });
  };

  var unBlockDatas = async (id) => {
    console.log("......id id id i don't like it ", id);
    let arr = [];
    arr.push({ userId: id });
    setUids(arr);
    setShows(true);
  };

  const unblockUser = (id) => {
    axios
      .post(
        Server.server.link + "unblockUser",
        { userId: id },
        { headers: { "x-token": token } }
      )
      .then(async (respo) => {
        if (respo.data.code == 203) {
          localStorage.clear();
          toast.error(respo.data.message);
          history("/");
        } else if (respo.data.code == 202) {
          toast.error(respo.data.message);
          setShows(false);
        } else {
          toast.success(respo.data.message);
          setShows(false);
          const res = await axios.get(Server.server.link + "reportedlist", {
            headers: {
              pageNumber: page,
              limit: rowsPerPage,
              search: searchs,
              "x-token": token,
            },
          });
          if (res.data.code === 203) {
            localStorage.clear();
            history("/");
            toast.error(res.data.message);
          } else {
            setTotaldoc(res.data.totalDocuments);
            setUsers(res.data.data);
            history("/home/reportTable");
          }
        }
      });
  };

  const details = async (id) => {
    {
      users.map((row) => {
        if (row._id === id) {
          history(`/home/reportedDetails/${id}`);
        }
        // history(`/Dashboard/reportuser/${id}`)
      });
    }
  };

  const searType = async (e) => {
    setSearch(e.target.value);
    console.log(e.target.value, "this is the searched key");
    let a = e.target.value;
    const res = await axios.get(Server.server.link + "reportedlist", {
      headers: {
        pageNumber: "0",
        limit: totalDoc,
        search: a,
        "x-token": token,
      },
    });

    if (res.data.code === 203) {
      // setLoading(false)
      localStorage.clear();
      history("/");
      toast.error(res.data.message);
    } else {
      // setLoading(false)
      setTotaldoc(res.data.totalDocuments);
      setUsers(res.data.data);
      // setTotalDoc(res.data.total)
    }
  };

  // const setreportdata=(id)=>{
  //   let arr=[]
  //   arr.push({userId:id})
  //   setUserId(arr)
  //   setDeletePopup(true)
  //   console.log(userId,"cnbvkjghfkl ld",id)
  // }

  // const redirectTo=(id)=>{
  //   {users.map((row) =>{
  //     if(row._id===id){
  //       if(row.reportCount>0){
  //         history(`/Dashboard/reportuser/${id}`)
  //       }else{
  //         toast.success("No reports yet")
  //       }
  //     }
  //     // history(`/Dashboard/reportuser/${id}`)
  //   })

  // }

  // }

  // let length= 0
  // if(users.length>0){
  //   length=users.length
  // }

  return (
    <>
      <Stack direction="row">
        <TextField
          label="Search"
          name="Search"
          placeholder="Search with user name or email"
          value={searchs}
          onChange={searType}
        />
      </Stack>
      {!users ? (
        <div className="hey">
          <ReactLoading
            type={"spokes"}
            color={"rgb(9 167 167)"}
            height={50}
            width={50}
          />
        </div>
      ) : (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>S.no</StyledTableCell>
                  <StyledTableCell align="left">UserName</StyledTableCell>
                  <StyledTableCell align="left">Email</StyledTableCell>

                  <StyledTableCell align="left">Mobile</StyledTableCell>

                  {/* <StyledTableCell align="left">Verify</StyledTableCell> */}
                  <StyledTableCell align="left">Report Count</StyledTableCell>
                  <StyledTableCell align="left">Block</StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((row) => (
                  <TableRow>
                    <TableCell align="left">{a++}</TableCell>
                    <TableCell align="left">{row.fullName}</TableCell>
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell align="left">
                      {row.countryCode} {row.mobileNumber}
                    </TableCell>

                    {/* {row.isEmailVerified === true ? (
                      <TableCell align="left">
                        <VerifiedUserIcon className="trueicon" />
                      </TableCell>
                    ) : (
                      <TableCell align="left">
                        <CancelIcon className="falseicon" />
                      </TableCell>
                    )} */}
                    <TableCell align="left">{row.reportCount}</TableCell>
                    {row.adminblocked === true ? (
                      <TableCell align="left">
                        <HttpsRoundedIcon className="blockedUsericon" />
                      </TableCell>
                    ) : (
                      <TableCell align="left">
                        <LockOpenOutlinedIcon className="noBlUser" />
                      </TableCell>
                    )}
                    {/* {row.adminblocked===true 
               <TableCell align="center">  </TableCell> : ""
              } */}
                    <TableCell align="center">
                      <ButtonGroup
                        variant="contained"
                        aria-label="outlined primary button group"
                      >
                        {row.reportCount === 0 ? (
                          <Tooltip title="Get Report details">
                            <Button
                              className="userview"
                              id="userdetails"
                              onClick={() => details(row._id)}
                              disabled
                            >
                              <VisibilitySharpIcon />
                            </Button>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Get Report details">
                            <Button
                              className="userview"
                              id="userdetails"
                              onClick={() => details(row._id)}
                            >
                              <VisibilitySharpIcon />
                            </Button>
                          </Tooltip>
                        )}

                        {row.adminblocked === true ? (
                          <Tooltip title="Unblock the user">
                            <Button
                              className="adminunblockicon"
                              id="adminblockicons"
                              onClick={() => unBlockDatas(row._id)}
                            >
                              <LockOpenRoundedIcon />
                            </Button>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Block the user">
                            <Button
                              className="adminblockicon"
                              id="adminblockicon"
                              onClick={() => adminBlock(row._id)}
                            >
                              <BlockIcon />
                            </Button>
                          </Tooltip>
                        )}
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={
              totalDoc < 5
                ? [totalDoc, 5, 10, 25, 100]
                : totalDoc === 5
                ? [5, 10, 25, 100]
                : totalDoc < 10 && totalDoc > 5
                ? [5, totalDoc, 10, 25, 100]
                : totalDoc === 10
                ? [5, 10, 25, 100]
                : totalDoc < 25 && totalDoc > 10
                ? [5, 10, totalDoc, 25, 100]
                : totalDoc === 25
                ? [5, 10, 25, 100]
                : totalDoc < 100 && totalDoc > 25
                ? [5, 10, 25, totalDoc, 100]
                : totalDoc === 100
                ? [5, 10, 25, 100]
                : [5, 10, 25, 100, totalDoc]
            }
            component="div"
            count={totalDoc}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
      <Modal show={shows}>
        {/* <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header> */}
        <Modal.Body>Would you like to unblock this user ?</Modal.Body>
        <Modal.Footer>
          {unBlockId.map((data) => (
            <>
              {/* <span>{data.userId} data</span> */}
              <Tooltip title="Unblock the user">
                <Button
                  className="p-inherit"
                  onClick={() => {
                    unblockUser(data.userId);
                  }}
                  variant="contained"
                >
                  <DoneIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Cancel">
                <Button
                  className="catBt1 p-inherits"
                  onClick={() => {
                    setShows(false);
                  }}
                  variant="contained"
                >
                  <CloseIcon />
                </Button>
              </Tooltip>
            </>
          ))}
          {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>

      <Modal show={show}>
        {/* <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header> */}
        <Modal.Body>Would you like to block this user ?</Modal.Body>
        <Modal.Footer>
          {id_neededTodelete.map((data) => (
            <>
              <Tooltip title="Block the user">
                <Button
                  className="p-inherit"
                  onClick={() => {
                    adminBlockUser(data.userId);
                  }}
                  variant="contained"
                >
                  <DoneIcon />
                </Button>
              </Tooltip>
              <Tooltip title="Cancel">
                <Button
                  className="catBt1 p-inherits"
                  onClick={() => {
                    setShow(false);
                  }}
                  variant="contained"
                >
                  <CloseIcon />
                </Button>
              </Tooltip>
            </>
          ))}
          {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ReportList;
