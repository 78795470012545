import React, { useState , useEffect} from 'react'

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Button from '@mui/material/Button';
import Server from '../server/Server';
import { useNavigate } from "react-router-dom";
import {  toast } from 'react-toastify';
import axios from 'axios';
import ReactLoading from 'react-loading';

function Editprivacy() {
    let history=useNavigate()
    const [idOFprivacy,setPrivacyId]=useState("")
    const [privacyData,setData]=useState(undefined)
    const [edited,setEdited]=useState("")
    const admId= localStorage.getItem("adminId")
    var token= localStorage.getItem('token')
    useEffect(()=>{
        const fetchDatas=async ()=>{
          // setLoading(true);
        //   console.log(currentPage,"this is current page")
          const res=await axios.get(Server.server.link+'privacyPolicy',{headers:{"adminId":admId,"x-token":token}})
      
          console.log(res.data.results,"Coming data")
        if(res.data.code===203){
          localStorage.clear()
          history('/')
          toast.error(res.data.message)
        }else{
          console.log(res.data.data.data,"data__is_fine")
          let a= "<p></p>"
          if(res.data.data.data===""){
            setData(a)
            setPrivacyId(res.data.results._id)
          }else{
            setData(res.data.data.data)
            setPrivacyId(res.data.data._id)
          }
          
        }
        
        }
        fetchDatas()
      },[])
    const onchange=( event, editor ) => {
        setEdited(editor.getData())
        console.log(edited,"data")
        }

        console.log(privacyData,"data_is_not_good")

        const submit= async ()=>{
          console.log(idOFprivacy,"data that is here",typeof idOFprivacy)
          var check=edited.trim().length
          // console.log(a,"the fucking shit that i am searching")
          if(check===0){
            toast.error("You didn't change anything")
          }else if(!edited || edited==="" || edited===undefined){
            toast.error("You didn't change anything")
          }else{
          var tokens= localStorage.getItem('token')
            const updateddata= await axios.post(Server.server.link+"updatePolicy",{policyId:idOFprivacy,adminId:admId,data:edited},{headers:{"x-token":tokens}})
            console.log(updateddata.data.code,"data got from")
            if(updateddata.data.code===203){
              localStorage.clear()
              toast.error(updateddata.data.message)
              history('/')
            }
            else if(updateddata.data.code===201){
                toast.error(updateddata.data.message)
            }else{
                // const respo=await axios.get(Server.server.link+'privacyPolicy',{headers:{"adminId":admId,"x-token":tokens}})
                
                // console.log(respo.data,"res.data.results")
                const res=await axios.get(Server.server.link+'privacyPolicy',{headers:{"adminId":admId,"x-token":token}})
                toast.success(updateddata.data.message)
                console.log(res.data.results,"Coming data")
              if(res.data.code===203){
                localStorage.clear()
                history('/')
                toast.error(res.data.message)
              }else{
                console.log(res.data.data.data,"data__is_fine")
                let a= "<p></p>"
                if(res.data.data.data===""){
                  setData(a)
                  setPrivacyId(res.data.results._id)
                }else{
                  setData(res.data.data.data)
                  setPrivacyId(res.data.data._id)
                }
                }
            }
          }
           
        }  
    
        const reset=async ()=>{
          var tokens= localStorage.getItem('token')
            setData({})
            const res=await axios.get(Server.server.link+'privacyPolicy',{headers:{"adminId":admId,"x-token":token}})
      
            console.log(res.data.results,"Coming data")
          if(res.data.code===203){
            localStorage.clear()
            history('/')
            toast.error(res.data.message)
          }else{
            console.log(res.data.data.data,"data__is_fine")
            let a= "<p></p>"
            if(res.data.data.data===""){
              setData(a)
              setPrivacyId(res.data.results._id)
            }else{
              setData(res.data.data.data)
              setPrivacyId(res.data.data._id)
            }
            
          }
        }
        

  return (
    <>
 {!privacyData ?   <div className="hey">
          <ReactLoading
            type={"spokes"}
            color={"rgb(9 167 167)"}
            height={50}
            width={50}
          />
        </div>  :
 <div className="editorCk">
 {/* <h2 className='privacyhead'>PRIVACY POLICY</h2> */}
 <CKEditor
     editor={ ClassicEditor }
     data={privacyData}
     onReady={ editor => {
         // You can store the "editor" and use when it is needed.
         console.log( 'Editor is ready to use!', editor );
     } }
     onChange={ onchange }
     onBlur={ ( event, editor ) => {
         console.log( 'Blur.', editor );
     } }
     onFocus={ ( event, editor ) => {
         console.log( 'Focus.', editor ); 
     } }
 />
 <Button onClick={submit}>Update</Button><Button onClick={reset}>Cancel</Button>
</div>
  }

    </>
    
  )
}

export default Editprivacy