import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import Server from "../../server/Server";
import Server from "../server/Server";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
// import Box from '@mui/material/Box';
import CloseIcon from "@mui/icons-material/Close";
import Modal from "react-bootstrap/Modal";
import AddIcon from "@mui/icons-material/Add";
import Form from "react-bootstrap/Form";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import ReactLoading from "react-loading";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Tooltip } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";

// const Item = styled(Paper)(({ theme }) => ({
//   backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
//   ...theme.typography.body2,
//   padding: theme.spacing(1),
//   textAlign: 'left',
//   color: theme.palette.text.secondary,
// }));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function Faq() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const history = useNavigate();
  const token = localStorage.getItem("token");
  const [users, setUsers] = useState(undefined);
  const [totalDoc, setTotaldoc] = useState("");
  const [f_total,setF_Total]= useState(totalDoc)
  const [editAm, setIdView] = useState([]);
  const [amId, setUids] = useState([]);
  const [edit, setEdit] = useState(false);
  const [searchs, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [add, setAdd] = useState(false);
  const [enteredAminities, setEntered] = useState("");
  const [enteredAnswer, setEntere] = useState("");
  useEffect(() => {
    const fetchDatas = async () => {
      console.log(page, "this is current page");
      const res = await axios.get(Server.server.link + "getFaq", {
        params:{
          pageNumber: page,
          limit: rowsPerPage,
          search: searchs,
        },
        headers: {
          
          "x-token": token,
        },
      });

      if (res.data.code === 203) {
        localStorage.clear();
        history("/");
        toast.error(res.data.message);
      } else {
        // toast.success(res.data.message)
        setUsers(res.data.data);
        setTotaldoc(res.data.totalDocuments);
        setF_Total(res.data.totalDocuments);
        console.log(res.data, "daaaaaaa");
        // setTotalPages(res.data.totalPages)
      }
    };
    fetchDatas();
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event, newPage) => {
    console.log(event.target.value, "dataaa");
    if (event.target.value === "all") {
      toast.success(`Total number of Faq's : ${totalDoc}`);
      setRowsPerPage(totalDoc);
      setPage(0);
    } else {
      setRowsPerPage(+event.target.value);
      setPage(0);
    }
  };
  let a = 1;
  // const h=()=>{
  //   setShow(false)
  // }
  const AddAmenitiesPopup = () => {
    setAdd(true);
  };

  const addAmenitie = async () => {
    const question = enteredAminities;
    const Answer = enteredAnswer;
    var check1 = question.trim().length;
    var check2 = Answer.trim().length;

    console.log(question, "data is here....", Answer);
    if (question === "" && Answer === "") {
      toast.error("Please Fill the Question and Answer fields");
    } else if (check1 === 0) {
      toast.error(
        "Please Fill the Question field white spaces are not allowed"
      );
    } else if (check2 === 0) {
      toast.error("Please Fill the Answer field white spaces are not allowed");
    } else {
      axios
        .post(
          Server.server.link + "createFaq",
          { question: question, answer: Answer },
          { headers: { "x-token": token } }
        )
        .then(async (respo) => {
          if (respo.data.code === 203) {
            toast.error(respo.data.message);
            history("/");
          } else {
            toast.success(respo.data.message);
            setAdd(false);
            setEntered("");
            setEntere("");
            const res = await axios.get(Server.server.link + "getFaq", {
              params:{
                pageNumber: page,
                limit: rowsPerPage,
                search: searchs,
              },
              headers: {
               
                "x-token": token,
                
              },
            });
            if (res.data.code === 203) {
              localStorage.clear();
              history("/");
              toast.error(res.data.message);
            } else {
              setTotaldoc(res.data.totalDocuments);
              setUsers(res.data.data);
              history("/home/faq");
            }
          }
        });
    }
  };

  const editAmenitie = async (id) => {
    var question = enteredAminities;
    var Answer = enteredAnswer;
    // var data={
    //   d:enteredAminities.trim(),
    //   a:enteredAnswer.trim()
    // }

    // var  as=data.d
    // var sv =data.a
    // if(as ===""){
    //   var v=""
    //   const a = editAm.map(async(datas)=>{
    //     // return await data.question
    //     // console.log(data,"fastya")
    //     v= datas
    //   })
    //   as =v.question
    // }

    // if(sv===""){
    //   var vh=""
    //   const ass= editAm.map(async(datass)=>{
    //     // return await data.question
    //     // console.log(data,"fastya")
    //     vh= datass
    //   })
    //   sv =vh.answer
    // }

    // console.log(sv,"the dAta",as)
    // con  sole.log(enteredAminities,"data",enteredAnswer)
    // var check= enteredAminities.trim().length
    // var check2= enteredAnswer.trim().length
    // console.log(check,"datadseftre",check2)
    // if(check ===0 && check2 >0) {
    //   var d = editAm.map((data)=>{
    //     console.log(data,"datadseftre")
    //     question=data.question
    //   })

    // }
    // // console.log("df",s)

    // if(check>0 && check2===0) {
    //   var d = editAm.map(async (data)=>{
    //      Answer=data.answer
    //   })
    // }
    if (question === "" && Answer === "") {
      toast.error(
        "Please Fill the Question and Answer fields whitespaces are not allowed"
      );
    } else {
      var tokens = localStorage.getItem("token");
      // let data={}
      // if(check===0){
      //   data={"question":"","answer":Answer ,"id":id}
      // }
      // if(check2===0){
      //   data={"question":question,"answer":"" ,"id":id}
      // }
      axios
        .post(
          Server.server.link + "updatefaq",
          { question: question, answer: Answer, id: id },
          { headers: { "x-token": tokens } }
        )
        .then(async (respo) => {
          // console.log(respo.data,"hey mahbnssss")
          if (respo.data.code === 203) {
            toast.error(respo.data.message);
            history("/");
          } else if (respo.data.code === 201) {
            toast.error(respo.data.message);
          } else {
            toast.success(respo.data.message);
            setEdit(false);
            setEntered("");
            setEntere("");
            const res = await axios.get(Server.server.link + "getFaq", {
              params:{
                pageNumber: page,
                limit: rowsPerPage,
                search: searchs,
              },headers: {
               
                "x-token": token,
               
              },
            });
            if (res.data.code === 203) {
              localStorage.clear();
              history("/");
              toast.error(res.data.message);
            } else {
              setTotaldoc(res.data.totalDocuments);
              setF_Total(res.data.totalDocuments);
              setUsers(res.data.data);
              history("/home/faq");
            }
          }
        });
    }
  };

  var deleteAmini = async (id) => {
    console.log("......id id id i don't like it ", id);
    let arr = [];
    arr.push({ d: id });
    setUids(arr);
    setShow(true);
  };

  const deletingBackend = (id) => {
    console.log("id ...", id);
    var tokens = localStorage.getItem("token");
    axios
      .post(
        Server.server.link + "deleteFaq",
        { faqId: id },
        { headers: { "x-token": tokens } }
      )
      .then(async (respo) => {
        if (respo.data.code === 203) {
          toast.error(respo.data.message);
          history("/");
        } else if (respo.data.code === 202) {
          toast.error(respo.data.message);
          setShow(false);
        } else {
          toast.success(respo.data.message);
          setShow(false);
          const res = await axios.get(Server.server.link + "getFaq", {
            params:{
              pageNumber: page,
              limit: rowsPerPage,
              search: searchs,
            },
            headers: {
              "x-token": tokens,
            },
          });
          if (res.data.code === 203) {
            localStorage.clear();
            history("/");
            toast.error(res.data.message);
          } else {
            setTotaldoc(res.data.totalDocuments);
            setF_Total(res.data.totalDocuments)
            setUsers(res.data.data);
            history("/home/faq");
          }
        }
      });
  };

  const searType = async (e) => {
    setSearch(e.target.value);
    console.log(e.target.value, "this is the searched key");
    let a = e.target.value;
    var tokens = localStorage.getItem("token");
    const res = await axios.get(Server.server.link + "getFaq", {
      params:{
        pageNumber: '0',
        limit: f_total,
        search: a,
      },
      headers: {
       
        "x-token": tokens,
      },
    });
    console.log("logss", res.data);
    if (res.data.code === 203) {
      // setLoading(false)
      toast.error(res.data.message);
      localStorage.clear();
      history("/");
    } else {
      // setLoading(false)
      setTotaldoc(res.data.totalDocuments);
      setUsers(res.data.data);
      // setTotalDoc(res.data.total)
    }
  };

  const getOneAminitie = async (id) => {
    // let arr=[]

    // setEdit(true)
    // const data = await axios.get(Server.server.link+'getOneFaq',{headers:{faqid:id,"x-token":token}})
    // console.log(data.data.data,"adhi nee evide")
    // arr.push(data.data.data)
    // setIdView(arr)
    console.log("id.................", id);
    var to = localStorage.getItem("token");
    const data = await axios.get(Server.server.link + "getOneFaq", {
      headers: { faqId: id, "x-token": to },
    });
    if (data.data.code === 203) {
      toast.error(data.data.message);
      history("/");
    } else {
      let arr = [];
      setEdit(true);
      arr.push(data.data.data);
      setIdView(arr);
    }
  };

  const roleChangeHandler = (event) => {
    setEntered(event.target.value);
    console.log(event.target.value, "data question");
  };

  const roleChangeHandleroo = (event) => {
    var a = event.target.value;
    // setEntered(a.trim());
    if (a.trim().length === 0) {
      setEntered(a.trim());
    } else {
      setEntered(event.target.value);
    }
    console.log(event.target.value, "data question");
  };

  const roleChangeHandlers = (event) => {
    setEntere(event.target.value);
    console.log(event.target.value, "data answer");
  };

  const roleChangeHandlersooo = (event) => {
    var answerdata = event.target.value;
    if (answerdata.trim().length === 0) {
      setEntere(answerdata.trim());
    } else {
      setEntere(event.target.value);
    }
  };

  const removeData = () => {
    setIdView([]);
    setEdit(false);
    setEntered("");
    setEntere("");
  };

  const closecreate = () => {
    setAdd(false);
    setIdView([]);
    setEntered("");
    setEntere("");
  };
  return (
    <>
      <Stack direction="row">
        <TextField
          label="Search"
          name="Search"
          value={searchs}
          placeholder="Search With question or answer"
          onChange={searType}
        />
      </Stack>
      <Stack direction="row">
        <Button className="AddAmenities" onClick={AddAmenitiesPopup}>
          {" "}
          <AddIcon /> Add FAQ
        </Button>
      </Stack>
      {!users ? (
        <div className="hey">
          <ReactLoading
            type={"spokes"}
            color={"rgb(9 167 167)"}
            height={50}
            width={50}
          />
        </div>
      ) : (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>S.no</StyledTableCell>
                  <StyledTableCell align="left">Question</StyledTableCell>
                  <StyledTableCell align="left">Answer</StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {users.map((row) => (
                  <TableRow>
                    <TableCell align="left">{a++}</TableCell>
                    <TableCell align="left">{row.question}</TableCell>
                    <TableCell align="left">{row.answer}</TableCell>

                    <TableCell align="center">
                    <ButtonGroup
                        variant="contained"
                        aria-label="outlined primary button group"
                      >
                        <Tooltip title="Edit FAQ">
                        <Button
                        className="userview"
                        onClick={() => {
                          getOneAminitie(row._id);
                        }}
                      >
                        <EditRoundedIcon />
                      </Button>
                        </Tooltip>
                        <Tooltip title="Delete FAQ">
                        <Button
                        className="adminblockicon"
                        onClick={() => {
                          deleteAmini(row._id);
                        }}
                      >
                        <DeleteRoundedIcon />
                      </Button>
                        </Tooltip>
                      
                      </ButtonGroup>
                   
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={
              totalDoc < 5
                ? [totalDoc, 5, 10, 25, 100]
                : totalDoc === 5
                ? [5, 10, 25, 100]
                : totalDoc < 10 && totalDoc > 5
                ? [5, totalDoc, 10, 25, 100]
                : totalDoc === 10
                ? [5, 10, 25, 100]
                : totalDoc < 25 && totalDoc > 10
                ? [5, 10, totalDoc, 25, 100]
                : totalDoc === 25
                ? [5, 10, 25, 100]
                : totalDoc < 100 && totalDoc > 25
                ? [5, 10, 25, totalDoc, 100]
                : totalDoc === 100
                ? [5, 10, 25, 100]
                : [5, 10, 25, 100, totalDoc]
            }
            // rowsPerPageOptions={[5,10, 25, 100,totalDoc]}
            component="div"
            count={totalDoc}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}

      <Modal show={show}>
        <Modal.Body>Would you like to delete this Faq ?</Modal.Body>
        <Modal.Footer>
          {amId.map((data) => (
            <>
                <Tooltip title="Delete">
                <Button
                className="editButton"
                onClick={() => {
                  deletingBackend(data.d);
                }}
                variant="contained"
              >
                <DeleteRoundedIcon />
              </Button>
                </Tooltip>
                <Tooltip title="Cancel">
                <Button
                className="editButton"
                onClick={() => {
                  setShow(false);
                }}
                variant="contained"
              >
                <CloseIcon />
              </Button>
                </Tooltip>
             
            </>
          ))}
        </Modal.Footer>
      </Modal>

      <Modal show={add}>
        <Modal.Header>
          <Modal.Title>Create New Faq</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Question</Form.Label>
              <Form.Control
                type="text"
                style={{ marginBottom: "10px" }}
                value={enteredAminities}
                onChange={roleChangeHandler}
                required
              />
              <Form.Label>Answer</Form.Label>
              <Form.Control
                type="text"
                value={enteredAnswer}
                onChange={roleChangeHandlers}
                required
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Tooltip title="Add New FAQ">
          <Button
            className="editButton"
            onClick={addAmenitie}
            variant="contained"
          >
            <DoneIcon />
          </Button>
          </Tooltip>
        
          <br />
          <Tooltip title="Cancel">
          <Button
            className="editButton"
            onClick={closecreate}
            variant="contained"
          >
            <CloseIcon />
          </Button>
          </Tooltip>
        </Modal.Footer>
      </Modal>

      {/* <Modal show={edit} >
        <Modal.Header>
          <Modal.Title>Edit amenities</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
          {editAm.map(data=>(
            <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Aminities</Form.Label>
              <Form.Control type="text" defaultValue={data.amenities} onChange={roleChangeHandler}  required/>
              <Button className='editButton' onClick={()=>editAmenitie(data._id)}   variant="contained"><AddIcon/></Button>
              <Button className='editButton' onClick={removeData}   variant="contained"><CloseIcon/></Button>
            </Form.Group>
            </Form>
          ))}
        
            </Modal.Body>
        <Modal.Footer>
          
        </Modal.Footer>
      </Modal> */}

      {editAm.map((data) => (
        <Modal show={edit}>
          <Modal.Header>
            <Modal.Title>Edit Faq</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Question</Form.Label>
                <Form.Control
                  type="text"
                  style={{ marginBottom: "10px" }}
                  defaultValue={data.question}
                  onChange={roleChangeHandleroo}
                  required
                />
                <Form.Label>Answer</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={data.answer}
                  onChange={roleChangeHandlersooo}
                  required
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Tooltip title="Update FAQ">
            <Button
              className="editButton"
              onClick={() => editAmenitie(data._id)}
              variant="contained"
            >
              <DoneIcon />
            </Button>
            </Tooltip>
            <Tooltip title="Close">
            <Button
              className="editButton"
              onClick={removeData}
              variant="contained"
            >
              <CloseIcon />
            </Button>
            </Tooltip>
          </Modal.Footer>
        </Modal>
      ))}
    </>
  );
}

export default Faq;
