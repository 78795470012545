import React,{ useEffect ,useState ,useRef}  from 'react'
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Server from '../server/Server';
import { toast } from "react-toastify";
import Form from "react-bootstrap/Form";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";
import KeyboardReturnIcon from '@mui/icons-material/KeyboardReturn';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import PhoneInput , {CountryData} from 'react-phone-input-2'
import UserProfile from './UserProfile';
import ReactLoading from "react-loading";
function EditUser() {
    const history=useNavigate()
    const token= localStorage.getItem('token');
    const {id}=useParams()
    const [user,setUser]=useState([])
    const [phone,setPhone]=useState("")
    const [name,setName]=useState("")
    const [email,setEmail]=useState("")
    const [countryCode,setCountryCode]=useState("")
    const [mobile,setMobile]=useState("")
    const [about,setAbout]=useState("")
    useEffect(()=>{
        const fetchDatas=async ()=>{
       await axios.get(Server.server.link+'getOneUser',
    {
      headers:{"x-token":token,"userId":id}
    }).then(res=>{
      if(res.data.code===203){
        localStorage.clear()
        toast.error(res.data.message)
        history('/')
      }else{
        console.log(res.data.user,"hey nee thaane")
        // let arr=[]
        // arr.push(res.data.user)
        setUser(res.data.user)
        setPhone(res.data.user.countryCode+res.data.user.mobileNumber)
        // console.log(user,"data vandhitteee")
      }
      
    //   setShow(true) 
    })
       
    }
fetchDatas()
},[])       

const updateUser=async()=>{
    try {
      if(name==="" && email ==="" && countryCode ==="" && about ==="" && mobile===""){
        toast.error("Please update any field white spaces are not allowed")
      }else{
        var mob= ""
        if(countryCode!=""){
          mob=`+${countryCode}`
        }else{
          mob = countryCode
        }
        await axios.post(Server.server.link + "updateUser",{"fullName":name,"email": email,"countryCode":mob, "mobileNumber": mobile , "bio":about ,"userId":user._id},{headers:{"x-token":token}})
        .then(async(res)=>{
         
          if(res.data.code===203){
            localStorage.clear();
            history("/");
            toast.error(res.data.message);
          }else if(res.data.code===201){
            toast.error(res.data.message)
          }else{
            setName("")
            setEmail("")
            setCountryCode("")
            setMobile("")
            setAbout("")
            var results={
                full_name:res.data.results.full_name,
                about:res.data.results.about,
                email:res.data.results.email,
                _id:res.data.results._id
            }
            setUser(results)
            setPhone(res.data.results.countryCode+res.data.results.mobileNumber)
            // toast.error(res.data.results.countryCode+res.data.results.contact)
            toast.success(res.data.message)
          }
        })
      }
    } catch (e) {
      toast.error("Something went wrong")
    }
}

        const nameChange=(e)=>{
            if (e.target.value.trim().length === 0) {
            setName(e.target.value.trim());
            } else {
            setName(e.target.value);
            }
        }

        const emailChange=(e)=>{
            if (e.target.value.trim().length === 0) {
              setEmail(e.target.value.trim());
            } else {
                setEmail(e.target.value);
            }
          }

          const countryCodeChange=(e)=>{
            if (e.target.value.trim().length === 0) {
              setCountryCode(e.target.value.trim());
            } else {
                setCountryCode(e.target.value);
            }
          }

          const mobileChange=(e)=>{
            if (e.target.value.trim().length === 0) {
              setMobile(e.target.value.trim());
            } else {
                setMobile(e.target.value);
            }
          }

          const aboutChange=(e)=>{
            if (e.target.value.trim().length === 0) {
              setAbout(e.target.value.trim());
            } else {
                setAbout(e.target.value);
            }
          }
          const redirect=()=>{
            history(`/home/userlist`)
          }
        //   toast.error(phone)
          const setphoneuse=async(data,country)=>{
            // console.log(data.slice(country.dialCode.length),"fffff",country)
            setCountryCode(country.dialCode)
            setMobile(data.slice(country.dialCode.length))
          }
  return (
    <>
     {user.length===0 ? (
        <div className="hey">
          <ReactLoading
            type={"spinningBubbles"}
            color={"rgb(9 167 167)"}
            height={50}
            width={50}
          />
        </div>
      ) : 
      <div>
        <Form>
          <Form.Group
            className="mb-3"
            controlId="formBasicEmail"
            style={{ width: "30%", margin: "auto" }}
          > 
          <h3>{user.full_name}</h3>
            <Form.Label >Name</Form.Label>
            <Form.Control
              type="text"
              // id="inputPassword5"
              aria-describedby="passwordHelpBlock"
              defaultValue={user.fullName}
              onChange={nameChange}
            />
            <Form.Label style={{ marginTop: "4%" }}>
              Email
            </Form.Label>
            <Form.Control
              // style={{"marginTop": "4%"}}
              type="text"
              // id="inputPassword5"
              aria-describedby="passwordHelpBlock"
              defaultValue={user.email}
              onChange={emailChange}
            />
             {/* <Form.Label style={{ marginTop: "4%" }}>
              Country Code
            </Form.Label>
             <Form.Control
              // style={{"marginTop": "4%"}}
              type="text"
              // id="inputPassword5"
              aria-describedby="passwordHelpBlock"
              defaultValue={user.countryCode}
              onChange={countryCodeChange}
            /> */}
             <Form.Label style={{ marginTop: "4%" }}>
              Contact Number
            </Form.Label>
             {/* <Form.Control
              // style={{"marginTop": "4%"}}
              type="text"
              // id="inputPassword5"
              aria-describedby="passwordHelpBlock"
              defaultValue={user.contact}
              onChange={mobileChange}
            />  */}
           <PhoneInput
                country={CountryData}
                enableSearch={true}               
                // placeholder={user.countryCode+user.contact}
                value={phone}
                onChange={(data,country) => setphoneuse(data,country)}
                />
             <Form.Label style={{ marginTop: "4%" }}>
              About
            </Form.Label>
             <Form.Control
              // style={{"marginTop": "4%"}}
              type="text"
              // id="inputPassword5"
              aria-describedby="passwordHelpBlock"
              defaultValue={user.bio}
              onChange={aboutChange}
            />
             {/* <ButtonGroup aria-label="Basic example"> */}
             <div className="profileUpdateBut">
             <Tooltip title={`Update ${user.fullName} Profile`}>
              <Button
                className="changeButton"
                onClick={updateUser}
                variant="contained"
                href="#contained-buttons"
              >
                UPDATE
              </Button>
            </Tooltip>
            <Tooltip title="Redirect to user module">
              <Button
                className="changeButton"
                onClick={()=>redirect()}
                variant="contained"
                href="#contained-buttons"
              >
                USER MODULE
              </Button>
            </Tooltip>
           
             </div>
            {/* <Button onClick={()=>redirect()} >User module<KeyboardReturnIcon /></Button> */}
             {/* </ButtonGroup> */}
           
            
            {/* <Tooltip title="Move to user module"> */}
            
            {/* </Tooltip> */}
          </Form.Group>
        </Form>
        <br />
      </div>
}
    </>
  )
}

export default EditUser