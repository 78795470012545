import { React, useState } from "react";
import Card from "@mui/material/Card";
import { useNavigate, NavLink } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Server from "../server/Server";
import LoginIcon from "@mui/icons-material/Login";
import logo from "../../images/unionnews.svg";
import {Row,Col} from 'react-bootstrap'
// import {  } from '@material-ui/core'

function Login() {
  // let ref = useRef();
  const history = useNavigate();
  const [user, setUser] = useState({ email: "", password: "" });
  let name, value;
  console.log(user, "ZZZZZZZZZZZZZ");

  const handaleinput = (e) => {
    name = e.target.name;
    value = e.target.value;
    console.log(value, "name");
    setUser({ ...user, [name]: value });
  };

  const postdata = async (e) => {
    e.preventDefault();
    // console.log(user, "Hello I am User");
    var response = await axios
      .post(Server.server.link + "login", user)
      .then(async (response) => {
        console.log(response.data);
        if (response.data.code === 201) {
          toast.error(response.data.message);
          history("/");
        } else if (response.data.code === 202) {
          toast.error(response.data.message);
          history("/");
        } else {
          localStorage.setItem("token", response.data.results.accessToken);
          localStorage.setItem("adminId", response.data.results.adminId);
          history("/home/Dashboard");
          toast.success("Logged in successfully")
        }
      });
    console.log(response, "response");
  };
  return (
    <>
      <Card variant="outlined" className="loginaa">
        <div>
          <Row>
          <Col></Col>
          <Col>
          <img className="imageAPp" src={logo} alt="logo"></img>
          </Col>
          <Col></Col>
          </Row>
          <Row>
          <Col sm={1}></Col>
          <Col sm={20}>
          <h2 className="appName">Union News Admin</h2>
          </Col>
          <Col></Col>
          </Row>
         
          {/* <TextField id="outlined-basic" className="inputf" name="email" value={user.email} label="Email" variant="outlined" />
          <TextField id="outlined-basic" type = "password"  className = "inputf" value={user.password} label="Password" variant="outlined"/> */}
          <Row>
          <Col>
          <TextField
            id="outlined-basic"
            name="email"
            className="inputfd"
            label="Email"
            variant="outlined"
            value={user.email}
            onChange={handaleinput}
          />
          <br />
          <TextField
            id="outlined-basic"
            name="password"
            type="password"
            className="inputfd"
            label="Password"
            variant="outlined"
            value={user.password}
            onChange={handaleinput}
          />
          </Col>
          </Row>
          

        
          <br />
          <Row>
          <Col></Col>
          <Col>
          <Button
            className="loginButton"
            onClick={postdata}
            variant="contained"
            type="submit"
            // href="#contained-buttons"
          >
            LOGIN
          </Button>
          
          </Col>
          <Col>
          
          </Col>
          <Row>
          <div className="forgot-btn" style={{marginTop:"10px"}}>
            <NavLink to="/forgot" className="forgot">
              <span>Forgot password ?</span>
            </NavLink>
          </div>
          </Row>
          </Row>
          {/* <TextField id="outlined-basic" name='password' type = "password" className="inputf" label="Password" variant="outlined" value={user.password} onChange={handaleinput}/> */}
          
        </div>
      </Card>
    </>
  );
}

export default Login;
