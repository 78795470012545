import React, { useEffect, useState } from "react";
// import "./webview.css"
import { useTheme } from "@mui/material/styles";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CardMedia,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import ImportExportIcon from "@mui/icons-material/ImportExport";
import FavoriteIcon from "@mui/icons-material/Favorite";
import CommentIcon from "@mui/icons-material/Comment";
import { red } from "@mui/material/colors";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import MobileStepper from "@mui/material/MobileStepper";
import axios from "axios";
import Server from "../server/Server";
import { useParams } from "react-router-dom";
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

function Webview() {
  const theme = useTheme();
  const [index, setIndex] = useState(0);
  const [activeStep, setActiveStep] = React.useState(0);
  const [media, setMedia] = useState([]);
  const [postdetails, setPostDetials] = useState({});
  const [avatarFirstLetter,setAvatar]= useState("")
  const maxSteps = media.length;

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  
  const { id } = useParams();
  useEffect(() => {
    const fetchDatas = async () => {
      await axios
        .get(Server.serverforOther.link + "postviews", {
          params: { postId: id },
        })
        .then(async (res) => {
          console.log(res.data, "data is here");
          setMedia(res.data.data.media);
          setPostDetials(res.data.data);
          setAvatar( res.data.data.userName.slice(0, 1))
        });
    };
    fetchDatas();
  });
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };

  // extension = fileName.substring(fileName.lastIndexOf('.') + 1);
  //         document.querySelector('.output')
  //                                 .textContent = extension;
  console.log(media.length, "ddddd");
  return (
    <>
      {media.length === 0 && postdetails ? (
        ""
      ) : (
        <div>
          <Card sx={{
          maxWidth: "450px",
          marginLeft: "auto",
          marginRight: "auto",
          marginTop: "1% !important",
        }}>
            <CardHeader
              avatar={
                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                  {avatarFirstLetter}
                </Avatar>
              }
              title={postdetails.userName}
              subheader={postdetails.headLineTitle}
            />

            <CardMedia>
            <Box sx={{ maxWidth: 700, flexGrow: 1 }}>
            <Paper
              square
              elevation={0}
              sx={{
                display: "flex",
                alignItems: "center",
                height: 50,
                pl: 2,
                bgcolor: "background.default",
              }}
            ></Paper>
             <SwipeableViews
              axis={theme.direction === "rtl" ? "x-reverse" : "x"}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
            >
              {media.map((row, index) =>
                row.fileName.split(".").pop() === "mp4" ||
                row.fileName.split(".").pop() === "mov" ||
                row.fileName.split(".").pop() === "mog" ? (
                  <Box
                    sx={{
                      height: 300,
                      display: "block",
                      maxWidth: 700,
                      overflow: "hidden",
                      width: "100%",
                    }}
                  >
                    <div className="video-section">
                      <video class="img-fluid" autoPlay loop muted>
                        <source
                          src={Server.serverforOther.link + row.fileName}
                        />
                      </video>
                    </div>
                  </Box>
                ) : (
                  <Box
                    sx={{
                      height: 300,
                      display: "block",
                      maxWidth: 700,
                      overflow: "hidden",
                      width: "100%",
                    }}
                  >
                    <img
                      className="d-block w-100"
                      src={Server.serverforOther.link + row.fileName}
                      alt="First slide"
                    />
                  </Box>
                )
              )}
            </SwipeableViews>
            <MobileStepper
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              nextButton={
                <Button
                  size="small"
                  onClick={handleNext}
                  disabled={activeStep === maxSteps - 1}
                >
                  Next
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowLeft />
                  ) : (
                    <KeyboardArrowRight />
                  )}
                </Button>
              }
              backButton={
                <Button
                  size="small"
                  onClick={handleBack}
                  disabled={activeStep === 0}
                >
                  {theme.direction === "rtl" ? (
                    <KeyboardArrowRight />
                  ) : (
                    <KeyboardArrowLeft />
                  )}
                  Back
                </Button>
              }
            />
          </Box>
            </CardMedia>

            <CardContent>
              <Typography variant="body2" color="text.secondary">
                {postdetails.description}
              </Typography>
            </CardContent>
            <CardActions disableSpacing>
              <IconButton aria-label="add to favorites">
                <span style={{ marginRight: "10px" }}>{postdetails.likes}</span>
                <FavoriteIcon style={{ color: "red" }} />
              </IconButton>
              <IconButton aria-label="share">
                <span style={{ marginRight: "10px" }}>
                  {postdetails.biazCount}
                </span>
                <ImportExportIcon style={{ color: "black" }}/>
              </IconButton>
              <IconButton aria-label="share">
                <span style={{ marginRight: "10px" }}>
                  {postdetails.comments}
                </span>
                <CommentIcon />
              </IconButton>
              {/* <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore> */}
            </CardActions>
            {/* <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>
          <Typography paragraph>Method:</Typography>
          <Typography paragraph>
            Heat 1/2 cup of the broth in a pot until simmering, add saffron and set
            aside for 10 minutes.
          </Typography>
          <Typography paragraph>
            Heat oil in a (14- to 16-inch) paella pan or a large, deep skillet over
            medium-high heat. Add chicken, shrimp and chorizo, and cook, stirring
            occasionally until lightly browned, 6 to 8 minutes. Transfer shrimp to a
            large plate and set aside, leaving chicken and chorizo in the pan. Add
            pimentón, bay leaves, garlic, tomatoes, onion, salt and pepper, and cook,
            stirring often until thickened and fragrant, about 10 minutes. Add
            saffron broth and remaining 4 1/2 cups chicken broth; bring to a boil.
          </Typography>
          <Typography paragraph>
            Add rice and stir very gently to distribute. Top with artichokes and
            peppers, and cook without stirring, until most of the liquid is absorbed,
            15 to 18 minutes. Reduce heat to medium-low, add reserved shrimp and
            mussels, tucking them down into the rice, and cook again without
            stirring, until mussels have opened and rice is just tender, 5 to 7
            minutes more. (Discard any mussels that don&apos;t open.)
          </Typography>
          <Typography>
            Set aside off of the heat to let rest for 10 minutes, and then serve.
          </Typography>
        </CardContent>
      </Collapse> */}
          </Card>
        </div>
      )}
    </>
  );
}

export default Webview;
