import {
  // Link,
  NavLink,
  // BrowserRouter,
  // Route,
  // Routes,
  // Switch,
  // useNavigate,
} from "react-router-dom";
// import SetMealRoundedIcon from '@mui/icons-material/SetMealRounded';
import PolicyIcon from "@mui/icons-material/Policy";
import StreamSharpIcon from '@mui/icons-material/StreamSharp';

import { useState } from "react";
import PollIcon from '@mui/icons-material/Poll';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import PhotoAlbumIcon from '@mui/icons-material/PhotoAlbum';
import QuizIcon from '@mui/icons-material/Quiz';

export default function Posts() {
  const [open, setOpen] = useState(false);
  // const data=()=>{
  //     setOpen(true)
  // }
  return (
    // <div activeClassName="active">
    // <SetMealRoundedIcon className="icon" />
    //   <span>Content Management</span>
    // </div>
    <>
      <div>
        <a
          activeClassName="active"
          className="iems"
          onClick={() => setOpen(!open)}
        >
          <StreamSharpIcon className="icon" />
          <span>Feed Management</span>
          {open === true ? (
            <i className="bi bi-chevron-compact-down togle"></i>
          ) : (
            <i className="bi bi-chevron-compact-up togle"></i>
          )}
        </a>
        {/* <a activeClassName="active"> */}
        <div className={open === true ? "subbar open" : "subbar"}>
        <li className="hover">
                <NavLink to="/home/posts" activeClassName="active">
                  <PhotoAlbumIcon className="icon" />
                  <span>User Feeds</span>
                </NavLink>
              </li>
              <li className="hover">
                <NavLink to="/home/Posttable" activeClassName="active">
                  <DynamicFeedIcon className="icon" />
                  <span>Posts</span>
                </NavLink>
              </li>
              <li className="hover">
                <NavLink to="/home/Polltable" activeClassName="active">
                  <PollIcon className="icon" />
                  <span>Polls</span>
                </NavLink>
              </li>
              <li className="hover">
                <NavLink to="/home/Questiontable" activeClassName="active">
                  <QuizIcon className="icon" />
                  <span>Questions</span>
                </NavLink>
              </li>
        </div>
        {/* </a > */}
      </div>
    </>
  );
}
