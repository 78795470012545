import React, { useState, useEffect } from "react";
// import DashboardIcon from "@mui/icons-material/Dashboard";
// import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
// import EventNoteIcon from "@mui/icons-material/EventNote";
// import PolicyIcon from "@mui/icons-material/Policy";
import LogoutSharpIcon from '@mui/icons-material/LogoutSharp';
import PeopleIcon from "@mui/icons-material/People";
import HomeIcon from "@mui/icons-material/Home";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
// import { styled,  } from "@mui/material/styles";
// import Avatar from '@mui/material/Avatar';
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import AccountCircleSharpIcon from '@mui/icons-material/AccountCircleSharp';
// import SetMealRoundedIcon from '@mui/icons-material/SetMealRounded';
// import NetworkWifiIcon from '@mui/icons-material/NetworkWifi';
import LiveTvIcon from "@mui/icons-material/LiveTv";
import Modal from "react-bootstrap/Modal";
// import BlockIcon from '@mui/icons-material/Block';
import PasswordIcon from "@mui/icons-material/Password";
import AssessmentIcon from "@mui/icons-material/Assessment";
// import DropdownButton from 'react-bootstrap/DropdownButton'
import Dropdown from "react-bootstrap/Dropdown";
// import hooked from '../../../images/hooked.ico';
import CategoryIcon from "@mui/icons-material/Category";
import CollectionsIcon from "@mui/icons-material/Collections";
import SouthAmericaIcon from "@mui/icons-material/SouthAmerica";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import DashboardIcon from '@mui/icons-material/Dashboard';
import PhotoAlbumIcon from '@mui/icons-material/PhotoAlbum';
import SlideshowIcon from '@mui/icons-material/Slideshow';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import PollIcon from '@mui/icons-material/Poll';
import ReportIcon from '@mui/icons-material/Report';
import QuizIcon from '@mui/icons-material/Quiz';
import PatternSharpIcon from '@mui/icons-material/PatternSharp';
// import Item from './Item';
// const a = {<i class="fa-solid fa-rectangle-ad"></i>}
import {
  // Link,
  NavLink,
  // BrowserRouter,
  Route,
  Routes,
  // Switch,
  useNavigate,
} from "react-router-dom";
// import Dashboard from "../Dashboard/Dashboard";
import Dashboard from "./Dashboard";
// import Userlist from "../users/Userlist";
// import UserProfile from "../users/UserProfile";
// import Server from "../../server/Server";
import Server from "../server/Server";
import axios from "axios";
import { toast } from "react-toastify";
import Item from "./Item";
import Category from "../categorys/Category";
import Userlist from "../users/Userlist";
import UserProfile from "../users/UserProfile";
import ReportList from "../users/ReportList";
import ReportDetails from "../users/ReportDetails";
import NewsChannels from "../categorys/NewsChannels";
import Userpost from "../users/Userspost";
import Editdisclaimer from "../policyandmore/Editdisclaimer";
import Editprivacy from "../policyandmore/Editprivacy";
import EditTerms from "../policyandmore/Editterms";
import Faq from "../policyandmore/Faq";
import Ethencity from "../ethencity/Ethencity";
import ChangePassword from "../admin/Changepassword";
import Adminquestion from "../categorys/Adminquestion";
import Question from "../categorys/Question";
// import Events from "../events/Amenities";
// import Amenities from "../events/Amenities";
// import FishTypes from "../events/FishTypes";
// import Watertypes from "../events/Watertypes";
// import Editprivacy from "../policiesandmore/Editprivacy";
// import Editdisclaimer from "../policiesandmore/Editdisclaimer";
// import EditTerms from "../policiesandmore/Editterms";
// import ChangePassword from '../../admin/Changepassword';
// import Faq from '../events/Faq';
// import ReportList from '../users/ReportList';
// import ReportedUser from '../users/ReportedUser';
// import ReportDetails from '../users/ReportDetails';
// import Menu from '@mui/material/Menu';
// import MenuItem from '@mui/material/MenuItem';
// import Test from '../users/Test';
// import Cancellation from "../cancellationpolicy/Cancellation";
// import Privacy from "../privacy/Privacy";
// import Faqs from "../faqs/faqs";
// import Userlist from "../userList/Userlist";
import logo from "../../images/unionnews.svg";
import EditUser from "../users/EditUser";
import Adminprofile from "../admin/Profile";
import PersonSharpIcon from '@mui/icons-material/PersonSharp';
import Banners from "../categorys/Banners";
import Advertisement from "../categorys/Advertisement";
import PostTable from "../users/PostTable";
import PostReport from "../users/PostReport";
import PollTable from "../users/PollTable";
import QuestionTable from "../users/QuestionTable";
import Posts from "./Posts";

const drawerWidth = 240;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

function Home() {
  const history = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const adminId = localStorage.getItem("adminId");
  const token = localStorage.getItem("token");
  const [show, setShow] = useState(false);
  const [data, setData] = useState([]);
  const [adminName,setAdminName]= useState("")
  // const [adminOpen,setAdminopen]=useState(false)
  var toast=localStorage.getItem('toast')
  const headers = {
    Authorization: "Bearer my-token",
    "My-Custom-Header": "foobar",
    adminId: adminId,
    "x-token": token,
  };
  if(toast===true){
    // setTimeout(() => { 
      toast.success("Logged in successfully");
    // }, 1000);
  }
  useEffect(() => {
    const fetchDatas = async () => {
      const res = await axios.get(Server.server.link + "adminDetailsforHome", {
        headers: { "x-token": token },
      });
      console.log(res,"ffff")
      setData(res.data.results);
      setAdminName(res.data.results.name)
    };
    fetchDatas();
  },[]);

  const handleChange = async () => {
    await axios
      .post(Server.server.link + "logout", { headers })
      .then((response) => {
        console.log(response, "asdaasd");
        localStorage.clear();
        history("/");
        toast.success(response.data.results);
      });
  };
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const adminBlock = async () => {
    setShow(true);
  };

  // const adminOption=()=>{
  //   console.log("open")
  //   setAdminopen(true)
  // }
  // console.log("adminOpen",adminOpen)

  const changePasswordredicrect = () => {
    history("/home/changePassword");
  };

  const dashboardredicrect = () => {
    history("/home/Dashboard");
  };

  const redirectToProfile=()=>{
    history('/home/adminProfile')
  }
  return (
    <>
      <Box sx={{ display: "flex" }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{ mr: 2, ...(open && { display: "none" }) }}
            >
              {/* <span>hello</span> */}
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div">
              Union News Admin Panel
            </Typography>
            {/* <Avatar/> */}
            <Dropdown style={{ marginLeft: "auto" }}>
              <Dropdown.Toggle variant="success" id="dropdown-basic">
              {adminName}
              </Dropdown.Toggle>

              <Dropdown.Menu>
              <Dropdown.Item className="logout-button" onClick={redirectToProfile}> <AccountCircleSharpIcon />Profile</Dropdown.Item>
                <Dropdown.Item onClick={changePasswordredicrect}>
                  <PatternSharpIcon className="icon" />
                  Change Password
                </Dropdown.Item>
                <Dropdown.Item className="logout-button" onClick={adminBlock}>
                  <LogoutSharpIcon />
                  Logout
                </Dropdown.Item>
                {/* <Dropdown.Item href="#/action-3">Something else</Dropdown.Item> */}
              </Dropdown.Menu>
            </Dropdown>
            {/* <Menu>
          <MenuItem >Profile</MenuItem>
        <MenuItem onClick={adminOption}>My account</MenuItem>
        <MenuItem onClick={adminOption}>Logout</MenuItem>
          </Menu> */}
            {/* {adminOpen===fa ? <Menu>
            <MenuItem >Profile</MenuItem>
        <MenuItem onClick={adminOption}>My account</MenuItem>
        <MenuItem onClick={adminOption}>Logout</MenuItem>
          </Menu>:""} */}
          </Toolbar>
        </AppBar>
        <Drawer
          sx={{
            width: drawerWidth,
            flexShrink: 0,
            "& .MuiDrawer-paper": {
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
          variant="persistent"
          anchor="left"
          open={open}
        >
          <DrawerHeader>
            <img
              className="imageAPps"
              src={logo}
              onClick={dashboardredicrect}
              alt="imageAPps"
            ></img>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === "ltr" ? (
                <ChevronLeftIcon />
              ) : (
                <ChevronRightIcon />
              )}
            </IconButton>
          </DrawerHeader>
          <Divider />

          <nav style={{ marginTop: "6px", marginBottom: "6px" }}>
            <ul className="nav nav-pills sidemenus ">
              <li className="hover">
                <NavLink to="/home/Dashboard" activeClassName="active">
                  <DashboardIcon className="icon" />
                  <span>DashBoard</span>
                </NavLink>
              </li>
              <li className="hover">
                <NavLink to="/home/userlist" activeClassName="active">
                  <PeopleIcon className="icon" />
                  <span style={{color:"#000"}}>User Management</span>
                </NavLink>
              </li>
              <li className="hover">
                <NavLink to="/home/reportTable" activeClassName="active">
                  <ReportIcon className="icon" />
                  <span>Report Table</span>
                </NavLink>
              </li>

              {/* <li className="hover">
                <NavLink to="/home/posts" activeClassName="active">
                  <PhotoAlbumIcon className="icon" />
                  <span>User posts</span>
                </NavLink>
              </li>
              <li className="hover">
                <NavLink to="/home/Posttable" activeClassName="active">
                  <DynamicFeedIcon className="icon" />
                  <span>Posts Table</span>
                </NavLink>
              </li>
              <li className="hover">
                <NavLink to="/home/Polltable" activeClassName="active">
                  <PollIcon className="icon" />
                  <span>Poll Table</span>
                </NavLink>
              </li>
              <li className="hover">
                <NavLink to="/home/Questiontable" activeClassName="active">
                  <QuizIcon className="icon" />
                  <span>Question Table</span>
                </NavLink>
              </li> */}
              {/* Questiontable */}
              
              {/* <li className="hover">
              <NavLink to="" activeClassName="active">
                <HomeWorkIcon className="icon" />
                <span>Property Management</span>
              </NavLink>
            </li> */}
            <li className="hover">
              <Posts/>
            </li>
              <li className="hover">
                <NavLink to="/home/categorys" activeClassName="active">
                  <CategoryIcon className="icon" />
                  <span>Categories</span>
                </NavLink>
              </li>
              <li className="hover">
                <NavLink to="/home/channels" activeClassName="active">
                  <LiveTvIcon className="icon" />
                  <span>Channels</span>
                </NavLink>
              </li>
              <li className="hover">
              <NavLink to="/home/Banner" activeClassName="active">
                <CollectionsIcon className="icon" />
                <span>Banners</span>
              </NavLink>
            </li>
            <li className="hover">
              <NavLink to="/home/Advertisement" activeClassName="active">
                <SlideshowIcon className="icon" />
                <span>Advertisement</span>
              </NavLink>
            </li>
              {/* <li className="hover">
              <NavLink to="/home/editprivacy" activeClassName="active">
                <PolicyIcon className="icon" />
                <span>Privacy policy</span>
              </NavLink>
            </li> */}

              <li className="hover">
                <Item />
              </li>

              <li className="hover">
                <NavLink to="/home/ethnicity" activeClassName="active">
                  <SouthAmericaIcon className="icon" />
                  <span>Ethnicity</span>
                </NavLink>
              </li>

              {/* <li className="hover">
                <NavLink to="/home/adminquestions" activeClassName="active">
                  <QuestionAnswerIcon className="icon" />
                  <span>Admin questions</span>
                </NavLink>
              </li> */}
              
             
            </ul>
          </nav>
          
          
        </Drawer>
        <Main open={open}>
          <DrawerHeader />
          <Routes>
            <Route exact path="Dashboard" element={<Dashboard />} />
            <Route exact path="categorys" element={<Category />} />
            {/* 
        <Route exact path="reporteduser/:id" element={<ReportedUser />}/>
        
        <Route exact path="amenities" element={<Amenities />}/>
        <Route exact path="fishtypes" element={<FishTypes />}/>
        <Route exact path="watertypes" element={<Watertypes />}/>
        <Route exact path="editprivacy" element={<Editprivacy />}/>
        <Route exact path="editdisclaimer" element={<Editdisclaimer />}/>
        <Route exact path="editterms" element={<EditTerms />}/>
        <Route exact path="changePassword" element={<ChangePassword />}/>
        <Route exact path="faq" element={<Faq />}/>
        */}
            {/* <Route exact path="test" element={<Test />}/> */}
            {/* <Route exact path="cancel" element={<Cancellation />}/>
        <Route exact path="privacy" element={<Privacy />}/>
        <Route exact path="faqs" element={<Faqs />}/>
        */}
            <Route exact path="userlist" element={<Userlist />} />
            <Route exact path="user/:id" element={<UserProfile />} />
            <Route exact path="reportTable" element={<ReportList />} />
            <Route
              exact
              path="reportedDetails/:id"
              element={<ReportDetails />}
            />
            <Route exact path="channels" element={<NewsChannels />} />
            <Route exact path="posts" element={<Userpost />} />
            <Route exact path="editdisclaimer" element={<Editdisclaimer />} />
            <Route exact path="editprivacy" element={<Editprivacy />} />
            <Route exact path="editterms" element={<EditTerms />} />
            <Route exact path="faq" element={<Faq />} />
            <Route exact path="ethnicity" element={<Ethencity />} />
            <Route exact path="changePassword" element={<ChangePassword />} />
            {/* <Route exact path="adminquestions" element={<Question />} /> */}
            <Route exact path="adminProfile" element={<Adminprofile />} />
            <Route exact path="EditUser/:id" element={<EditUser />}/>
            <Route path="Banner" element={<Banners />} />
            <Route path="Advertisement" element={<Advertisement />} />
            <Route path="Posttable" element={<PostTable />} />
            <Route path="Reports/:id" element={<PostReport />} />
            <Route path="Polltable" element={<PollTable />} />
            <Route path="Questiontable" element={<QuestionTable />} />
          </Routes>
        </Main>
      </Box>
      <Modal show={show}>
        {/* <Modal.Header>
          <Modal.Title></Modal.Title>
        </Modal.Header> */}
        <Modal.Body>Would you like to logout ?</Modal.Body>
        <Modal.Footer>
          <>
            <Button
              className="p-inherit"
              onClick={handleChange}
              variant="contained"
            >
              LOGOUT
            </Button>
            <Button
              className="catBt1 p-inherits"
              onClick={() => {
                setShow(false);
              }}
              variant="contained"
            >
              CANCEL
            </Button>
          </>
          {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Home;
