import { React, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Server from "../server/Server";
import LoginIcon from "@mui/icons-material/Login";
import { Tooltip } from "@mui/material";
import { Col, Form, Row } from "react-bootstrap";

function ChangePassword() {
  const history = useNavigate();
  const [user, setUser] = useState({ oldPassword: "", newPassword: "" });
  let name, value;
  const adminId = localStorage.getItem("adminId");
  const token = localStorage.getItem("token");
  const headers = {
    Authorization: "Bearer my-token",
    "My-Custom-Header": "foobar",
    adminId: adminId,
    "x-token": token,
  };
  const handaleinput = (e) => {
    name = e.target.name;
    value = e.target.value;
    console.log(value, "name");
    setUser({ ...user, [name]: value });
  };

  const postdata = async (e) => {
    e.preventDefault();
    if (user.oldPassword === "") {
      toast.error("Please fill the old password field");
    } else if (user.newPassword === "") {
      toast.error("Please fill the new password field");
    } else {
      var response = await axios
        .post(Server.server.link + "changePassword", user, { headers })
        .then(async (response) => {
          console.log(response.data);
          if (response.data.code === 203) {
            toast.error(response.data.message);
            localStorage.clear();
            history("/");
          } else if (response.data.code === 201) {
            toast.error(response.data.message);
          } else if (response.data.code === 202) {
            toast.error(response.data.message);
          } else {
            setUser({ oldPassword: "", newPassword: "" });
            toast.success(response.data.message);
          }
        });
      console.log(response, "response");
    }
  };
  return (
    <>
      <Row>
        <Form>
          <Form.Group
            className="mb-3"
            controlId="formBasicEmail"
            style={{ width: "30%", margin: "auto" }}
          >
            {" "}
            <Form.Label>Old password</Form.Label>
            <Form.Control
              type="password"
              name="oldPassword"
              // id="inputPassword5"
              aria-describedby="passwordHelpBlock"
              defaultValue={user.oldPassword}
              onChange={handaleinput}
            />
            <Form.Label>New password</Form.Label>
            <Form.Control
              type="password"
              name="newPassword"
              // id="inputPassword5"
              aria-describedby="passwordHelpBlock"
              defaultValue={user.newPassword}
              onChange={handaleinput}
            />
            <Tooltip title="Update Password">
              <Button
                className="changeButton"
                onClick={postdata}
                variant="contained"
                href="#contained-buttons"
              >
                UPDATE
              </Button>
            </Tooltip>
          </Form.Group>
        </Form>
      </Row>
      
    </>
  );
}

export default ChangePassword;
