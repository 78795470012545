import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Server from "../server/Server";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import TablePagination from "@mui/material/TablePagination";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import ReactLoading from "react-loading";
const token = localStorage.getItem("token");

function Row(props) {
  const { row, index } = props;
  const history = useNavigate();
  // const {} = props;
  const [open, setOpen] = useState(false);
  const [takeQ, setTakeQ] = useState(false);
  const [takeO, setTakeO] = useState(false);
  const [deleteO, setDeleteO] = useState(false);
  const [deleteQ, setDeleteQ] = useState(false);
  const [questionOpen, setQuestionOpen] = useState({});
  const [enteredQuestion, setEnteredQ] = useState("");
  const [optionOpen, setOptionOpen] = useState({});
  const [enteredOption, setEnteredOption] = useState("");
  const [idtoDelete, setOptionIddelete] = useState("");
  const [newOption, setNewOption] = useState("");
  const [selectedQid, setSelectedQid] = useState("");
  const [sQid, setSelectedDeleteQid] = useState("");
  const [addOptionNewopen, setAddOptionNewopen] = useState(false);
  let a = 1;

  // console.log(enteredOption,"the f data")
  const opeEditq = async (id) => {
    var t= localStorage.getItem("token");
    try {
      setTakeQ(true);
      axios
        .get(Server.server.link + "getOneQuestionforedit", {
          headers: { qid: id, "x-token": t },
        })
        .then((res) => {
          console.log(res.data.code,"the data is here")
          if (res.data.code === 203) {
            localStorage.clear();
            history("/");
            toast.error(res.data.message);
          } else if (res.data.code === 201) {
            toast.error(res.data.message);
          } else {
            setQuestionOpen(res.data.results);
            //   setOptionE(res.data.results.options)
          }
          console.log("this is the response", res);
        });
    } catch (e) {
      toast.error("Something Went Wrong");
    }
  };
  console.log(questionOpen, "mytest");

  const closeedit = async () => {
    setQuestionOpen({});
    setTakeQ(false);
    setEnteredQ("");
  };

  const closeeditOption = async () => {
    setOptionOpen({});
    setTakeO(false);
    setEnteredOption("");
  };

  const roleChangeHandler = async (event) => {
    var a = event.target.value;
    console.log("fadhdljf", a);
    if (a.trim().length === 0) {
      setEnteredQ(a.trim());
      // console.log(a.trim(),"the value ",a.trim().length)
    } else {
      setEnteredQ(a);
      // console.log(a,"value provided")
    }
  };

  const roleChangeHandlersofEdit = async (event) => {
    // var a= event.target.value
    console.log("fadhdljf", event.target.value);
    // if(a.trim().length===0){
    setEnteredOption(event.target.value);
    console.log(a.trim(), "the value ", a.trim().length);
    // } else {
    // setEnteredOption(a)
    console.log(a, "value provided");
    // }
  };

  const roleChangeHandlersofaddOption = async (event) => {
    // var a= event.target.value
    // if(a.trim().length===0){
    setNewOption(event.target.value);
    // console.log(a.trim(),"the value ",a.trim().length)
    // } else {
    // setEnteredOption(a)
    // console.log(a,"value provided")
    // }
  };

  const optionEdit = async (data) => {
    try {
      setTakeO(true);
      axios
        .get(Server.server.link + "getOneOption", {
          params: { questionId: data.qId, optionId: data._id },
          headers:{"x-token":localStorage.getItem("token")}
        })
        .then(async (res) => {
          if (res.data.code === 203) {
            localStorage.clear();
            history("/");
            toast.error(res.data.message);
          } else if (res.data.code === 201) {
            toast.error(res.data.message);
          } else {
            setOptionOpen(res.data.results);
          }
        });
    } catch (e) {
      // console.log(e,"option edit error")
      toast("Something went wrong");
    }
  };

  const optionDelete = async (data) => {
    try {
      //  setTakeO(true)
      axios
        .get(Server.server.link + "optionDeleteCheck", {
          headers: { adminqid: data.qId, optionId: data._id, "x-token": token },
        })
        .then(async (res) => {
          console.log(res.data.code, "option edit error");
          if (res.data.code === 200) {
            toast.error(res.data.message);
          } else if (res.data.code === 204) {
            setOptionIddelete(data._id);
            setDeleteO(true);
          }
        });
    } catch (e) {
      //  console.log(e,"option edit error")
      toast("Something went wrong");
    }
  };

  const sumbit = async (data) => {
    // console.log({id:data.id,option:data.option},"HEY MY BCTH")
    props.editOption({ id: data.id, option: data.option });
    setTakeO(false);
    setEnteredOption("");
    setOptionOpen({});
    // setTimeout(() => {

    //   }, 1000);
  };

  const sumbitnewOption = async (data) => {
    // console.log({id:data.id,option:data.option},"HEY MY BCTH")
    props.addOption({ id: data.id, option: data.option }).then(() => {
      setAddOptionNewopen(false);
      setSelectedQid("");
    });

    // toast.success(data.option)
    // props.editOption({id:data.id,option:data.option})
    // setTakeO(false)
    // setEnteredOption("")
    // setOptionOpen({})
    // setTimeout(() => {

    //   }, 1000);
  };

  const deleteConfirmFunction = async (id) => {
    props.deleteOption(id);
    setDeleteO(false);
    // try {
    //     await axios.post(Server.server.link+"deleteOptions",{optionId:id},{headers:{"x-token":token}}).then()
    // } catch (e) {

    // }
  };

  const addNewOption = async (id) => {
    setSelectedQid(id);
    setAddOptionNewopen(true);
  };

  const deleteQuestion = async (id) => {
    setSelectedDeleteQid(id);
    setDeleteQ(true);
    // toast.error(id)
  };

  const submitDeletion = async (id) => {
    props.deletingQuestion(id);
    setSelectedDeleteQid("");
    setDeleteQ(false);
  };

  return (
    <>
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell component="th" scope="row">
            {index + 1}
          </TableCell>
          <TableCell align="left">{row.question}</TableCell>
          <TableCell align="right">
            <Tooltip title="Edit">
              <IconButton
                onClick={() => {
                  opeEditq(row._id);
                }}
              >
                <EditIcon style={{ color: "blue" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Delete">
              <IconButton onClick={() => deleteQuestion(row._id)}>
                <DeleteIcon style={{ color: "red" }} />
              </IconButton>
            </Tooltip>
          </TableCell>

          <TableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Typography variant="h6" gutterBottom component="div">
                  <br /> Options
                  <Tooltip title="Add Option">
                    <IconButton
                      className="leftportion"
                      onClick={() => addNewOption(row._id)}
                    >
                      <AddIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
                <br />
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Sr. No.</TableCell>
                      <TableCell>Option</TableCell>
                      <TableCell align="right">Action</TableCell>
                      {/* <TableCell align="">Delete</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.options.map((historyRow) => (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {a++}
                        </TableCell>
                        <TableCell>{historyRow.option}</TableCell>
                        <TableCell align="right">
                          <Tooltip title="Edit">
                            <IconButton
                              onClick={() => {
                                optionEdit({
                                  _id: historyRow._id,
                                  qId: historyRow.questionId,
                                });
                              }}
                            >
                              <EditIcon style={{ color: "blue" }} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton
                              onClick={() => {
                                optionDelete({
                                  _id: historyRow._id,
                                  qId: historyRow.questionId,
                                });
                              }}
                            >
                              <DeleteIcon style={{ color: "red" }} />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        {/* <TableCell align="">
                                                <Button>delete</Button>
                                            </TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
      <Modal show={takeQ}>
        <Modal.Header>
          <Modal.Title>Edit Question</Modal.Title>
          <Tooltip title="Close the screen">
            <IconButton
              className="catBt1 p-inherit"
              onClick={closeedit}
              style={{ color: "blue" }}
              variant="contained"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Question</Form.Label>
              <Form.Control
                type="text"
                style={{ marginBottom: "10px" }}
                defaultValue={questionOpen.question}
                onChange={roleChangeHandler}
                required
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="catBt1 p-inherit"
            onClick={() => {
              props.editQuestion({
                data: { id: questionOpen._id, q: enteredQuestion },
              });
            }}
            variant="contained"
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={takeO}>
        <Modal.Header>
          <Modal.Title>Edit Option</Modal.Title>
          <Tooltip title="Close the screen">
            <IconButton
              className="catBt1 p-inherit"
              onClick={closeeditOption}
              style={{ color: "blue" }}
              variant="contained"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Option</Form.Label>
              <Form.Control
                type="text"
                style={{ marginBottom: "10px" }}
                defaultValue={optionOpen.option}
                onChange={roleChangeHandlersofEdit}
                required
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="catBt1 p-inherit"
            onClick={() => {
              sumbit({ id: optionOpen._id, option: enteredOption });
            }}
            variant="contained"
          >
            Edit
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={deleteO}>
        <Modal.Body>Would you like to delete this Option ?</Modal.Body>
        <Modal.Footer>
          {/* {amId.map(data=>( */}
          <>
            {/* <Button className='p-inherit' onClick={() => { deletingBackend(data.d); } } variant="contained"> <DeleteIcon /></Button> */}
            <Tooltip title="Delete">
              <IconButton
                className="catBt1 p-inherit"
                onClick={() => {
                  deleteConfirmFunction(idtoDelete);
                }}
                style={{ color: "blue" }}
                variant="contained"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Close the screen">
              <IconButton
                className="catBt1 p-inherit"
                onClick={() => {
                  setDeleteO(false);
                }}
                style={{ color: "blue" }}
                variant="contained"
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
            {/* <Button className='catBt1 p-inherits'  variant="contained"><CloseIcon/></Button> */}
          </>
          {/* // ))} */}
        </Modal.Footer>
      </Modal>

      <Modal show={deleteQ}>
        <Modal.Body>Would you like to delete this Question ?</Modal.Body>
        <Modal.Footer>
          {/* {amId.map(data=>( */}
          <>
            {/* <Button className='p-inherit' onClick={() => { deletingBackend(data.d); } } variant="contained"> <DeleteIcon /></Button> */}
            <Tooltip title="Delete">
              <IconButton
                className="catBt1 p-inherit"
                onClick={() => {
                  submitDeletion(sQid);
                }}
                style={{ color: "blue" }}
                variant="contained"
              >
                <DeleteIcon />
              </IconButton>
            </Tooltip>

            <Tooltip title="Close the screen">
              <IconButton
                className="catBt1 p-inherit"
                onClick={() => {
                  setDeleteQ(false);
                }}
                style={{ color: "blue" }}
                variant="contained"
              >
                <CloseIcon />
              </IconButton>
            </Tooltip>
            {/* <Button className='catBt1 p-inherits'  variant="contained"><CloseIcon/></Button> */}
          </>
          {/* // ))} */}
        </Modal.Footer>
      </Modal>

      <Modal show={addOptionNewopen}>
        <Modal.Header>
          <Modal.Title>Add New Option</Modal.Title>
          <Tooltip title="Close the screen">
            <IconButton
              className="catBt1 p-inherit"
              onClick={() => setAddOptionNewopen(false)}
              style={{ color: "blue" }}
              variant="contained"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Option</Form.Label>
              <Form.Control
                type="text"
                style={{ marginBottom: "10px" }}
                onChange={roleChangeHandlersofaddOption}
                required
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="catBt1 p-inherit"
            onClick={() => {
              sumbitnewOption({ id: selectedQid, option: newOption });
            }}
            variant="contained"
          >
            Add
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

Row.propTypes = {
  row: PropTypes.shape({
    calories: PropTypes.number.isRequired,
    carbs: PropTypes.number.isRequired,
    fat: PropTypes.number.isRequired,
    history: PropTypes.arrayOf(
      PropTypes.shape({
        amount: PropTypes.number.isRequired,
        customerId: PropTypes.string.isRequired,
        date: PropTypes.string.isRequired,
      })
    ).isRequired,
    name: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    protein: PropTypes.number.isRequired,
  }).isRequired,
};

//// PARENT FUNCTION ///////

export default function CollapsibleTable() {
  var history = useNavigate();
  const token = localStorage.getItem("token");
  const [questions, setUsers] = useState([]);
  const [totalDoc, setTotaldoc] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [enteredQuestion, setEnteredQ] = useState("");
  const [option1, setOption1] = useState("");
  const [option2, setOption2] = useState("");
  const [option3, setOption3] = useState("");
  const [add, setAdd] = useState(false);
  const [searchs, setSearch] = useState("");
  const [takeQ, setTakeQ] = useState(false);

  const deleteOption = async (id) => {
    // toast.error(id)
    try {
      await axios
        .post(
          Server.server.link + "deleteOptions",
          { optionId: id },
          { headers: { "x-token": token } }
        )
        .then(async (res) => {
          if (res.data.code === 203) {
            localStorage.clear();
            history("/");
            toast.error(res.data.message);
          } else if (res.data.code === 201) {
            toast.error(res.data.message);
          } else {
            var me = res.data.message;
            const data = await axios.get(
              Server.server.link + "getQuestionsForEdit",
              {
                headers: {
                  pageNumber: page,
                  limit: rowsPerPage,
                  search: "",
                  "x-token": token,
                },
              }
            );
            // console.log("this is current pagaDGFURLEIBCHFaCFKJIbnasdo:CFKLHasndfJnasdfKLJNe",data)
            if (data.data.code === 203) {
              localStorage.clear();
              history("/");
              toast.error(data.data.message);
            } else {
              history("/home/adminquestions");
              setUsers(data.data.data);
              toast.success(me);
              setTotaldoc(data.data.totalDocuments);
            }
          }
        });
    } catch (e) {}
  };

  const deletingQuestion = (id) => {
    // console.log("id ...",id)
    var tokens = localStorage.getItem("token");
    axios
      .post(
        Server.server.link + "deleteQuestion",
        { questionId: id },
        { headers: { "x-token": tokens } }
      )
      .then(async (respo) => {
        if (respo.data.code === 203) {
          localStorage.clear();
          toast.error(respo.data.message);
          history("/");
        } else if (respo.data.code === 201) {
          toast.error(respo.data.message);
        } else {
          toast.success(respo.data.message);
          const data = await axios.get(
            Server.server.link + "getQuestionsForEdit",
            {
              headers: {
                pageNumber: page,
                limit: rowsPerPage,
                search: "",
                "x-token": token,
              },
            }
          );
          // console.log("this is current pagaDGFURLEIBCHFaCFKJIbnasdo:CFKLHasndfJnasdfKLJNe",data)
          if (data.data.code === 203) {
            localStorage.clear();
            history("/");
            toast.error(data.data.message);
          } else {
            history("/home/adminquestions");
            setUsers(data.data.data);
            setTotaldoc(data.data.totalDocuments);
          }
        }
      });
  };

  const addOption = async (data) => {
    var option = data.option;
    if (option.trim().length === 0) {
      option = option.trim();
    } else {
      option = data.option;
    }

    if (option === "") {
      toast.error("Please fill the Option field whitspaces are not allowed");
    } else {
      try {
        axios
          .post(
            Server.server.link + "addNewOption",
            { id: data.id, option: data.option },
            { headers: { "x-token": token } }
          )
          .then(async (res) => {
            if (res.data.code === 203) {
              localStorage.clear();
              history("/");
              toast.error(res.data.message);
            } else if (res.data.code === 201) {
              toast.error(res.data.message);
            } else {
              var message = res.data.message;

              const data = await axios.get(
                Server.server.link + "getQuestionsForEdit",
                {
                  headers: {
                    pageNumber: page,
                    limit: rowsPerPage,
                    search: "",
                    "x-token": token,
                  },
                }
              );
              // console.log("this is current pagaDGFURLEIBCHFaCFKJIbnasdo:CFKLHasndfJnasdfKLJNe",data)
              if (data.data.code === 203) {
                localStorage.clear();
                history("/");
                toast.error(data.data.message);
              } else {
                history("/home/adminquestions");
                toast.success(message);
                setUsers(data.data.data);
                setTotaldoc(data.data.totalDocuments);
              }
            }
          });
      } catch (e) {
        console.log(e);
        toast.error("Something went wrong");
      }
    }
    // console.log(data,"this is the data")
  };

  const editQuestion = async (data) => {
    var qu = data.data.q;
    // console.log(data,"this is the data")
    if (qu.trim().length === 0) {
      qu = data.data.q.trim();
    } else {
      qu = data.data.q;
    }

    if (qu === "") {
      toast.error("Please fill the question field whitspaces are not allowed");
    } else {
      // console.log(data.data.id,"fhhhfhhh")
      try {
        axios
          .post(
            Server.server.link + "updateQuestionForAdmin",
            { question: qu },
            { headers: { "x-token": token, qid: data.data.id } }
          )
          .then(async (res) => {
            if (res.data.code === 203) {
              localStorage.clear();
              history("/");
              toast.error(res.data.message);
            } else if (res.data.code === 201) {
              toast.error(res.data.message);
            } else {
              toast.success(res.data.message);

              // useEffect()
              setEnteredQ("");
              setTakeQ(false);
              // history('/home/adminquestions')
              // window.location.reload('/home/adminquestions')
              const data = await axios.get(
                Server.server.link + "getQuestionsForEdit",
                {
                  headers: {
                    pageNumber: page,
                    limit: rowsPerPage,
                    search: "",
                    "x-token": token,
                  },
                }
              );
              // console.log("this is current pagaDGFURLEIBCHFaCFKJIbnasdo:CFKLHasndfJnasdfKLJNe",data)
              if (data.data.code === 203) {
                localStorage.clear();
                history("/");
                toast.error(data.data.message);
              } else {
                history("/home/adminquestions");
                setUsers(data.data.data);
                setTotaldoc(data.data.totalDocuments);
                //   console.log(data.data,"daaaaaaa")
              }
              // CollapsibleTable.setUsers()
            }
          });
      } catch (e) {
        toast.error("Something went wrong");
      }
    }
  };

  const editOption = async (data) => {
    var option = data.option;
    // console.log(data,"this is the data we needed",option)
    if (option.trim().length === 0) {
      option = option.trim();
    }

    if (option === "") {
      toast.error(
        "Option is not allowded to be empty whitespaces are not allowed"
      );
    } else {
      try {
        axios
          .post(
            Server.server.link + "optionUpdate",
            { option: option },
            { headers: { "x-token": token, optionid: data.id } }
          )
          .then(async (res) => {
            if (res.data.code === 203) {
              localStorage.clear();
              history("/");
              toast.error(res.data.message);
            } else if (res.data.code === 201) {
              toast.error(res.data.message);
            } else {
              toast.success(res.data.message);
              setTakeQ(false);
              const data = await axios.get(
                Server.server.link + "getQuestionsForEdit",
                {
                  headers: {
                    pageNumber: page,
                    limit: rowsPerPage,
                    search: "",
                    "x-token": token,
                  },
                }
              );
              // console.log("this is current pagaDGFURLEIBCHFaCFKJIbnasdo:CFKLHasndfJnasdfKLJNe",data)
              if (data.data.code === 203) {
                localStorage.clear();
                history("/");
                toast.error(data.data.message);
              } else {
                history("/home/adminquestions");
                setUsers(data.data.data);
                setTotaldoc(data.data.totalDocuments);
              }
            }
          });
      } catch (e) {
        toast.error("Something went wrong");
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event, newPage) => {
    // console.log(event.target.value,"dataaa")
    if (event.target.value === "all") {
      toast.success(`Total number of Faq's : ${totalDoc}`);
      setRowsPerPage(totalDoc);
      setPage(0);
    } else {
      setRowsPerPage(+event.target.value);
      // setPage(0)
    }
  };

  const AddAmenitiesPopup = () => {
    setAdd(true);
  };

  const closecreate = () => {
    setAdd(false);
    // setIdView([])
    setOption3("");
    setOption1("");
    setOption2("");
    setEnteredQ("");
  };

  useEffect(() => {
    const fetchDatas = async () => {
      console.log(page, "this is current page");
      const res = await axios.get(Server.server.link + "getQuestionsForEdit", {
        headers: {
          pageNumber: page,
          limit: rowsPerPage,
          search: "",
          "x-token": token,
        },
      });
      console.log(
        "this is current pagaDGFURLEIBCHFaCFKJIbnasdo:CFKLHasndfJnasdfKLJNe"
      );
      if (res.data.code === 203) {
        localStorage.clear();
        history("/");
        toast.error(res.data.message);
      } else {
        setUsers(res.data.data);
        setTotaldoc(res.data.totalDocuments);
        console.log(res.data, "the data was not good");
      }
    };
    fetchDatas();
  }, [page, rowsPerPage]);

  const roleChangeHandler = (event) => {
    var a = event.target.value;
    if (a.trim().length === 0) {
      setEnteredQ(a.trim());
      // console.log(a.trim(),"the value ",a.trim().length)
    } else {
      setEnteredQ(a);
      // console.log(a,"value provided")
    }

    console.log(event.target.value, "data question");
  };

  const roleChangeHandlers1 = (event) => {
    var a = event.target.value;
    if (a.trim().length === 0) {
      setOption1(a.trim());
      // console.log(a.trim(),"the value 1",a.trim().length)
    } else {
      setOption1(a);
      // console.log(a,"value 1 provided")
    }
    // (event.target.value);
    console.log(event.target.value, "data answer");
  };

  const roleChangeHandlers2 = (event) => {
    var a = event.target.value;
    if (a.trim().length === 0) {
      setOption2(a.trim());
      // console.log(a.trim(),"the value 2",a.trim().length)
    } else {
      setOption2(a);
      // console.log(a,"value 2 provided ")
    }
    // (event.target.value);
    console.log(event.target.value, "data answer");
  };

  const roleChangeHandlers3 = (event) => {
    var a = event.target.value;
    if (a.trim().length === 0) {
      setOption3(a.trim());
      // console.log(a.trim(),"the value 3",a.trim().length)
    } else {
      setOption3(a);
      // console.log(a,"value 3 provided ")
    }
  };

  const addAmenitie = async () => {
    var array = [];
    if (option1 !== "") {
      array.push(option1);
    }

    if (option2 !== "") {
      array.push(option2);
    }

    if (option3 !== "") {
      array.push(option3);
    }

    console.log(option1===option2,"this is the array" )
    // if(option1 !=="" || option2 =="" && option3===""){

    // }
    if (enteredQuestion === "") {
      toast.error("Please fill the question field whitspaces are not allowed");
    } else if (option1 === "" && option2 === "" && option3 === "") {
      toast.error(
        "Please provide minimum two options whitspaces are not allowed"
      );
    } else if(option1===option2 || option1===option3 || option2===option1){
      toast.error(
        "Please provide Different options whitspaces are not allowed"
      );
    } else if (array.length < 2) {
      toast.error(
        "Please provide minimum two options whitspaces are not allowed"
      );
    } else {
      axios
        .post(
          Server.server.link + "addQuestionsFromCategory",
          { question: enteredQuestion, options: array },
          { headers: { "x-token": token } }
        )
        .then(async (respo) => {
          if (respo.data.code === 203) {
            toast.error(respo.data.message);
            history("/");
          } else if (respo.data.code === 201) {
            toast.error(respo.data.message);
          } else {
            toast.success(respo.data.message);
            setAdd(false);
            setOption3("");
            setOption1("");
            setOption2("");
            setEnteredQ("");
            const res = await axios.get(
              Server.server.link + "getQuestionsForEdit",
              {
                headers: {
                  pageNumber: page,
                  limit: rowsPerPage,
                  "x-token": token,
                  search: searchs,
                },
              }
            );
            if (res.data.code === 203) {
              localStorage.clear();
              history("/");
              toast.error(res.data.message);
            } else {
              setUsers(res.data.data);
              setTotaldoc(res.data.totalDocuments);
              history("/home/adminquestions");
            }
          }
        });
    }
  };
  const searType = async (e) => {
    setSearch(e.target.value);
    console.log(e.target.value, "this is the searched key");
    let a = e.target.value;
    // let setA =page
    // if(page<=rowsPerPage){
    //   setA=0
    // }
    const res = await axios.get(Server.server.link + "getQuestionsForEdit", {
      headers: {
        pageNumber: page,
        limit: rowsPerPage,
        "x-token": token,
        search: a,
      },
    });
    if (res.data.code === 203) {
      localStorage.clear();
      history("/");
      toast.error(res.data.message);
    } else {
      setUsers(res.data.data);
      setTotaldoc(res.data.totalDocuments);
      history("/home/adminquestions");
    }
  };
  return (
    <>
      <Stack direction="row">
        <TextField
          variant="standard"
          name="Search"
          onChange={searType}
          placeholder="Search with question"
        />
        {/* onChange={searType} */}
      </Stack>
      <Stack direction="row">
        <Tooltip title="Add Question">
          <IconButton className="addQ" onClick={AddAmenitiesPopup}>
            <AddIcon style={{ color: "blue" }} />
          </IconButton>
        </Tooltip>
      </Stack>
      {questions.length === 0 ? (
        <div className="hey">
          <ReactLoading
            type={"spokes"}
            color={"rgb(9 167 167)"}
            height={50}
            width={50}
          />
        </div>
      ) : (
        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead>
              <TableRow>
                <TableCell className="sno">S/N</TableCell>
                <TableCell align="left">Question</TableCell>
                <TableCell align="right">Action</TableCell>

                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {questions.map((row, i) => (
                <Row
                  key={row.question}
                  row={row}
                  addOption={addOption}
                  deletingQuestion={deletingQuestion}
                  deleteOption={deleteOption}
                  editQuestion={editQuestion}
                  editOption={editOption}
                  index={i}
                />
              ))}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={
              totalDoc < 5
                ? [totalDoc, 5, 10, 25, 100]
                : totalDoc === 5
                ? [5, 10, 25, 100]
                : totalDoc < 10 && totalDoc > 5
                ? [5, totalDoc, 10, 25, 100]
                : totalDoc === 10
                ? [5, 10, 25, 100]
                : totalDoc < 25 && totalDoc > 10
                ? [5, 10, totalDoc, 25, 100]
                : totalDoc === 25
                ? [5, 10, 25, 100]
                : totalDoc < 100 && totalDoc > 25
                ? [5, 10, 25, totalDoc, 100]
                : totalDoc === 100
                ? [5, 10, 25, 100]
                : [5, 10, 25, 100, totalDoc]
            }
            // rowsPerPageOptions={[5,10, 25, 100,totalDoc]}
            component="div"
            count={totalDoc}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </TableContainer>
      )}

      <Modal show={add}>
        <Modal.Header>
          <Modal.Title>Create New Admin Question</Modal.Title>
          <Tooltip title="Close the screen">
            <IconButton
              className="catBt1 p-inherit"
              onClick={closecreate}
              style={{ color: "blue" }}
              variant="contained"
            >
              <CloseIcon />
            </IconButton>
          </Tooltip>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Question</Form.Label>
              <Form.Control
                type="text"
                style={{ marginBottom: "10px" }}
                placeholder="question is required"
                onChange={roleChangeHandler}
                required
              />
              <Form.Label>Options</Form.Label>
              <Form.Control
                type="text"
                onChange={roleChangeHandlers1}
                required
              />
              <Form.Control
                type="text"
                onChange={roleChangeHandlers2}
                required
              />
              <Form.Control
                type="text"
                onChange={roleChangeHandlers3}
                required
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="catBt1 p-inherit"
            onClick={addAmenitie}
            variant="contained"
          >
            Add
          </Button>
          {/* <Tooltip title="Submit the Question" >
                                                <IconButton className='catBt1 p-inherit' onClick={addAmenitie}  variant="contained">
                                                  <p>ADD</p>
                                                </IconButton>
                                                </Tooltip> */}

          {/* <Button className='catBt1 p-inherit'   variant="contained"><AddIcon/></Button><br/> */}
          {/* <Button className='catBt1 p-inherit' onClick={closecreate}   variant="contained"><CloseIcon/></Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}
