import React, { useEffect, useState, useRef } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Server from "../server/Server";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import CancelIcon from "@mui/icons-material/Cancel";
import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp';
import Button from "@mui/material/Button";
import EditSharpIcon from "@mui/icons-material/EditSharp";
import DeleteIcon from "@mui/icons-material/Delete";
import Stack from "@mui/material/Stack";
// import Popup from "../../../additional/Popup";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import ButtonGroup from "@mui/material/ButtonGroup";
import BlockRoundedIcon from "@mui/icons-material/BlockRounded";
import LockOpenRoundedIcon from "@mui/icons-material/LockOpenRounded";
import BlockIcon from "@mui/icons-material/Block";
import AppRegistrationRoundedIcon from "@mui/icons-material/AppRegistrationRounded";
import Chip from "@mui/material/Chip";
import HttpsRoundedIcon from "@mui/icons-material/HttpsRounded";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SearchIcon from "@mui/icons-material/Search";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import Grid from "@mui/material/Grid";
import Modal from "react-bootstrap/Modal";
import ReactLoading from "react-loading";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function ReportDetails() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const history = useNavigate();
  const token = localStorage.getItem("token");
  const [users, setUsers] = useState(undefined);
  const [totalDoc, setTotaldoc] = useState("");
  const [id_neededTodelete, setIddelete] = useState([]);
  const [blockPopup, setBlockPopup] = useState(false);
  const [idNeedtoView, setIdView] = useState([]);
  const [detailsPopup, setDetailsPopup] = useState(false);
  const [unBlockId, setUids] = useState([]);
  const [unBlockPopup, setunBlockPopup] = useState(false);
  const [searchs, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const { id } = useParams();

  useEffect(() => {
    const fetchDatas = async () => {
      console.log(page, "this is current page");
      const res = await axios.get(Server.server.link + "reportDetails", {
        headers: {
          pageNumber: page,
          limit: rowsPerPage,
          userId: id,
          "x-token": token,
        },
      });

      if (res.data.code === 203) {
        localStorage.clear();
        history("/");
        toast.error(res.data.message);
      } else {
        // toast.success(res.data.message)
        setUsers(res.data.data);
        setTotaldoc(res.data.totalDocuments);
        console.log(res.data, "daaaaaaa");
        // setTotalPages(res.data.totalPages)
      }
    };
    fetchDatas();
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event, newPage) => {
    console.log(event.target.value, "dataaa");
    if (event.target.value === "all") {
      setRowsPerPage(totalDoc);
      setPage(0);
    } else {
      setRowsPerPage(+event.target.value);
      setPage(0);
    }
  };
  let a = 1;
  const h = () => {
    setShow(false);
  };
  const redirect = () => {
    history(`/home/reportTable`);
  };
  const adminBlock = async (id) => {
    console.log("......asdfhwerf", id);
    let arr = [];
    arr.push({ userId: id });
    setIddelete(arr);
    setShow(true);
  };
  const onHide = () => {
    setShow(false);
  };

  const adminBlockUser = async (id) => {
    axios
      .post(Server.server.link + "blockUser", { userId: id })
      .then(async (respo) => {
        toast.success(respo.data.message);
        setShow(false);
        setIddelete([]);
        const res = await axios.get(Server.server.link + "reportDetails", {
          headers: {
            pageNumber: page,
            limit: rowsPerPage,
            "x-token": token,
            search: searchs,
          },
        });
        if (res.data.code === 203) {
          localStorage.clear();
          history("/");
          toast.error(res.data.message);
        } else {
          setTotaldoc(res.data.totalDocuments);
          setUsers(res.data.data);
          history("/home/userlist");
        }
      });
  };

  var unBlockDatas = async (id) => {
    console.log("......id id id i don't like it ", id);
    let arr = [];
    arr.push({ d: id });
    setUids(arr);
    setunBlockPopup(true);
  };

  const unblockUser = (id) => {
    axios
      .post(Server.server.link + "unblockUser", { userId: id })
      .then(async (respo) => {
        if (respo.data.code == 202) {
          toast.error(respo.data.message);
          setunBlockPopup(false);
        } else {
          toast.success(respo.data.message);
          setunBlockPopup(false);
          const res = await axios.get(Server.server.link + "reportedlist", {
            headers: {
              pageNumber: page,
              limit: rowsPerPage,
              search: searchs,
              "x-token": token,
            },
          });
          if (res.data.code === 203) {
            localStorage.clear();
            history("/");
            toast.error(res.data.message);
          } else {
            setTotaldoc(res.data.totalDocuments);
            setUsers(res.data.data);
            history("/home/userlist");
          }
        }
      });
  };

  const details = async (id) => {
    {
      users.map((row) => {
        if (row._id === id) {
          history(`/home/reporteduser/${id}`);
        }
        // history(`/Dashboard/reportuser/${id}`)
      });
    }
  };

  const searType = async (e) => {
    setSearch(e.target.value);
    console.log(e.target.value, "this is the searched key");
    let a = e.target.value;
    const res = await axios.get(Server.server.link + "reportDetails", {
      headers: {
        pageNumber: page,
        limit: rowsPerPage,
        search: a,
        "x-token": token,
      },
    });

    if (res.data.code === 203) {
      // setLoading(false)
      localStorage.clear();
      history("/");
      toast.error(res.data.message);
    } else {
      // setLoading(false)
      setTotaldoc(res.data.totalDocuments);
      setUsers(res.data.data);
      // setTotalDoc(res.data.total)
    }
  };

  // const setreportdata=(id)=>{
  //   let arr=[]
  //   arr.push({userId:id})
  //   setUserId(arr)
  //   setDeletePopup(true)
  //   console.log(userId,"cnbvkjghfkl ld",id)
  // }

  // const redirectTo=(id)=>{
  //   {users.map((row) =>{
  //     if(row._id===id){
  //       if(row.reportCount>0){
  //         history(`/Dashboard/reportuser/${id}`)
  //       }else{
  //         toast.success("No reports yet")
  //       }
  //     }
  //     // history(`/Dashboard/reportuser/${id}`)
  //   })

  // }

  // }

  // let length= 0
  // if(users.length>0){
  //   length=users.length
  // }

  return (
    <>
      {/* <Stack  direction="row">
               
               <TextField label="Search" name="Search" value={searchs} onChange={searType}/> 

            </Stack> */}
      {!users ? (
        <div className="hey">
          <ReactLoading
            type={"spokes"}
            color={"rgb(9 167 167)"}
            height={50}
            width={50}
          />
        </div>
      ) : (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>S.no</StyledTableCell>
                  <StyledTableCell align="left">UserName</StyledTableCell>
                  <StyledTableCell align="left">Email</StyledTableCell>
                  <StyledTableCell align="left">Report Text</StyledTableCell>
                  <StyledTableCell align="left">Mobile</StyledTableCell>

                  <StyledTableCell align="left">Verify</StyledTableCell>
                  <StyledTableCell align="left">Block</StyledTableCell>
                  {/* <StyledTableCell align="left">Block</StyledTableCell>
    <StyledTableCell align="center">Action</StyledTableCell> */}
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((row) => (
                  <TableRow>
                    <TableCell align="left">{a++}</TableCell>
                    <TableCell align="left">{row.username}</TableCell>
                    <TableCell align="left">{row.email}</TableCell>
                    <TableCell align="left">{row.reportedText}</TableCell>
                    <TableCell align="left">
                      {row.countryCode} {row.contact}
                    </TableCell>

                    {row.isEmailVerified === true ? (
                      <TableCell align="left">
                        <VerifiedUserIcon className="trueicon" />
                      </TableCell>
                    ) : (
                      <TableCell align="left">
                        <CancelIcon className="falseicon" />
                      </TableCell>
                    )}
                    {/* <TableCell align="left">{row.countOf.reportCount}</TableCell> */}
                    {row.adminBlock === true ? (
                      <TableCell align="left">
                        <HttpsRoundedIcon className="blockedUsericon" />
                      </TableCell>
                    ) : (
                      <TableCell align="left">
                        <LockOpenOutlinedIcon className="noBlUser" />
                      </TableCell>
                    )}
                    {/* {row.adminBlock===true 
               <TableCell align="center">  </TableCell> : ""
              } */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={
              totalDoc < 5
                ? [totalDoc, 5, 10, 25, 100]
                : totalDoc === 5
                ? [5, 10, 25, 100]
                : totalDoc < 10 && totalDoc > 5
                ? [5, totalDoc, 10, 25, 100]
                : totalDoc === 10
                ? [5, 10, 25, 100]
                : totalDoc < 25 && totalDoc > 10
                ? [5, 10, totalDoc, 25, 100]
                : totalDoc === 25
                ? [5, 10, 25, 100]
                : totalDoc < 100 && totalDoc > 25
                ? [5, 10, 25, totalDoc, 100]
                : totalDoc === 100
                ? [5, 10, 25, 100]
                : [5, 10, 25, 100, totalDoc]
            }
            component="div"
            count={totalDoc}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}
      <Button onClick={() => redirect()}>
        Report list
        <KeyboardReturnIcon />
      </Button>
      {/* <Popup my="unblock"
                    className='unblockpopup'
                    openPopup={unBlockPopup}
                    setOpenpopup={setunBlockPopup}
                    title="Would you like to unblock this user ?"
                    >
                    {unBlockId.map((data)=>(
                      <>
                      <Button className='p-inherit' onClick={()=>{unblockUser(data.d)}} variant="contained"> <LockOpenRoundedIcon /></Button> 
                      <Button className='catBt1 p-inherit' onClick={()=>{setunBlockPopup(false)}}  variant="contained"><CloseIcon/></Button></>
                    ))}
          </Popup>  */}
    </>
  );
}

export default ReportDetails;
