/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState, useRef } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Server from "../server/Server";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
// import Box from '@mui/material/Box';
import CloseIcon from "@mui/icons-material/Close";
import Modal from "react-bootstrap/Modal";
import AddIcon from "@mui/icons-material/Add";
import Form from "react-bootstrap/Form";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import ReactLoading from "react-loading";
import ButtonGroup from "@mui/material/ButtonGroup";
import { Tooltip } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { Col, Row } from "react-bootstrap";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function Category() {
  const [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(5);
  const history = useNavigate();
  const token = localStorage.getItem("token");
  const [users, setUsers] = useState(undefined);
  const [totalDoc, setTotaldoc] = useState("");
  const [f_total,setF_Total]= useState(totalDoc)
  const [editAm, setIdView] = useState([]);
  const [amId, setUids] = useState([]);
  const [edit, setEdit] = useState(false);
  const [searchs, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [add, setAdd] = useState(false);
  const [enteredAminities, setEntered] = useState("");
  const [categoryImage, setCategoryImage] = useState("");
  const [editCatImage, setEditcatImage] = useState("");
  const [editCategorys, setEditCategorys] = useState([]);
  const [editCatName, setEditCatName] = useState("");
  // const [categoryName,setCategoryName]=useState('');
  useEffect(() => {
    const fetchDatas = async () => {
      console.log(page, "this is current page");
      const res = await axios.get(Server.server.link + "getCategorys", {
        params:{
          pageNumber: page,
          limit: rowsPerPage,
          search: searchs,
        },headers: {
          "x-token": token,
        },
      });

      if (res.data.code === 203) {
        localStorage.clear();
        history("/");
        toast.error(res.data.message);
      } else {
        // toast.success(res.data.message)
        setUsers(res.data.data);
        setTotaldoc(res.data.totalDocuments);
        setF_Total(res.data.totalDocuments)
        console.log(res.data, "this is the category");
        // setTotalPages(res.data.totalPages)
      }
    };
    fetchDatas();
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const editCatname = (e) => {
    if (e.target.value.trim().length === 0) {
      setEditCatName(e.target.value.trim());
      // console.log(a.trim(),"the value ",a.trim().length)
    } else {
      // setEnteredQ(a);
      setEditCatName(e.target.value);
      // console.log(a,"value provided")
    }
  };

  const EditCatImage = (e) => {
    // if (e.target.value.trim().length === 0) {
    //   setEditcatImage(e.target.value.trim());
    //   // console.log(a.trim(),"the value ",a.trim().length)
    // } else {
    //   // setEnteredQ(a);
    //   setEditcatImage(e.target.value);
    //   // console.log(a,"value provided")
    // }
    setEditcatImage(e.target.files[0]);
  };

  const handleChangeRowsPerPage = (event, newPage) => {
    console.log(event.target.value, "dataaa");
    if (event.target.value === "all") {
      // let ah=totalDoc
      toast.success(`Total Amenties : ${totalDoc}`);
      setRowsPerPage(totalDoc);
      setPage(0);
    } else {
      setRowsPerPage(+event.target.value);
      setPage(0);
    }
  };
  let a = 1;
  const h = () => {
    setShow(false);
  };
  const AddAmenitiesPopup = () => {
    setAdd(true);
  };
  const categoryImages = (e) => {
    setCategoryImage(e.target.files[0]);
  };

  const addAmenitie = async (e) => {
    const amenitie = enteredAminities;
    console.log("data is here....", amenitie);
    var check = amenitie.trim().length;
    if (amenitie == "") {
      toast.error("Please Fill the Amenities field");
    } else if (check == 0) {
      toast.error(
        "Please Fill the Amenities field white spaces are not allowed"
      );
    } else if (amenitie.length > 10) {
      toast.error("More than 10 characters not allowed");
    } else {
      e.preventDefault();
      const formData = new FormData();
      formData.append("image", categoryImage);
      formData.append("categoryName", amenitie);
      axios
        .post(Server.server.link + "addCategory", formData, {
          headers: {
            "x-token": `${token}`,
          },
        })
        .then(async (response) => {
          console.log(response, "////");
          if (response.data.code == 203) {
            localStorage.clear();
            history("/");
            toast.error(response.data.message);
          } else if (response.status == 202) {
            toast.error(response.data.message);
          } else if (response.status == 200) {
            setCategoryImage("");
            toast.success(response.data.message);
            setAdd(false);
            setEntered("");
            const res = await axios.get(Server.server.link + "getCategorys", {
              params:{
                pageNumber: page,
                search: searchs,
                limit: rowsPerPage,
              },headers: {
                "x-token": token,
              },
            });

            if (res.data.code === 203) {
              localStorage.clear();
              history("/");
              toast.error(res.data.message);
            } else {
              setTotaldoc(res.data.totalDocuments);
              setUsers(res.data.data);
              history("/home/categorys");
            }
            //  history('/Dashboard/category')
          }
        })
        .catch((error) => {
          console.log(error);
        });
      //  const data=await axios.post(Server.server.link+'createAminities',{"categoryName":amenitie},{headers:{"x-token":token}})
      // //  console.log(data.data.code,"this is the fucking data")
      // //  .then( async (respo)=>{
      //     if(data.data.code==203){
      //       console.log(data,"fassdf")
      //       localStorage.clear()
      //       history('/login')
      //       toast.error(data.data.message)
      //     }else{
      //       console.log(data.data.code,"dfdfdfdf")
      //       toast.success(data.data.message)
      //       setAdd(false)
      //       setEntered("")
      //       const res=await axios.get(Server.server.link+'getAminities',{headers:{"pageNumber":page,"limit":rowsPerPage,"x-token":token,"search":searchs}})
      //         if(res.data.code===203){
      //           localStorage.clear()
      //           history('/login')
      //           toast.error(res.data.message)
      //         }else{
      //           setTotaldoc(res.data.totalDocuments)
      //           setUsers(res.data.data)
      //           history('/home/amenities')
      //         }
      //     }

      // })
    }
  };

  const editAmenitie = async (e) => {
    const amenitie = editCatName;
    //     console.log("data is here....",amenitie)
    var check = amenitie.trim().length;
    //   if(check==0){
    //       toast.error("Please Fill the Amenities field white spaces are not allowed")
    //     }else{
    e.preventDefault();
    editCategorys.map((data) => {
      // console.log(editCatName,"..dsofish")

      const formData = new FormData();
      formData.append("CatId", data._id);
      formData.append("image", editCatImage);
      formData.append("categoryName", editCatName);
      console.log(data, "dkjsdhfsdfksdfk", editCatImage, "xhkhkkf");
      if (editCatImage == "" && editCatName == "") {
        toast.error("Update any of the field");
      } else {
        axios
          .post(Server.server.link + "updateCategory", formData, {
            headers: {
              "x-token": `${token}`,
            },
          })
          .then(async (response) => {
            console.log(response.data.code, "....");
            if (response.data.code === 202) {
              toast.error(response.data.message);
            }
            else if(response.data.code === 201){
              toast.error('category name length must be less than or equalt to 10');
            }
             else {
              setEditcatImage("");
              setEditCatName("");
              setEditCategorys([]);
              toast.success(response.data.message);
              // setEditOpen(false)
              setEdit(false);
              const res = await axios.get(Server.server.link + "getCategorys", {
               params:{
                pageNumber: page,
                limit: rowsPerPage,
                search: searchs,
               }, headers: {
                 
                  "x-token": token,
                  
                },
              });

              if (res.data.code === 203) {
                localStorage.clear();
                history("/");
                toast.error(res.data.message);
              } else {
                // setTotal(res.data.totalPages)
                // // setPosts([]);
                // setPosts(res.data.data);
                // if( res.data.data.length===res.data.totalDocuments){
                //   setCurrentpage(0)
                //   toast.success(`Total Categorys:${res.data.totalDocuments}`)
                // }

                // if(res.data.data.length===0){
                //   setCurrentpage(0)
                //   toast.error(`Total Categorys:${res.data.totalDocuments}`)
                // }
                setTotaldoc(res.data.totalDocuments);
                setF_Total(res.data.totalDocuments)
                setUsers(res.data.data);
                history("/home/categorys");
                // setStatus(res.data.data[0].active)
              }
              //  history.push('/admin/categorys')
            }
          });
      }
    });
    // axios.post(Server.server.link+'updateAminities',{"amenities":amenitie ,"id":id},{headers:{"x-token":token}}).then( async (respo)=>{
    //   // console.log(respo.data,"hey mahbnssss")
    //   if(respo.data.code==203){
    //     localStorage.clear()
    //     history('/login')
    //     toast.error(respo.data.message)
    //   }
    //   else if(respo.data.code ===201){
    //     toast.error(respo.data.message)
    //   }else{
    //     toast.success(respo.data.message)
    //     setEdit(false)
    //     setEntered("")
    //     const res=await axios.get(Server.server.link+'getAminities',{headers:{"pageNumber":page,"limit":rowsPerPage,"x-token":token,"search":searchs}})
    //       if(res.data.code===203){
    //         localStorage.clear()
    //         history.push('/login')
    //         toast.error(res.data.message)
    //       }else{
    //         setTotaldoc(res.data.totalDocuments)
    //         setUsers(res.data.data)
    //         history('/home/amenities')
    //       }
    //   }

    // })
    //   }
  };

  var deleteAmini = async (id) => {
    console.log("......id id id i don't like it ", id);
    let arr = [];
    arr.push({ d: id });
    setUids(arr);
    setShow(true);
  };

  const deletingBackend = (id) => {
    console.log("id ...", id);
    axios
      .post(
        Server.server.link + "deleteCategory",
        { categoryId: id },
        { headers: { "x-token": token } }
      )
      .then(async (respo) => {
        if (respo.data.code == 202) {
          toast.error(respo.data.message);
          setShow(false);
        } else {
          toast.success(respo.data.message);
          setShow(false);
          const res = await axios.get(Server.server.link + "getCategorys", {
            params:{
              pageNumber: page,
              limit: rowsPerPage,
              search: searchs,
             }, headers: {
              // pageNumber: page,
              // limit: rowsPerPage,
              // search: searchs,
              "x-token": token,
            },
          });
          if (res.data.code === 203) {
            localStorage.clear();
            history("/");
            toast.error(res.data.message);
          } else {
            setTotaldoc(res.data.totalDocuments);
            setUsers(res.data.data);
            history("/home/categorys");
          }
        }
      });
  };

  const searType = async (e) => {
    setSearch(e.target.value);
    console.log(e.target.value, "this is the searched key");
    let a = e.target.value;
    // let setA =page
    // if(page<=rowsPerPage){
    //   setA=0
    // }
    const res = await axios.get(Server.server.link + "getCategorys", {
      params:{
        pageNumber: '0',
        limit: f_total,
        search: a,
      },headers: {
       
        "x-token": token,
      },
    });

    if (res.data.code === 203) {
      // setLoading(false)
      localStorage.clear();
      history("/");
      toast.error(res.data.message);
    } else {
      // setLoading(false)
      setTotaldoc(res.data.totalDocuments);
      setUsers(res.data.data);
      // setTotalDoc(res.data.total)
    }
  };

  const getOneAminitie = async (id) => {
    // let arr=[]

    // setEdit(true)
    // const data = await axios.get(Server.server.link+'getOneAminitie',{headers:{amId:id,"x-token":token}})
    // arr.push(data.data.data)
    // setIdView(arr)
    var to = localStorage.getItem("token");
    var data = await axios.get(Server.server.link + "getOneCategory", {
      headers: { categoryid: id, "x-token": to },
    });
    if (data.data.code === 203) {
      toast.error(data.data.message);
      history("/");
    } else {
      let arr = [];
      setEdit(true);
      console.log(data, "data is here");
      arr.push(data.data.category);
      setEditCategorys(arr);
      setIdView(arr);
    }
  };

  const roleChangeHandler = (event) => {
    setEntered(event.target.value);
    console.log(enteredAminities, "data");
  };

  const removeData = () => {
    setIdView([]);
    setEdit(false);
  };
  return (
    <>
      <Stack direction="row">
        <TextField
          label="Search"
          name="Search"
          value={searchs}
          placeholder="Search for Categorys"
          onChange={searType}
        />
      </Stack>
      <Stack direction="row">
        <Button className="AddAmenities" onClick={AddAmenitiesPopup}>
          {" "}
          <AddIcon /> Add Category
        </Button>
      </Stack>
      {!users ? (
        <div className="hey">
          <ReactLoading
            type={"spokes"}
            color={"rgb(9 167 167)"}
            height={50}
            width={50}
          />
        </div>
      ) : (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>S.no</StyledTableCell>
                  <StyledTableCell align="left">Category Name</StyledTableCell>
                  <StyledTableCell align="center">Category Image</StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {users.map((row) => (
                  <TableRow>
                    <TableCell align="left">{a++}</TableCell>
                    <TableCell align="left">{row.categoryName}</TableCell>
                    <TableCell align="center" scope="row">
                      <img
                        src={
                          Server.serverforOther.link +
                          "/categorys/" +
                          row.categoryImage
                        }
                        style={{ width: "55px" }}
                      />
                    </TableCell>
                    <TableCell align="center">
                      <ButtonGroup
                        variant="contained"
                        aria-label="outlined primary button group"
                      >
                        <Tooltip title={`Edit Category ${row.categoryName}`}>
                          <Button
                            className="userview"
                            onClick={() => {
                              getOneAminitie(row._id);
                            }}
                          >
                            <EditRoundedIcon />
                          </Button>
                        </Tooltip>
                        <Tooltip title={`Delete Category ${row.categoryName}`}>
                        <Button
                          className="adminblockicon"
                          onClick={() => {
                            deleteAmini(row._id);
                          }}
                        >
                          <DeleteRoundedIcon />
                        </Button>
                        </Tooltip>
                      </ButtonGroup>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={
              totalDoc < 5
                ? [totalDoc, 5, 10, 25, 100]
                : totalDoc === 5
                ? [5, 10, 25, 100]
                : totalDoc < 10 && totalDoc > 5
                ? [5, totalDoc, 10, 25, 100]
                : totalDoc === 10
                ? [5, 10, 25, 100]
                : totalDoc < 25 && totalDoc > 10
                ? [5, 10, totalDoc, 25, 100]
                : totalDoc === 25
                ? [5, 10, 25, 100]
                : totalDoc < 100 && totalDoc > 25
                ? [5, 10, 25, totalDoc, 100]
                : totalDoc === 100
                ? [5, 10, 25, 100]
                : [5, 10, 25, 100, totalDoc]
            }
            component="div"
            count={totalDoc}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}

      <Modal show={show}>
        <Modal.Body>Would you like to delete this Category ?</Modal.Body>
        <Modal.Footer>
          {amId.map((data) => (
            <>
              <Tooltip title="Delete category">
              <Button
                className="editButton"
                onClick={() => {
                  deletingBackend(data.d);
                }}
                variant="contained"
              >
                {/* {" "} */}
                <DeleteRoundedIcon />
              </Button>
              </Tooltip>
              <Tooltip title="Cancel">
              <Button
                className="editButton"
                onClick={() => {
                  setShow(false);
                }}
                variant="contained"
              >
                <CloseIcon />
              </Button>
              </Tooltip>
            </>
          ))}
        </Modal.Footer>
      </Modal>

      <Modal show={add}>
        <Modal.Header>
          <Modal.Title>Create New Category</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Category</Form.Label>
              <Form.Control
                type="text"
                value={enteredAminities}
                onChange={roleChangeHandler}
                placeholder="SPORTS"
                required
              />
              <Form.Control
                type="file"
                name="categoryImage"
                onChange={categoryImages}
                placeholder="Image"
                required
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
         <Tooltip title="Add new category">
         <Button
            className="editButton"
            onClick={addAmenitie}
            variant="contained"
          >
            <DoneIcon />
          </Button>
         </Tooltip>
          <br />
          <Tooltip title="Cancel">
          <Button
            className="editButton"
            onClick={() => {
              setAdd(false);
            }}
            variant="contained"
          >
            <CloseIcon />
          </Button>
          </Tooltip>
         
        </Modal.Footer>
      </Modal>

      {/* <Modal show={edit} >
        <Modal.Header>
          <Modal.Title>Edit amenities</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
          {editAm.map(data=>(
            <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Aminities</Form.Label>
              <Form.Control type="text" defaultValue={data.amenities} onChange={roleChangeHandler}  required/>
              <Button className='catBt1 p-inherit' onClick={()=>editAmenitie(data._id)}   variant="contained"><AddIcon/></Button>
              <Button className='catBt1 p-inherit' onClick={removeData}   variant="contained"><CloseIcon/></Button>
            </Form.Group>
            </Form>
          ))}
        
            </Modal.Body>
        <Modal.Footer>
          
        </Modal.Footer>
      </Modal> */}

      {editAm.map((data) => (
        <Modal show={edit}>
          <Modal.Header>
            <Modal.Title>Edit Categorys</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Row>
                <Form.Label>Category</Form.Label>
                <Col>
                <Form.Control
                  type="text"
                  defaultValue={data.categoryName}
                  onChange={editCatname}
                  required
                  className="editCatnameclass"
                />
                </Col>
                </Row>
                <Row style={{paddingTop:"7px" }}>
                <Col>
                <Form.Control
                  type="file"
                  name="images"
                  onChange={EditCatImage}
                  placeholder="Image"
                  required
                />
                </Col>
               
                </Row>
                
                {/* <Form.Control type="file" onChange={EditCatImage} required /> */}
                <img
                  src={
                    Server.serverforOther.link +
                    "/categorys/" +
                    data.categoryImage
                  }
                  style={{ width: "70px" ,"marginTop":"10px"}}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Tooltip title={`Update ${data.categoryName}`}>
            <Button
              className="editButton"
              onClick={editAmenitie}
              variant="contained"
              style={{paddingRight:"7px !important"}}
            >
              <DoneIcon />
            </Button>
            </Tooltip>
            <Tooltip title="Cancel">
            <Button
              className="editButton"
              onClick={removeData}
              variant="contained"
            >
              <CloseIcon />
            </Button>
            </Tooltip>
            
          </Modal.Footer>
        </Modal>
      ))}
    </>
  );
}

export default Category;
