import React from "react";
import { Link, Outlet ,Navigate} from "react-router-dom";

function ProtectedRoute() {
  var isAuthenticated = localStorage.getItem("token");
  return (
    isAuthenticated ? <Outlet/>:<Navigate to="/"/>
  );
}

export default ProtectedRoute;