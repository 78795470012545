import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import 'semantic-ui-css/semantic.min.css'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(

    <>
    <React.StrictMode>
        <BrowserRouter>
        <App />
        </BrowserRouter>
  
        </React.StrictMode> 
    </>
);
