import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Button, Card, Progress, Icon } from "semantic-ui-react";
import Chart from "react-apexcharts";
import { Tooltip } from "@mui/material";
import moment from "moment";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import { useNavigate } from "react-router-dom";

function ReportedUsers(props) {
  const history = useNavigate();
  // var now = moment().valueOf()
  // var lastMonth=moment(props.body.lastMonthDate).format('MMMM')
  // var thisMonth =moment(now).format('MMMM')
  // if(props.body.lastMonthDate){
  // setLastMonth(moment(props.body.lastMonthDate).format('MMMM'))
  // }
  // const moment=Moment;
  // const [users,setUsers] = useState([])
  // setUsers(props)
  //   console.log(moment(props.body.lastMonthDate).format('MMMM'),"hello...")
  //   console.log(props,"this is the props")
  const userReportManagement = () => {
    history("/home/reportTable");
  };
  console.log(props, "bgggg");
  return (
    <>
      <Card className="user_count_card" onClick={userReportManagement}>
        <Card.Content className="reported_card">
          <Row>
            <Col sm></Col>
            <Col></Col>
            <Col sm>
              <Icon
                className="reported_usersIcon"
                style={{ marginLeft: "20px" }}
                name="user x"
              />
            </Col>
          </Row>

          <Card.Header style={{ color: "#ffff" }}>Reported Users</Card.Header>
          <Card.Meta
            style={{
              color: "#ffff",
              width: "auto",
              maxWidth: "245px",
              marginLeft: "13px",
            }}
          >
            Reported users percentage According to total users
          </Card.Meta>
          <Card.Description>
            <Row>
              <Col md={9}>
                <Progress
                  percent={props.body.reportedPercentage.toFixed(0)}
                  indicating
                />
              </Col>
              <Col md={3}>
                <h3
                  style={{
                    fontSize: "16px",
                    fontFamily: "fantasy",
                    fontWeight: "300",
                    color: "#ffff",
                  }}
                >
                  {props.body.reportedPercentage} %
                </h3>
              </Col>
            </Row>

            <Row>
              <Col md={8}>
                <span className="reportedCount">user count</span>
              </Col>
              <Col md={4}>
                <span className="reportedCount">
                  {props.body.reportedUsers}
                </span>{" "}
              </Col>
              <Col md={8}>
                <span className="usersCounts">Blocked users</span>
              </Col>
              {/* {props.body.map((data)=>( */}
              <Col md={4}>
                <span className="usersCounts">
                  {props.body.adminBlockedUsers}
                </span>{" "}
              </Col>
              {/* <Col md={8}>
                  <span className="usersCounts">test</span>
                </Col>

                  <Col md={4}>
                  <span className="usersCounts">12</span>{" "}
                </Col>

                <Col md={8}>
                  <span className="usersCounts">test</span>
                </Col>

                  <Col md={4}>
                  <span className="usersCounts">test</span>{" "}
                </Col> */}
            </Row>
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          {/* <div className="ui two buttons"> */}
          <button
            class="button-87"
            role="button"
            onClick={userReportManagement}
          >
            Report Table
          </button>
          {/* </div> */}
        </Card.Content>
      </Card>
    </>
  );
}

export default ReportedUsers;
