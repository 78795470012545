import React, { useState , useEffect} from 'react'
import axios from 'axios';
import Server from '../server/Server';
import ReactLoading from 'react-loading';

function Disclaimer() {
    var [data,setData]=useState([])
    useEffect(()=>{
        const fetchDatas=async ()=>{
            // setLoading(true);
          //   console.log(currentPage,"this is current page")
            const res=await axios.get(Server.server.link+'getDisclaimer')
        
            console.log(res.data.code,"Coming data")
          if(res.data.code===203){
            localStorage.clear()
            // history('/')
            // toast.error(res.data.message)
          }else{
            console.log(res.data.results.disclaimer,"data__is_fine")
            let a= "<p></p>"
            if(res.data.results.disclaimer===""){
              setData(a)
            //   setPrivacyId(res.data.results._id)
            }else{
              setData(res.data.results.disclaimer)
            //   setPrivacyId(res.data.data._id)
            }
            
          }
          
          }
          fetchDatas()
    },[])
    console.log(data.length,"ffffff")
  return (
    <>
    {   data.length>0  ?    <div className='privacyApp' dangerouslySetInnerHTML={{__html: data}}></div>  :
       <div className="hey">
       <ReactLoading
         type={"spokes"}
         color={"rgb(9 167 167)"}
        //  height={50}
        //  width={50}
       />
     </div>
      }
      
    </>
  )
}

export default Disclaimer