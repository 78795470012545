import { React, useState, useEffect, Profiler } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Login from "./components/admin/Login";
import 'bootstrap/dist/css/bootstrap.min.css';
import Home from "./components/dashboard/Home";
import ProtectedRoute from "./auth/auth";
import Dashboard from "./components/dashboard/Dashboard";
import Category from "./components/categorys/Category";
import Userlist from "./components/users/Userlist";
import ReactLoading from "react-loading";
import UserProfile from "./components/users/UserProfile";
import ReportList from "./components/users/ReportList";
import ReportDetails from "./components/users/ReportDetails";
import NewsChannels from "./components/categorys/NewsChannels";
import Userpost from "./components/users/Userspost";
import Editdisclaimer from "./components/policyandmore/Editdisclaimer";
import Editprivacy from "./components/policyandmore/Editprivacy";
import EditTerms from "./components/policyandmore/Editterms";
import Faq from "./components/policyandmore/Faq";
import Ethencity from "./components/ethencity/Ethencity";
import ChangePassword from "./components/admin/Changepassword";
import Adminquestion from "./components/categorys/Adminquestion";
import Question from "./components/categorys/Question";
import Forgot from "./components/admin/ForgotPassword";
import EditUser from "./components/users/EditUser";
import "react-phone-input-2/lib/style.css";
import Webview from "./components/webview/Webview";
import Adminprofile from "./components/admin/Profile";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import View from "./components/webview/View";
import PrivacyPolicy from "./components/webview/PrivacyPolicy";
import Disclaimer from "./components/webview/Disclaimer";
import Terms from "./components/webview/Terms";
import NewsChannelNew from "./components/categorys/NewsChannelNew";
import Banners from "./components/categorys/Banners";
import Advertisement from "./components/categorys/Advertisement";
import Wenview1 from "./components/webview/Wenview1";
import PostTable from "./components/users/PostTable";
import PostReport from "./components/users/PostReport";
import PollTable from "./components/users/PollTable";
import QuestionTable from "./components/users/QuestionTable";
import FaqView from "./components/policyandmore/FaqView";
// import Dashboardcopy from "./components/dashboard/Dashboardcopy";

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/forgot" element={<Forgot />} />
        <Route path="/postview/:id" element={<Webview />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy/>} />
        <Route path="/Disclaimer" element={<Disclaimer/>} />
        <Route path="/FAQ" element={<FaqView/>}/>
        <Route path="/ChannelData" element={<Wenview1/>} />
        <Route path="/Terms_And_Conditions" element={<Terms/>} />
        <Route element={<ProtectedRoute />}>
          <Route path="/home" element={<Home />}>
            <Route path="Dashboard" element={<Dashboard />} />
            <Route path="categorys" element={<Category />} />
            <Route path="userlist" element={<Userlist />} />
            <Route path="user/:id" element={<UserProfile />} />
            <Route path="reportTable" element={<ReportList />} />
            <Route path="channels" element={<NewsChannels />} />
            <Route path="posts" element={<Userpost />} />
            <Route path="editdisclaimer" element={<Editdisclaimer />} />
            <Route path="editprivacy" element={<Editprivacy />} />
            <Route path="editterms" element={<EditTerms />} />
            <Route path="faq" element={<Faq />} />
            <Route path="ethnicity" element={<Ethencity />} />
            <Route path="changePassword" element={<ChangePassword />} />
            {/* <Route path="adminquestions" element={<Question />} /> */}
            <Route path="adminProfile" element={<Adminprofile />} />
            <Route path="reportedDetails/:id" element={<ReportDetails />} />
            <Route path="EditUser/:id" element={<EditUser />} />
            <Route path="Banner" element={<Banners />} />
            <Route path="Posttable" element={<PostTable />} />
            <Route path="Polltable" element={<PollTable />} />
            <Route path="Questiontable" element={<QuestionTable />} />
            <Route path="Advertisement" element={<Advertisement />} />
            <Route path="Reports/:id" element={<PostReport />}/>
          </Route>
        </Route>
      </Routes>
      <ToastContainer
        className="toast-position"
        position="top-center"
        autoClose={4000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
    </>
  );
}

export default App;
