import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import Server from "../server/Server";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import ReactLoading from "react-loading";
import userDefult from '../../images/user.ico'
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

function UserProfile() {
  const history = useNavigate();
  const token = localStorage.getItem("token");
  const { id } = useParams();
  const [user, setUser] = useState([]);
  console.log(user,"ggggggggg")
  useEffect(() => {
    const fetchDatas = async () => {
      var data = await axios
        .get(Server.server.link + "getOneUser", {
          headers: { "x-token": token, userId: id },
        })
        .then((res) => {
          console.log(res.data.user, "hey nee thaane");
          let arr = [];
          arr.push(res.data.user);
          setUser(arr);
          console.log(user, "data vandhitteee");
          //   setShow(true)
        });
    };
    fetchDatas();
  }, []);

  const redirect = () => {
    history(`/home/userlist`);
  };
  return (
    <>
      {!user ? (
        <div className="hey">
          {" "}
          <ReactLoading
            type={"spokes"}
            color={"rgb(9 167 167)"}
            height={50}
            width={50}
          />
        </div>
      ) : (
        user.map((data) => (
          <div className="row">
            <div className="col-xl-4 col-md-12">
              {data.profilePhoto==="" ? (
                <Card>
                <CardMedia
                  component="img"
                  image={userDefult}
                />
              </Card>
              ) : (
                
                 <Card>
                 <CardMedia
                   component="img"
                   image={Server.serverforOther.link +'profilepics/'+  data.profilePhoto}
                 />
               </Card>
              )}
            </div>
            <div className="col-xl-8 col-md-12">
              <Box sx={{ flexGrow: 1 }}>
                <Grid container spacing={2}>
                  <Grid item xs={11}>
                  <label>Name</label>
                    <Item>{data.fullName}</Item>
                  </Grid>
                  <Grid item xs={11}>
                  <label>Email</label>
                    <Item>{data.email}</Item>
                  </Grid>
                  <Grid item xs={11}>
                  <label>Mobile</label>
                    <Item>
                      {data.countryCode} {data.mobileNumber}{" "}
                    </Item>
                  </Grid>
                  <Grid item xs={11}>
                  <label>About</label>
                    <Item>{data.bio} </Item>
                  </Grid>
                  <Grid item xs={11}>
                  <label>Gender</label>
                    <Item>{data.gender} </Item>
                  </Grid>
                  <Grid item xs={11}>
                  <label>Age</label>
                    <Item>{data.age} </Item>
                  </Grid>
                  {/* <Grid item xs={11}>
                    <Item>
                      Account status :{" "}
                      {data.private === true ? "Private" : "public"}{" "}
                    </Item>
                  </Grid> */}
                </Grid>
              </Box>
              <div
                className="asking text-right"
                style={{ marginTop: "10px !important", marginRight: "auto" }}
              ></div>
              <Button className="redirectUser" style={{ marginTop: "10px !important", marginRight: "auto" }} variant="outlined"  onClick={() => redirect()}>
                User module
                <KeyboardReturnIcon />
              </Button>
            </div>
          </div>
        ))
      )}
    </>
  );
}

export default UserProfile;
