import { React, useState, useEffect, useRef } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useNavigate, NavLink } from "react-router-dom";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Server from "../server/Server";
import LoginIcon from "@mui/icons-material/Login";
import ChangeCircleSharpIcon from "@mui/icons-material/ChangeCircleSharp";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Form from "react-bootstrap/Form";
import ReactLoading from "react-loading";
// import Home from "../dashboard/Home";

function Adminprofile() {
  const history=useNavigate()
  const [data, setData] = useState([]);
  const [name,setName] = useState("")
  const [email,setEmail] = useState("")
  var token = localStorage.getItem("token");
  useEffect(() => {
    const fetchDatas = async () => {
      const res = await axios.get(Server.server.link + "adminDetails", {
        headers: { "x-token": token },
      }).then(async(res)=>{
        if(res.data.code===203){
          localStorage.clear();
          history("/");
          toast.error(res.data.message);
        }else {
          // console.log(res.data,"ffffff")
          setData(res.data.results);
        }
      })
    };
    fetchDatas();
  },[]);

  const nameChange=(e)=>{
    if (e.target.value.trim().length === 0) {
      setName(e.target.value.trim());
    } else {
      setName(e.target.value);
    }
  }

  const emailChange=(e)=>{
    if (e.target.value.trim().length === 0) {
      setEmail(e.target.value.trim());
    } else {
      setEmail(e.target.value);
    }
  }

  const updateAdmin=async()=>{
      try {
        if(name==="" && email ===""){
          toast.error("Please update any field white spaces are not allowed")
        }else{
          await axios.post(Server.server.link + "updateAdmin",{"name":name,"email": email},{headers:{"x-token":token}})
          .then(async(res)=>{
            setName("")
            setEmail("")
            if(res.data.code===203){
              localStorage.clear();
              history("/");
              toast.error(res.data.message);
            }else if(res.data.code===201){
              toast.error(res.data.message)
            }else{
              toast.success(res.data.message)
            }
          })
        }
      } catch (e) {
        toast.error("Something went wrong")
      }
  }

  return (
    <>
    {data.length===0 ? (
       <div className="hey">
       <ReactLoading
         type={"spokes"}
         color={"rgb(9 167 167)"}
         height={50}
         width={50}
       />
     </div>
      ) : 
      <div>
        <Form>
          <Form.Group
            className="mb-3"
            controlId="formBasicEmail"
            style={{ width: "30%", margin: "auto" }}
          > 
          <h3>PROFILE</h3>
            <Form.Label >Name</Form.Label>
            <Form.Control
              type="text"
              // id="inputPassword5"
              aria-describedby="passwordHelpBlock"
              defaultValue={data.name}
              onChange={nameChange}
            />
            <Form.Label style={{ marginTop: "4%" }}>
              Email
            </Form.Label>
            <Form.Control
              // style={{"marginTop": "4%"}}
              type="text"
              // id="inputPassword5"
              aria-describedby="passwordHelpBlock"
              defaultValue={data.email}
              onChange={emailChange}
            />
            <Tooltip title="">
              <Button
                className="changeButton"
                onClick={updateAdmin}
                variant="contained"
                href="#contained-buttons"
              >
                UPDATE
              </Button>
            </Tooltip>
          </Form.Group>
        </Form>
        <br />
      </div>
    }
    </>
  );
}

export default Adminprofile;
