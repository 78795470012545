import React, { useState , useEffect} from 'react'

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Button from '@mui/material/Button';
import Server from '../server/Server';
import { useNavigate } from "react-router-dom";
import {  toast } from 'react-toastify';
import axios from 'axios';
import ReactLoading from 'react-loading';

function EditTerms() {
    let history=useNavigate()
    const [idOFprivacy,setPrivacyId]=useState("")
    const [privacyData,setData]=useState(undefined)
    const [edited,setEdited]=useState("")
    const admId= localStorage.getItem("adminId")
    var token= localStorage.getItem('token')
    useEffect(()=>{
        const fetchDatas=async ()=>{
          // setLoading(true);
        //   console.log(currentPage,"this is current page")
          const res=await axios.get(Server.server.link+'getTermsAndconditionsForEdit',{headers:{"adminId":admId,"x-token":token}})
      
          console.log(res.data.results,"Coming data")
        if(res.data.code===203){
          localStorage.clear()
          history('/')
          toast.error(res.data.message)
        }else{
          console.log(res.data.data.data,"data__is_fine")
          let a= "<p></p>"
          if(res.data.data.data===""){
            setData(a)
            setPrivacyId(res.data.results._id)
          }else{
            setData(res.data.data.data)
            setPrivacyId(res.data.data._id)
          }
          
        }
        
        }
        fetchDatas()
      },[])
    const onchange=( event, editor ) => {
        setEdited(editor.getData())
        console.log(edited,"data")
        }

        console.log(privacyData,"data_is_not_good")

        const submit = async () => {
          var text = edited ;
          if(text.trim().length === 0){
            toast.error("Update Something White spaces are not allowed")
          }else if(edited===""){
            toast.error("Update Something")
          }else{
          const updateddata = await axios.post(
            Server.server.link + "updateTerms",
            { termsId: idOFprivacy, adminId: admId, data: edited },
            { headers: { adminId: admId, "x-token": token } }
          );
          console.log(updateddata, "data got from");
          if (updateddata.data.code === 203) {
            toast.error(updateddata.data.message);
            localStorage.clear();
            history("/");
          } else if (updateddata.data.code === 201) {
            toast.error(updateddata.data.message);
          } else {
            const res=await axios.get(Server.server.link+'getTermsAndconditionsForEdit',{headers:{"adminId":admId,"x-token":token}})
            toast.success(updateddata.data.message);
            console.log(res.data.results,"Coming data")
          if(res.data.code===203){
            localStorage.clear()
            history('/')
            toast.error(res.data.message)
          }else{
            console.log(res.data.data.data,"data__is_fine")
            let a= "<p></p>"
            if(res.data.data.data===""){
              setData(a)
              setPrivacyId(res.data.results._id)
            }else{
              setData(res.data.data.data)
              setPrivacyId(res.data.data._id)
            }
            
          }
          }
        }
        }; 
    
        const reset=async ()=>{
          var tokens= localStorage.getItem('token')
            setData({})
            const res=await axios.get(Server.server.link+'getTermsAndconditionsForEdit',{headers:{"adminId":admId,"x-token":token}})
      
            console.log(res.data.results,"Coming data")
          if(res.data.code===203){
            localStorage.clear()
            history('/')
            toast.error(res.data.message)
          }else{
            console.log(res.data.data.data,"data__is_fine")
            let a= "<p></p>"
            if(res.data.data.data===""){
              setData(a)
              setPrivacyId(res.data.results._id)
            }else{
              setData(res.data.data.data)
              setPrivacyId(res.data.data._id)
            }
            
          }
        }
        

  return (
    <>
 {!privacyData ?   <div className="hey">
          <ReactLoading
            type={"spokes"}
            color={"rgb(9 167 167)"}
            height={50}
            width={50}
          />
        </div>  :
 <div className="editorCk">
 {/* <h2 className='privacyhead'>PRIVACY POLICY</h2> */}
 <CKEditor
     editor={ ClassicEditor }
     data={privacyData}
     onReady={ editor => {
         // You can store the "editor" and use when it is needed.
         console.log( 'Editor is ready to use!', editor );
     } }
     onChange={ onchange }
     onBlur={ ( event, editor ) => {
         console.log( 'Blur.', editor );
     } }
     onFocus={ ( event, editor ) => {
         console.log( 'Focus.', editor ); 
     } }
 />
 <Button onClick={submit}>Update</Button><Button onClick={reset}>Cancel</Button>
</div>
  }

    </>
    
  )
}

export default EditTerms

