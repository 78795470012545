import {
  // Link,
  NavLink,
  // BrowserRouter,
  // Route,
  // Routes,
  // Switch,
  // useNavigate,
} from "react-router-dom";
// import SetMealRoundedIcon from '@mui/icons-material/SetMealRounded';
import PolicyIcon from "@mui/icons-material/Policy";
import AppSettingsAltIcon from "@mui/icons-material/AppSettingsAlt";

import { useState } from "react";
import GavelIcon from "@mui/icons-material/Gavel";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";

export default function Item() {
  const [open, setOpen] = useState(false);
  // const data=()=>{
  //     setOpen(true)
  // }
  return (
    // <div activeClassName="active">
    // <SetMealRoundedIcon className="icon" />
    //   <span>Content Management</span>
    // </div>
    <>
      <div>
        <a
          activeClassName="active"
          className="iems"
          onClick={() => setOpen(!open)}
        >
          <AppSettingsAltIcon className="icon" />
          <span>Content Management</span>
          {open === true ? (
            <i className="bi bi-chevron-compact-down togle"></i>
          ) : (
            <i className="bi bi-chevron-compact-up togle"></i>
          )}
        </a>
        {/* <a activeClassName="active"> */}
        <div className={open == true ? "subbar open" : "subbar"}>
          <li className="hover">
            <NavLink to="/home/editprivacy" activeClassName="active">
              <PolicyIcon className="icon" />
              <span>Privacy Policy</span>
            </NavLink>
          </li>

          <li className="hover">
            <NavLink to="/home/editdisclaimer" activeClassName="active">
              <ReportProblemIcon className="icon" />
              <span>Disclaimer</span>
            </NavLink>
          </li>
          <li className="hover">
            <NavLink to="/home/editterms" activeClassName="active">
              <GavelIcon className="icon" />
              <span>Terms and Conditions</span>
            </NavLink>
          </li>
          <li className="hover">
            <NavLink to="/home/faq" activeClassName="active">
              <QuestionMarkIcon className="icon" />
              <span>FAQ</span>
            </NavLink>
          </li>
        </div>
        {/* </a > */}
      </div>
    </>
  );
}
