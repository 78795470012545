
import React, { useState , useEffect} from 'react'

import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Button from '@mui/material/Button';
import Server from '../server/Server';
import { useNavigate } from "react-router-dom";
import {  toast } from 'react-toastify';
import axios from 'axios';
import ReactLoading from 'react-loading';

function Editdisclaimer() {
    let history=useNavigate()
    const [idOFprivacy,setPrivacyId]=useState("")
    const [privacyData,setData]=useState(undefined)
    const [edited,setEdited]=useState("")
    const admId= localStorage.getItem("adminId")
    var token= localStorage.getItem('token')
    useEffect(()=>{
        const fetchDatas=async ()=>{
          // setLoading(true);
        //   console.log(currentPage,"this is current page")
          const res=await axios.get(Server.server.link+'getDisclaimerEdit',{headers:{"adminId":admId,"x-token":token}})
      
          console.log(res.data.results,"Coming data")
        if(res.data.code===203){
          localStorage.clear()
          history('/')
          toast.error(res.data.message)
        }else{
          console.log(res.data.results.disclaimer,"data__is_fine")
          let a= "<p></p>"
          if(res.data.results.disclaimer===""){
            setData(a)
            setPrivacyId(res.data.results._id)
          }else{
            setData(res.data.results.disclaimer)
            setPrivacyId(res.data.results._id)
          }
          
        }
        
        }
        fetchDatas()
      },[])
    const onchange=( event, editor ) => {
        setEdited(editor.getData())
        console.log(edited,"data")
        }

        console.log(privacyData,"data_is_not_good")

        const submit = async () => {
          console.log(edited,"hhhhhhhhhh")
          var text = edited ;
          if(text.trim().length === 0){
            toast.error("Update Something White spaces are not allowed")
          }else if(edited===""){
            toast.error("Update Something")
          }else{
            const updateddata = await axios.post(
              Server.server.link + "updateDisclaimer",
              { disclaimerId: idOFprivacy, adminId: admId, data: edited },
              { headers: { "x-token": token } }
            );
            console.log(updateddata.data.code, "data got from");
            if (updateddata.data.code === 201) {
              toast.error(updateddata.data.message);
            } else {
              const res=await axios.get(Server.server.link+'getDisclaimerEdit',{headers:{"adminId":admId,"x-token":token}})
              toast.success(updateddata.data.message);
              console.log(res.data.results,"Coming data")
            if(res.data.code===203){
              localStorage.clear()
              history('/')
              toast.error(res.data.message)
            }else{
              console.log(res.data.results.disclaimer,"data__is_fine")
              let a= "<p></p>"
              if(res.data.results.disclaimer===""){
                setData(a)
                setPrivacyId(res.data.results._id)
              }else{
                setData(res.data.results.disclaimer)
                setPrivacyId(res.data.results._id)
              }
            }
          }
         
        }; 
      }
    
        const reset=async ()=>{
          var tokens= localStorage.getItem('token')
            setData({})
            const res=await axios.get(Server.server.link+'getDisclaimerEdit',{headers:{"adminId":admId,"x-token":token}})
      
            console.log(res.data.results,"Coming data")
          if(res.data.code===203){
            localStorage.clear()
            history('/')
            toast.error(res.data.message)
          }else{
            console.log(res.data.results.disclaimer,"data__is_fine")
            let a= "<p></p>"
            if(res.data.results.disclaimer===""){
              setData(a)
              setPrivacyId(res.data.results._id)
            }else{
              setData(res.data.results.disclaimer)
              setPrivacyId(res.data.results._id)
            }
            
          }
        }
        

  return (
    <>
 {!privacyData ?   <div className="hey">
          <ReactLoading
            type={"spokes"}
            color={"rgb(9 167 167)"}
            height={50}
            width={50}
          />
        </div>  :
 <div className="editorCk">
 {/* <h2 className='privacyhead'>PRIVACY POLICY</h2> */}
 <CKEditor
     editor={ ClassicEditor }
     data={privacyData}
     onReady={ editor => {
         // You can store the "editor" and use when it is needed.
         console.log( 'Editor is ready to use!', editor );
     } }
     onChange={ onchange }
     onBlur={ ( event, editor ) => {
         console.log( 'Blur.', editor );
     } }
     onFocus={ ( event, editor ) => {
         console.log( 'Focus.', editor ); 
     } }
 />
 <Button onClick={submit}>Update</Button><Button onClick={reset}>Cancel</Button>
</div>
  }

    </>
    
  )
}

export default Editdisclaimer

// import React, { useState, useEffect } from "react";

// import { CKEditor } from "@ckeditor/ckeditor5-react";
// import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
// import Button from "@mui/material/Button";
// // import Server from '../../server/Server';
// import Server from "../server/Server";
// import { useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
// import ReactLoading from "react-loading";
// import axios from "axios";

// function Editdisclaimer() {
//   let history = useNavigate();
//   const [idOfDiclaimer, setPrivacyId] = useState("");
//   const [privacyData, setData] = useState({});
//   const [edited, setEdited] = useState("");
//   const admId = localStorage.getItem("adminId");
//   var token = localStorage.getItem("token");
//   useEffect(() => {
//     const fetchDatas = async () => {
//       // setLoading(true);
//       //   console.log(currentPage,"this is current page")
//       const res = await axios.get(Server.server.link + "getDisclaimer", {
//         headers: { "x-token": token },
//       });

//       console.log(res.data.results, "Coming data");
//       if (res.data.code === 203) {
//         localStorage.clear();
//         history("/");
//         toast.error(res.data.message);
//       } else {
//         console.log(res.data, "data");
//         setData(res.data.results.disclaimer);
//         setPrivacyId(res.data.results._id);
//       }
//     };
//     fetchDatas();
//   }, []);
//   const onchange = (event, editor) => {
//     setEdited(editor.getData());
//     console.log(editor.getData(), "data on chaange");
//   };

//   // console.log(idOflicence,"data")

  // const submit = async () => {
  //   console.log(edited,"hhhhhhhhhh")
  //   var text = edited ;
  //   if(text.trim().length === 0){
  //     toast.error("Update Something White spaces are not allowed")
  //   }else if(edited===""){
  //     toast.error("Update Something")
  //   }else{
  //     const updateddata = await axios.post(
  //       Server.server.link + "updateDisclaimer",
  //       { disclaimerId: idOfDiclaimer, adminId: admId, data: edited },
  //       { headers: { "x-token": token } }
  //     );
  //     console.log(updateddata.data.code, "data got from");
  //     if (updateddata.data.code === 201) {
  //       toast.error(updateddata.data.message);
  //     } else {
  //       const respo = await axios.get(Server.server.link + "getDisclaimer", {
  //         headers: { adminId: admId },
  //       });
  //       toast.success(updateddata.data.message);
  //       // console.log(respo.data,"res.data.results")
  //       if (respo.data.code === 203) {
  //         localStorage.clear();
  //         history("/");
  //         toast.error(respo.data.message);
  //       } else {
  //         //   console.log(respo.data,"data")
  //         setData(respo.data.results.disclaimer);
  //         setPrivacyId(respo.data.results._id);
  //       }
  //     }
  //   }
   
  // };

//   const reset = async () => {
//     setData({});
//     const respo = await axios.get(Server.server.link + "getDisclaimer", {
//       headers: { adminId: admId },
//     });
//     // toast.success(updateddata.data.message)
//     // console.log(respo.data,"res.data.results")
//     if (respo.data.code === 203) {
//       localStorage.clear();
//       history("/");
//       toast.error(respo.data.message);
//     } else {
//       //   console.log(respo.data,"data")
//       setData(respo.data.results.disclaimer);
//       setPrivacyId(respo.data.results._id);
//     }
//   };
//   console.log(privacyData, "the data is here.....");
//   return (
//     <>
//       {Object.keys(privacyData).length === 0 ? (
//         <div className="hey">
//           <ReactLoading
//             type={"spokes"}
//             color={"rgb(9 167 167)"}
//             height={50}
//             width={50}
//           />
//         </div>
//       ) : (
//         <div className="editorCk">
//           <h2 className="privacyhead">LICENCE EDIT PANEL</h2>
//           <CKEditor
//             editor={ClassicEditor}
//             data={privacyData}
//             onReady={(editor) => {
//               // You can store the "editor" and use when it is needed.
//               console.log("Editor is ready to use!", editor);
//             }}
//             onChange={onchange}
//             onBlur={(event, editor) => {
//               console.log("Blur.", editor);
//             }}
//             onFocus={(event, editor) => {
//               console.log("Focus.", editor);
//             }}
//           />
//           <Button onClick={submit}>Update</Button>
//           <Button onClick={reset}>Cancel</Button>
//         </div>
//       )}
//     </>
//   );
// }

// export default Editdisclaimer;
