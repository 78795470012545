import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Server from "../server/Server";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "react-bootstrap/Modal";
import AddIcon from "@mui/icons-material/Add";
import Form from "react-bootstrap/Form";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import AddToHomeScreenIcon from '@mui/icons-material/AddToHomeScreen';
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import ReactLoading from "react-loading";
import ButtonGroup from "@mui/material/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import IconButton from "@mui/material/IconButton";
import { Tooltip } from "@mui/material";
const label = { inputProps: { "aria-label": "Switch demo" } };

// import sss from '@mui/icons-material/';


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function NewsChannelNew() {
  const [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(5);
  const history = useNavigate();
  const token = localStorage.getItem("token");
  const [users, setUsers] = useState(undefined);
  let [totalDoc, setTotaldoc] = useState("");
  const [editAm, setIdView] = useState([]);
  const [amId, setUids] = useState([]);
  const [toHomeId, setTohomeIds] = useState([]);
  const [edit, setEdit] = useState(false);
  const [searchs, setSearch] = useState("");
  const [show, setShow] = useState(false);
  const [add, setAdd] = useState(false);
  const [enteredAminities, setEntered] = useState("");
  const [enteredLink, setEnteredLink] = useState("");
  const [enteredDescription, setEnteredDescription] = useState("");
  const [categoryImage, setCategoryImage] = useState("");
  const [editCatImage, setEditcatImage] = useState("");
  const [editCategorys, setEditCategorys] = useState([]);
  const [editCatName, setEditCatName] = useState("");
  const [editChannelLink, setEditChannelLink] = useState("");
  const [editChannelDescription, setEditChannelDescription] = useState("");
  const [categorys, setCategorys] = useState([]);
  const [categoryId, setCategoryId] = useState("");
  // const [categoryName,setCategoryName]=useState('');
  useEffect(() => {
    const fetchDatas = async () => {
      console.log(page, "this is current page");
      const res = await axios.get(Server.server.link + "getBanners", {
        params:{
            skip: page,
           limit: rowsPerPage,
          search: searchs,
        },
        headers: {
          "x-token": token,
        },
      });

      if (res.data.code === 203) {
        localStorage.clear();
        history("/");
        toast.error(res.data.message);
      } else {
        // toast.success(res.data.message)
        console.log(res,"the data")
        setUsers(res.data.data);
        setTotaldoc(res.data.totaldocuments);
      }
    };
    fetchDatas();
  }, [page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const editCatname = (e) => {
    var answerdata = e.target.value;
    if (answerdata.trim().length === 0) {
      setEditCatName(answerdata.trim());
    } else {
      setEditCatName(e.target.value);
    }
    // (e.target.value)
  };

  const editchannelLink = (e) => {
    var answerdata = e.target.value;
    if (answerdata.trim().length === 0) {
      setEditChannelLink(answerdata.trim());
    } else {
      setEditChannelLink(e.target.value);
    }
  };

  const editchannelDescriptions = (e) => {
    var answerdata = e.target.value;
    if (answerdata.trim().length === 0) {
      setEditChannelDescription(answerdata.trim());
    } else {
      setEditChannelDescription(e.target.value);
    }
  };

  const EditCatImage = (e) => {
    setEditcatImage(e.target.files[0]);
  };

  const handleChangeRowsPerPage = (event, newPage) => {
    console.log(event.target.value, "dataaa");
    if (event.target.value === "all") {
      // let ah=totalDoc
      toast.success(`Total Amenties : ${totalDoc}`);
      setRowsPerPage(totalDoc);
      setPage(0);
    } else {
      setRowsPerPage(+event.target.value);
      setPage(0);
    }
  };
  let a = 1;
  const h = () => {
    setShow(false);
  };
  const AddAmenitiesPopup = async () => {
    setAdd(true);
  };


  const categoryImages = (e) => {
    setCategoryImage(e.target.files[0]);
  };

  const addAmenitie = async (e) => {
    var description = enteredDescription;
    console.log(description, "THe data os");
    const amenitie = enteredAminities;
    // const links = enteredLink;
    console.log("data is here....", amenitie);
    // var check = amenitie.trim().length;
    var check1 = description.trim().length;
    if (check1 === 0) {
      toast.error(
        "Please Fill the description field white spaces are not allowed"
      );
    }
    else if (categoryImage === "") {
      toast.error("Please add a  banner");
    }  
    else {
      e.preventDefault();

      // let formData = {
      //   channelName:amenitie,
      //   channelLink:links,
      //   categoryId:categoryId,
      //   description:enteredDescription
      // }
        const formData= new FormData();
        formData.append("file", categoryImage);
        formData.append("description", enteredDescription);
    
      console.log(enteredDescription, "hey bit.......");
      axios
        .post(Server.server.link + "createBanner", formData , {
          headers: {
            "x-token": `${token}`,
          },
        })
        .then(async (response) => {
          console.log(response, "////");
          if (response.data.code === 203) {
            localStorage.clear();
            history("/");
            toast.error(response.data.message);
          } else if (response.status === 201) {
            toast.error(response.data.message);
          }
          else if (response.status === 202) {
            toast.error(response.data.message);
          } 
          else if (response.status === 200) {
            setCategoryImage("");
            toast.success(response.data.message);
            setAdd(false);
            setEntered("");
            setEnteredLink("");
            setEnteredDescription("");
            const respo = await axios.get(Server.server.link + "getBanners", {
              params:{
                  skip: page,
                 limit: rowsPerPage,
                search: searchs,
              },
              headers: {
                "x-token": token,
              },
            });
      
            if (respo.data.code === 203) {
              localStorage.clear();
              history("/");
              toast.error(respo.data.message);
            } else {
              // toast.success(res.data.message)
              console.log(respo,"the data")
              setUsers(respo.data.data);
              setTotaldoc(respo.data.totaldocuments);
            }
            //  history('/Dashboard/category')
          }
        })
        .catch((error) => {
          console.log(error);
        });
      //  const data=await axios.post(Server.server.link+'createAminities',{"categoryName":amenitie},{headers:{"x-token":token}})
      // //  console.log(data.data.code,"this is the fucking data")
      // //  .then( async (respo)=>{
      //     if(data.data.code==203){
      //       console.log(data,"fassdf")
      //       localStorage.clear()
      //       history('/login')
      //       toast.error(data.data.message)
      //     }else{
      //       console.log(data.data.code,"dfdfdfdf")
      //       toast.success(data.data.message)
      //       setAdd(false)
      //       setEntered("")
      //       const res=await axios.get(Server.server.link+'getAminities',{headers:{"pageNumber":page,"limit":rowsPerPage,"x-token":token,"search":searchs}})
      //         if(res.data.code===203){
      //           localStorage.clear()
      //           history('/login')
      //           toast.error(res.data.message)
      //         }else{
      //           setTotaldoc(res.data.totalDocuments)
      //           setUsers(res.data.data)
      //           history('/home/amenities')
      //         }
      //     }

      // })
    }
  };

  const updateBanner= async(id)=>{
      const  description= enteredDescription;
      var check= description.trim().length;
      if(check===0 && categoryImage===""){
        toast.error("Please update any fields white spaces are not allowed")
      }else{
        const formData = new FormData;
        formData.append("file",categoryImage)
        formData.append("description",description)
        formData.append("id",id)
        await axios.post(Server.server.link+"updateBanner",formData,{
          headers: {
            "x-token": `${token}`,
          },
        }).then(async(res)=>{
          if (res.data.code === 203){
            toast.error(res.data.message)
            localStorage.clear()
            history("/")
          }else if(res.data.code===201){
            toast.error(res.data.message)
          }else if(res.data.code===200){
            setCategoryImage("")
            setEnteredDescription("")
            toast.success(res.data.message)
            history("/")
            setEdit(false)
            const respo = await axios.get(Server.server.link + "getBanners", {
              params:{
                  skip: page,
                 limit: rowsPerPage,
                search: searchs,
              },
              headers: {
                "x-token": token,
              },
            });
      
            if (respo.data.code === 203) {
              localStorage.clear();
              history("/");
              toast.error(respo.data.message);
            } else {
              // toast.success(res.data.message)
              console.log(respo,"the data")
              setUsers(respo.data.data);
              setTotaldoc(respo.data.totaldocuments);
            }
          }else{
            toast.error("Something went wrong")
          }
        })
      }
  }
//   const editAmenitie = async (e) => {
//     const amenitie = editCatName;
//     //     console.log("data is here....",amenitie)
//     var check = amenitie.trim().length;
//     var check1 = editChannelLink.trim().length;
//     //   if(check==0){
//     //       toast.error("Please Fill the Amenities field white spaces are not allowed")
//     //     }else{
//     e.preventDefault();
//     editCategorys.map((data) => {
//       console.log(
//         editCatName === data.channelName,
//         "..dsofish",
//         editCatName,
//         "ffff",
//         data.channelName,
//         "fata",
//         editChannelLink,
//         "ftas",
//         data.channelLink
//       );
//       // var checka=editChannelLink===data.channelLink

//       const formData = {
//         channelId: data._id,
//         channelName:editCatName,
//         channelLink:editChannelLink,
//         description:editChannelDescription
//       };
//     //   formData.append("channelId", data._id);
//     //   formData.append("file", editCatImage);
//     //   formData.append("channelName", editCatName);
//     //   formData.append("channelLink", editChannelLink);
//     //   formData.append("description", editChannelDescription);
//       console.log(
//         editCatName,
//         "dkjsdhfsdfksdfk",
//         editCatImage,
//         "xhkhkkf",
//         editChannelLink
//       );
//       if (check === 0 && editCatName) {
//         toast.error(
//           "Please Fill the Channel name field white spaces are not allowed"
//         );
//       } else if (check1 === 0 && editChannelLink) {
//         toast.error(
//           "Please Add the Channel Link  white spaces are not allowed"
//         );
//       }
//       // else if(checka===true){
//       //   toast.error("Update any of the field")
//       // }
//       else if (
//         editChannelLink === "" &&
//         editCatName === "" &&
//         editCatImage === "" &&
//         editChannelDescription === ""
//       ) {
//         toast.error("Update any of the field");
//       } else {
//         axios
//           .post(Server.server.link + "updateChannel", formData, {
//             headers: {
//               "x-token": `${token}`,
//             },
//           })
//           .then(async (response) => {
//             console.log(response.data.code, "....");
//             if (response.data.code === 201) {
//               toast.error(response.data.message);
//             } else if (response.data.code === 204) {
//               console.log("we are her...");
//               toast.error(response.data.message);
//               setEdit(true);
//             } else {
//               setEditcatImage("");
//               setEditCatName("");
//               setEditChannelLink("");
//               setEditChannelDescription("");
//               setEditCategorys([]);
//               toast.success(response.data.message);
//               // setEditOpen(false)
//               setEdit(false);
//               const res = await axios.get(
//                 Server.server.link + "getNewsChannel",
//                 {
//                   headers: {
//                     pageNumber: page,
//                     limit: rowsPerPage,
//                     "x-token": token,
//                     search: searchs,
//                   },
//                 }
//               );

//               if (res.data.code === 203) {
//                 localStorage.clear();
//                 history("/");
//                 toast.error(res.data.message);
//               } else {
//                 // setTotal(res.data.totalPages)
//                 // // setPosts([]);
//                 // setPosts(res.data.data);
//                 // if( res.data.data.length===res.data.totalDocuments){
//                 //   setCurrentpage(0)
//                 //   toast.success(`Total Categorys:${res.data.totalDocuments}`)
//                 // }

//                 // if(res.data.data.length===0){
//                 //   setCurrentpage(0)
//                 //   toast.error(`Total Categorys:${res.data.totalDocuments}`)
//                 // }
//                 setTotaldoc(res.data.totalDocuments);
//                 setUsers(res.data.data);
//                 history("/home/channels");
//                 // setStatus(res.data.data[0].active)
//               }
//               //  history.push('/admin/categorys')
//             }
//           });
//       }
//     });
//     // axios.post(Server.server.link+'updateAminities',{"amenities":amenitie ,"id":id},{headers:{"x-token":token}}).then( async (respo)=>{
//     //   // console.log(respo.data,"hey mahbnssss")
//     //   if(respo.data.code==203){
//     //     localStorage.clear()
//     //     history('/login')
//     //     toast.error(respo.data.message)
//     //   }
//     //   else if(respo.data.code ===201){
//     //     toast.error(respo.data.message)
//     //   }else{
//     //     toast.success(respo.data.message)
//     //     setEdit(false)
//     //     setEntered("")
//     //     const res=await axios.get(Server.server.link+'getAminities',{headers:{"pageNumber":page,"limit":rowsPerPage,"x-token":token,"search":searchs}})
//     //       if(res.data.code===203){
//     //         localStorage.clear()
//     //         history.push('/login')
//     //         toast.error(res.data.message)
//     //       }else{
//     //         setTotaldoc(res.data.totalDocuments)
//     //         setUsers(res.data.data)
//     //         history('/home/amenities')
//     //       }
//     //   }

//     // })
//     //   }
//   };

  var deleteAmini = async (id) => {
    console.log("......id id id i don't like it ", id);
    let arr = [];
    arr.push({ d: id });
    setUids(arr);
    setShow(true);
  };

  const deletingBackend = (id) => {
    console.log("id ...", id);
    axios
      .post(
        Server.server.link + "deleteBanner",
        { id: id },
        { headers: { "x-token": token } }
      )
      .then(async (respo) => {
        if (respo.data.code === 202) {
          toast.error(respo.data.message);
          setShow(false);
        } else {
          toast.success(respo.data.message);
          setShow(false);
          const res = await axios.get(Server.server.link + "getBanners", {
            params:{
              skip: page,
             limit: rowsPerPage,
            search: searchs,
            },
            headers: {
              "x-token": token,
            },
            
          });
          if (res.data.code === 203) {
            localStorage.clear();
            history("/");
            toast.error(res.data.message);
          } else {
            setTotaldoc(res.data.totalDocuments);
            setUsers(res.data.data);
            history("/home/channels");
          }
        }
      });
  };

  const searchType = async (e) => {
    setSearch(e.target.value);
    console.log(e.target.value, "this is the searched key");
    let a = e.target.value;
    let setA = page;
    // if(page<=rowsPerPage){
    //   setA=0
    // }
     const res = await axios.get(Server.server.link + "getBanners", {
        params:{
            skip: page,
           limit: rowsPerPage,
          search: a,
        },
        headers: {
          "x-token": token,
        },
      });

      if (res.data.code === 203) {
        localStorage.clear();
        history("/");
        toast.error(res.data.message);
      } else {
        // toast.success(res.data.message)
        console.log(res,"the data")
        setUsers(res.data.data);
        setTotaldoc(res.data.totaldocuments);
      }
  };

  const getOneAminitie = async (id) => {
    // let arr=[]

    // setEdit(true)
    // const data = await axios.get(Server.server.link+'getOneAminitie',{headers:{amId:id,"x-token":token}})
    // arr.push(data.data.data)
    // setIdView(arr)
    var to = localStorage.getItem("token");
    var data = await axios.get(Server.server.link + "getOneBanner", {
      params:{
        bannerId: id
      },
      headers: { "x-token": to },
    });
    if (data.data.code === 203) {
      toast.error(data.data.message);
      history("/");
    } else {
      let arr = [];
      setEdit(true);
      console.log(data.data.data, "data is here");
      arr.push(data.data.data);
      setEditCategorys(arr);
      setIdView(arr);
    }
  };

  const roleChangeHandler = (event) => {
    setEnteredDescription(event.target.value);
    console.log(enteredDescription, "data");
  };

//   const roleChangeHandlerForlink = (event) => {
//     setEnteredLink(event.target.value);
//     console.log(enteredLink, "data");
//   };

  const roleChangeHandlerForDescription = (event) => {
    setEnteredDescription(event.target.value);
    console.log(enteredDescription, "data", event.target.value);
  };

  const removeData = () => {
    setIdView([]);
    setEdit(false);
  };
//   // console.log("this is the data...", users);
//   const datafun = async (id) => {
//     console.log(id, "this is the oid");
//     var questionId = id;
//     var type=1
//     await axios
//       .post(
//         Server.server.link + "addTohome",
//         { questionId ,type},
//         { headers: { "x-token": token } }
//       )
//       .then(async (res) => {
//         if (res.data.code === 203) {
//           toast.error(res.data.message);
//           history("/");
//         } else if (res.data.code === 201) {
//           toast.error(res.data.message);
//         } else {
//           toast.success(res.data.message);
//           const respo = await axios.get(Server.server.link + "getNewsChannel", {
//             headers: {
//               pageNumber: page,
//               limit: rowsPerPage,
//               search: "",
//               "x-token": token,
//             },
//           });

//           if (respo.data.code === 203) {
//             localStorage.clear();
//             history("/");
//             toast.error(respo.data.message);
//           } else {
//             await setTotaldoc(respo.data.totalDocuments);
//             await setUsers(respo.data.data);
//           }
//         }
//       });
//   };

//   const remove = async (id) => {
//     console.log(id, "this is the oid");
//     var questionId = id;
//     var type=0
//     await axios
//       .post(
//         Server.server.link + "addTohome",
//         { questionId ,type},
//         { headers: { "x-token": token } }
//       )
//       .then(async (res) => {
//         if (res.data.code === 203) {
//           toast.error(res.data.message);
//           history("/");
//         } else if (res.data.code === 201) {
//           toast.error(res.data.message);
//         } else {
//           toast.success(res.data.message);
//           const respo = await axios.get(Server.server.link + "getNewsChannel", {
//             headers: {
//               pageNumber: page,
//               limit: rowsPerPage,
//               search: "",
//               "x-token": token,
//             },
//           });

//           if (respo.data.code === 203) {
//             localStorage.clear();
//             history("/");
//             toast.error(respo.data.message);
//           } else {
//             await setTotaldoc(respo.data.totalDocuments);
//             await setUsers(respo.data.data);
//           }
//         }
//       });
//   };
  

//   var toHomecheckProceed = async (id) => {
//     console.log("......id id id i don't like it ", id);
//     let arr = [];
//     arr.push({ d: id });
//     // setUids(arr)
//     // setShow(true)
//   };

//   const selectCategory = async (id) => {
//     setCategoryId(id);
//   };
  return (
    <>
      <Stack direction="row">
        <TextField
          label="Search"
          name="Search"
          value={searchs}
          placeholder="Search for Channel names"
          onChange={searchType}
        />
      </Stack>
      <Stack direction="row">
        <Button className="AddAmenities" onClick={AddAmenitiesPopup}>
          {" "}
          <AddIcon /> Add Banner
        </Button>
      </Stack>
      {!users ? (
        <div className="hey">
          <ReactLoading
            type={"spokes"}
            color={"rgb(9 167 167)"}
            height={50}
            width={50}
          />
        </div>
      ) : (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>S.no</StyledTableCell>
                  <StyledTableCell align="left">Banner</StyledTableCell>
                  <StyledTableCell align="left">Description</StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {users.map((row) => (
                  <TableRow>
                    <TableCell align="left">{a++}</TableCell>
                    <TableCell align="left" scope="row">
                      <img
                        src={
                          Server.serverforOther.link +
                          row.image
                        }
                        style={{ width: "55px" }}
                        alt=""
                      />
                    </TableCell>
                    <TableCell align="left">{row.description}</TableCell>
                    {/* <TableCell align="left">{row.categoryName}</TableCell>
                    <TableCell align="left">
                      <a href={`https://www.youtube.com/watch?v=${row.channelLink}`}>{`https://www.youtube.com/watch?v=${row.channelLink}`}</a>
                    </TableCell> */}
                   

                    
                    {/* <TableCell align="left">{row.description}</TableCell> */}
                    <TableCell align="center">
                    <ButtonGroup
                        variant="contained"
                        aria-label="outlined primary button group"
                      >
                        <Tooltip title ="Edit Banner">
                      <Button
                        className="userview"
                        onClick={() => {
                          getOneAminitie(row._id);
                        }}
                      >
                        <EditRoundedIcon />
                      </Button>
                      </Tooltip>
                      <Tooltip title ="Delete Banner">
                      <Button
                        className="adminblockicon"
                        onClick={() => {
                          deleteAmini(row._id);
                        }}
                      >
                        <DeleteRoundedIcon />
                      </Button>
                      </Tooltip>
                      </ButtonGroup>
                    
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={
              totalDoc < 5
                ? [totalDoc, 5, 10, 25, 100]
                : totalDoc === 5
                ? [5, 10, 25, 100]
                : totalDoc < 10 && totalDoc > 5
                ? [5, totalDoc, 10, 25, 100]
                : totalDoc === 10
                ? [5, 10, 25, 100]
                : totalDoc < 25 && totalDoc > 10
                ? [5, 10, totalDoc, 25, 100]
                : totalDoc === 25
                ? [5, 10, 25, 100]
                : totalDoc < 100 && totalDoc > 25
                ? [5, 10, 25, totalDoc, 100]
                : totalDoc === 100
                ? [5, 10, 25, 100]
                : [5, 10, 25, 100, totalDoc]
            }
            component="div"
            count={totalDoc}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}

      <Modal show={show}>
        <Modal.Body>Would you like to delete this Banner ?</Modal.Body>
        <Modal.Footer>
          {amId.map((data) => (
            <>
              <Button
                className="p-inherit"
                onClick={() => {
                  deletingBackend(data.d);
                }}
                variant="contained"
              >
                {" "}
                <DeleteRoundedIcon />
              </Button>
              <Button
                className="catBt1 p-inherit"
                onClick={() => {
                  setShow(false);
                }}
                variant="contained"
              >
                <CloseIcon />
              </Button>
            </>
          ))}
        </Modal.Footer>
      </Modal>

      <Modal show={add}>
        <Modal.Header>
          <Modal.Title>Create New Banner</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              {/* <Dropdown>
                <Dropdown.Toggle
                  variant="success"
                  id="dropdown-basic"
                  style={{ width: "100%" }}
                >
                  Categorys
                </Dropdown.Toggle> */}

                {/* <Dropdown.Menu style={{ width: "100%" }}>
                  {categorys.map((data) => (
                    <Dropdown.Item onClick={() => selectCategory(data._id)}>
                      {data.categoryName}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown> */}

              {/* <Form.Label></Form.Label> */}
              {/* <Form.Control
                type="text"
                value={enteredAminities}
                // onChange={roleChangeHandler}
                placeholder="&Tv"
                required
              />
              <Form.Label>Channel Link</Form.Label>
              <Form.Control
                type="text"
                value={enteredLink}
                // onChange={roleChangeHandlerForlink}
                placeholder=""
                required
              />
              <h6 style={{marginBottom:"20px"}}>Please use public youtube link avoid age restricted and other restrictions containing link</h6>
              */}
             <Form.Label>Banner</Form.Label>
              <Form.Control
                type="file"
                name="categoryImage"
                onChange={categoryImages}
                placeholder="Image"
                required
              />
               <Form.Label>Description</Form.Label>
              <Form.Control
                type="text"
                onChange={roleChangeHandlerForDescription}
                placeholder=""
                required
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="catBt1 p-inherit"
            onClick={addAmenitie}
            variant="contained"
          >
            <AddIcon />
          </Button>
          <br />
          <Button
            className="catBt1 p-inherit"
            onClick={() => {
              setAdd(false);
            }}
            variant="contained"
          >
            <CloseIcon />
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={edit} >
        <Modal.Header>
          <Modal.Title>Edit amenities</Modal.Title>
        </Modal.Header>
        <Modal.Body> 
          {editAm.map(data=>(
            <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
            <Form.Label>Banner</Form.Label>
              <Form.Control
                type="file"
                name="categoryImage"
                onChange={categoryImages}
                placeholder="Image"
                required
              />
              <Form.Label>description</Form.Label>
              <Form.Control type="text" defaultValue={data.description} onChange={roleChangeHandler}  required/>
              <Button className='catBt1 p-inherit' onClick={()=>updateBanner(data._id)}   variant="contained"><AddIcon/></Button>
            
                 <Button className='catBt1 p-inherit' onClick={removeData}   variant="contained"><CloseIcon/></Button>
            </Form.Group>
         
            </Form>
            
          ))}
        
            </Modal.Body>
        <Modal.Footer>
          
        </Modal.Footer>
      </Modal>

      {/* {editAm.map((data) => (
        <Modal show={edit}>
          <Modal.Header>
            <Modal.Title>Edit Channel</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Channel Name</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={data.channelName}
                  onChange={editCatname}
                  required
                />
                <Form.Label>Channel Link as Id</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={`https://www.youtube.com/watch?v=${data.channelLink}`}
                  onChange={editchannelLink}
                  required
                />
                <Form.Label>Channel Description</Form.Label>
                <Form.Control
                  type="text"
                  defaultValue={data.description}
                  onChange={editchannelDescriptions}
                  required
                />
                {/* <Form.Label>Channel Banner</Form.Label> */}
                {/* <Form.Control type="file" onChange={EditCatImage} required />
                <img
                  src={
                    Server.serverforOther.link + data.channelBanner
                  }
                  style={{ width: "200px" }}
                /> */}
              {/* </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="catBt1 p-inherit"
              onClick={editAmenitie}
              variant="contained"
            >
              <AddIcon />
            </Button>
            <Button
              className="catBt1 p-inherit"
              onClick={removeData}
              variant="contained"
            >
              <CloseIcon />
            </Button>
          </Modal.Footer>
        </Modal>
      ))} 

      {/*           
     <Modal show={show} >
        <Modal.Body>Would you like to Remove this Channel from home banner ?</Modal.Body>
        <Modal.Footer>
        {amId.map(data=>(
        <>
        <Button className='p-inherit' onClick={() => { deletingBackend(data.d); } } variant="contained"> <DeleteRoundedIcon /></Button>
        <Button className='catBt1 p-inherit' onClick={()=>{setShow(false)}}   variant="contained"><CloseIcon/></Button>
        </>
        ))}
          
        </Modal.Footer>
      </Modal> */}
    </>
  );
}

export default NewsChannelNew;
