import React, { useEffect, useState, useRef } from "react";
import { useLocation } from "react-router-dom";
import { useTheme } from "@mui/material/styles";
import { Box, ButtonGroup } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Server from "../server/Server";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import ReactLoading from "react-loading";
import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp';
import { Tooltip } from "@mui/material";
import { Modal } from "react-bootstrap";
import CloseIcon from "@mui/icons-material/Close";
import SwipeableViews from "react-swipeable-views";
import MobileStepper from "@mui/material/MobileStepper";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import DoNotTouchSharpIcon from '@mui/icons-material/DoNotTouchSharp';
import ImageIcon from "@mui/icons-material/Image";
import DoneIcon from "@mui/icons-material/Done";
import HowToVoteIcon from "@mui/icons-material/HowToVote";
import IconButton from "@mui/material/IconButton";
import ListIcon from '@mui/icons-material/List';
import ClearSharpIcon from '@mui/icons-material/ClearSharp';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function QuestionTable() {
  const theme = useTheme();
  const location = useLocation();
  const history = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const [searchs, setSearch] = useState(location.state || "");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [totalDocs, setTotaldoc] = useState(0);
  const[f_total,setF_Total]= useState(totalDocs)
  const [showMedia, setShowmedia] = useState(false);
  const [showPoll, setShowpoll] = useState(false);
  const [media, setMedia] = useState([]);
  const [status, setStatus] = useState("");
  const [disableModal, setDisableModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [diableDeleteId, setDisbaleIdDeleteId] = useState("");
  const [votes,setVotes]=useState({})

  console.log(media, "hey media");

  const maxSteps = media.length;

  const token = localStorage.getItem("token");
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    fetchDatas();
  }, [page, rowsPerPage]);
  const fetchDatas = async () => {
    const res = await axios.get(Server.server.link + "questionTable", {
      params: {
        skip: page,
        limit: rowsPerPage,
        search: searchs,
      },
      headers: {
        "x-token": token,
      },
    });
    if (res.data.code === 203) {
      localStorage.clear();
      history("/");
      toast.error(res.data.message);
    }else if(res.data.code === 201){
      setQuestions([]);
      // toast.error("No data found")
    } else {
            setQuestions(res.data.data);
            setTotaldoc(res.data.totalDoc);
            setF_Total(res.data.totalDoc)
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event, newPage) => {
    if (event.target.value === "all") {
      toast.success(`Total Amenties : ${totalDocs}`);
      setRowsPerPage(totalDocs);
      setPage(0);
    } else {
      setRowsPerPage(+event.target.value);
      setPage(0);
    }
  };

  const handleStepChange = (step) => {
    setActiveStep(step);
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };
  const mediaView = (id) => {
    questions.map((row) => {
        console.log(row._id === id,"hey hey hyue")
      if (row._id === id) {
        setShowmedia(true);
        setMedia(row.media);
        console.log(row, "hey....");
      }
    });
  };

  const disableId = (id, status) => {
    setDisableModal(true);
    setDisbaleIdDeleteId(id);
    setStatus(status);
  };

  const deleteId = (id) => {
    setDeleteModal(true);
    setDisbaleIdDeleteId(id);
  };

  const submitStatusChange = async () => {
    await axios
      .post(
        Server.server.link + "disbalePost",
        {},
        {
          params: {
            postId: diableDeleteId,
          },
          headers: {
            "x-token": token,
          },
        }
      )
      .then((res) => {
        if (res.data.code === 201) {
          toast.error(res.data.message);
        } else if (res.data.code === 203) {
          history("/");
          toast.error(res.data.message);
          localStorage.clear();
        } else if (res.data.code === 200) {
          setDisableModal(false);
          setDisbaleIdDeleteId("");
          toast.success(res.data.message);
          fetchDatas();
        }
      });
  };

  const deletePost = async () => {
    await axios
      .post(
        Server.server.link + "deletePost",
        {},
        {
          params: {
            postId: diableDeleteId,
          },
          headers: {
            "x-token": token,
          },
        }
      )
      .then((res) => {
        if (res.data.code === 201) {
          toast.error(res.data.message);
        } else if (res.data.code === 203) {
          history("/");
          toast.error(res.data.message);
          localStorage.clear();
        } else if (res.data.code === 200) {
          setDeleteModal(false);
          setDisbaleIdDeleteId("");
          toast.success(res.data.message);
          fetchDatas();
        }
      });
  };
  const toPostReport = (id) => {
    history(`/home/Reports/${id}`);
  };

  const searType = async (e) => {
    setSearch(e.target.value);
    console.log(e.target.value, "this is the searched key");
    let a = e.target.value;
    // let setA =page
    // if(page<=rowsPerPage){
    //   setA=0
    // }
    const res = await axios.get(Server.server.link + "questionTable", {
      params: {
        skip: '0',
        limit: f_total,
        search: a,
      },
      headers: {
        "x-token": token,
      },
    });

    if (res.data.code === 203) {
      localStorage.clear();
      history("/");
      toast.error(res.data.message);
    } else {
        setQuestions(res.data.data);
        setTotaldoc(res.data.totalDoc);
    }
  };

  
  const  checkVotes = (id)=>{
    {questions.map((row) => {
            if(row.questionId===id){
                setVotes(row.choices)
                setShowpoll(true)
            }
        })
    }
    }

    console.log(votes,"hey votes")
  let a = 1;
  return (
    <>
      <Stack direction="row">
        <TextField
          label="Search"
          name="Search"
          value={searchs}
          placeholder="Search for user name"
          onChange={searType}
        />
      </Stack>
      {!questions ? (
        <div className="hey">
          <ReactLoading
            type={"spokes"}
            color={"rgb(9 167 167)"}
            height={50}
            width={50}
          />
        </div>
      ) : (
        <Paper sx={{ width: "100%", overflow: "hidden" }} >
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>S.no</StyledTableCell>
                  <StyledTableCell align="left">User Name</StyledTableCell>
                  <StyledTableCell align="left">Question</StyledTableCell>
                  <StyledTableCell align="left">View Options</StyledTableCell>
                  {/* <StyledTableCell align="left">Biaz count</StyledTableCell>
                  <StyledTableCell align="left">Unbiazed count</StyledTableCell>
                  <StyledTableCell align="left">Neutral</StyledTableCell>
                  <StyledTableCell align="left">Likes</StyledTableCell>
                  <StyledTableCell align="left">Comments</StyledTableCell> */}
                  <StyledTableCell align="center">Media</StyledTableCell>
                  {/* <StyledTableCell align="left">Report count</StyledTableCell> */}
                  {/* <StyledTableCell align="left">Disable Status</StyledTableCell> */}
                  <StyledTableCell align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {questions.map((row) => (
                  <TableRow>
                    <TableCell align="left">{a++}</TableCell>
                    <TableCell align="left">{row.fullName}</TableCell>
                    <TableCell align="left">{row.question}</TableCell>
                    <TableCell align="center" className="postView" onClick={()=>checkVotes(row.questionId)}>
                      <Tooltip title="Click to check the  correct answer and the choice">
                        <ListIcon style={{ color: "green" ,marginRight:"20px"}} />
                      </Tooltip>
                    </TableCell>
                    <TableCell align="center">
                      {row.postWithStatus === true ? (
                        <Tooltip title="View Images and videos">
                          <Button
                            className="userview"
                            id="userdetails"
                            onClick={() => mediaView(row._id)}
                          >
                            <VisibilitySharpIcon />
                          </Button>
                        </Tooltip>
                      ) : (
                        <Tooltip title="Poll has no media">
                          <IconButton
                            className="userview"
                            id="userdetails"
                            disabled={true}
                          >
                            <VisibilitySharpIcon />
                          </IconButton>
                        </Tooltip>
                      )}
                    </TableCell>
                    {/* {row.reports > 0 ? (
                      <Tooltip title="Redirect to report details">
                        <TableCell
                          align="left"
                          onClick={() => toPostReport(row.postId)}
                          className="postView"
                        >
                          {row.reports}
                        </TableCell>
                      </Tooltip>
                    ) : (
                      <TableCell align="left">{row.reports}</TableCell>
                    )} */}

                    <TableCell align="center">
                      <ButtonGroup
                        variant="contained"
                        aria-label="outlined primary button group"
                      >
                        {row.expireStatus === "0" ? (
                          <Tooltip title="Disable the post">
                            <Button
                              className="userview"
                              onClick={() =>
                                disableId(row.postId, row.expireStatus)
                              }
                            >
                              {" "}
                              <DoNotTouchSharpIcon />
                            </Button>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Enable the post">
                            <Button
                              className="userview"
                              onClick={() =>
                                disableId(row.postId, row.expireStatus)
                              }
                            >
                              {" "}
                              <ImageNotSupportedIcon />
                            </Button>
                          </Tooltip>
                        )}

                        <Tooltip title="Delete the post">
                          <Button
                            className="adminblockicon"
                            onClick={() => deleteId(row.postId)}
                          >
                            <DeleteRoundedIcon />
                          </Button>
                        </Tooltip>
                      </ButtonGroup>
                    </TableCell>
                    {/* 
                    <TableCell align="left">{row.opinions.biazCount}</TableCell>
                    <TableCell align="left">
                      {row.opinions.unbiazedCount}
                    </TableCell>
                    <TableCell align="left">{row.opinions.neutral}</TableCell>
                    <TableCell align="left">{row.likes}</TableCell>
                    <TableCell align="left">{row.comments}</TableCell>
                    <TableCell align="center">
                      <Tooltip title="View Images and videos">
                        <Button
                          className="userview"
                          id="userdetails"
                          onClick={() => mediaView(row._id)}
                        >
                          <VisibilitySharpIcon />
                        </Button>
                      </Tooltip>
                    </TableCell>
                    {row.reports > 0 ? (
                      <Tooltip title="Redirect to report details">
                      <TableCell align="left" onClick={()=>toPostReport(row._id)} className="postView">
                        {row.reports}
                      </TableCell>
                      </Tooltip>
                    ) : (
                      <TableCell align="left">
                        {row.reports}
                      </TableCell>
                    )} */}

                    {/* <TableCell align="left">true</TableCell> */}

                    {/* <TableCell align="center" scope="row">
                      <img src={Server.serverforOther.link+"/categorys/"+row.categoryImage} style={{width:"55px"}}/>
                    </TableCell>
                    <TableCell align="center">
                    <Button  className = "userview" onClick={()=>{getOneAminitie(row._id)}} > < EditRoundedIcon  /></Button>
                    <Button className = "adminblockicon"  onClick={()=>{deleteAmini(row._id)}}><DeleteRoundedIcon /></Button>
               
                    </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={
              totalDocs < 5
                ? [totalDocs, 5, 10, 25, 100]
                : totalDocs === 5
                ? [5, 10, 25, 100]
                : totalDocs < 10 && totalDocs > 5
                ? [5, totalDocs, 10, 25, 100]
                : totalDocs === 10
                ? [5, 10, 25, 100]
                : totalDocs < 25 && totalDocs > 10
                ? [5, 10, totalDocs, 25, 100]
                : totalDocs === 25
                ? [5, 10, 25, 100]
                : totalDocs < 100 && totalDocs > 25
                ? [5, 10, 25, totalDocs, 100]
                : totalDocs === 100
                ? [5, 10, 25, 100]
                : [5, 10, 25, 100, totalDocs]
            }
            component="div"
            count={totalDocs}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}

      <Modal show={showMedia}>
        <Modal.Body>
          <SwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={handleStepChange}
            enableMouseEvents
          >
            {media.map((row, index) =>
              row.fileName.split(".").pop() === "mp4" ||
              row.fileName.split(".").pop() === "mov" ||
              row.fileName.split(".").pop() === "mog" ? (
                <Box
                  sx={{
                    height: 300,
                    display: "block",
                    maxWidth: 700,
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <div className="video-section">
                    <video class="img-fluid" autoPlay loop muted>
                      <source src={Server.serverforOther.link + row.fileName} />
                    </video>
                  </div>
                </Box>
              ) : (
                <Box
                  sx={{
                    height: 300,
                    display: "block",
                    maxWidth: 700,
                    overflow: "hidden",
                    width: "100%",
                  }}
                >
                  <img
                    className="d-block w-100"
                    src={Server.serverforOther.link + row.fileName}
                    alt="First slide"
                  />
                </Box>
              )
            )}
          </SwipeableViews>
          <MobileStepper
            steps={maxSteps}
            position="static"
            activeStep={activeStep}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={activeStep === maxSteps - 1}
              >
                Next
                {theme.direction === "rtl" ? (
                  <KeyboardArrowLeft />
                ) : (
                  <KeyboardArrowRight />
                )}
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={activeStep === 0}
              >
                {theme.direction === "rtl" ? (
                  <KeyboardArrowRight />
                ) : (
                  <KeyboardArrowLeft />
                )}
                Back
              </Button>
            }
          />
        </Modal.Body>
        <Modal.Footer>
          <Tooltip title="Cancel">
            <Button
              className="catBt1 p-inherits"
              onClick={() => {
                setShowmedia(false);
              }}
              variant="contained"
            >
              <CloseIcon />
            </Button>
          </Tooltip>
        </Modal.Footer>
      </Modal>

      <Modal show={disableModal}>
        <Modal.Body>
          {status === "1"
            ? "Would you like to enable the post ?"
            : "Would you like to disable the post ?"}
        </Modal.Body>
        <Modal.Footer>
          <Tooltip
            title={status === "1" ? "Enable the post" : "Disable the post"}
          >
            <Button
              className="catBt1 p-inherits"
              onClick={submitStatusChange}
              variant="contained"
            >
              <DoneIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Cancel">
            <Button
              className="catBt1 p-inherits"
              onClick={() => {
                setDisableModal(false);
              }}
              variant="contained"
            >
              <CloseIcon />
            </Button>
          </Tooltip>
        </Modal.Footer>
      </Modal>
      <Modal show={deleteModal}>
        <Modal.Body>Would you like to delete this post ?</Modal.Body>
        <Modal.Footer>
          <Tooltip title="Delete Post">
            <Button
              className="catBt1 p-inherits"
              onClick={deletePost}
              variant="contained"
            >
              <DoneIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Cancel">
            <Button
              className="catBt1 p-inherits"
              onClick={() => {
                setDeleteModal(false);
              }}
              variant="contained"
            >
              <CloseIcon />
            </Button>
          </Tooltip>
        </Modal.Footer>
      </Modal>

      <Modal show={showPoll}>
        <Modal.Body>
        <Paper sx={{ width: "100%", overflow: "hidden" }}  className="container">
        <TableContainer >
        <Table stickyHeader aria-label="sticky table">
        <TableHead>
                <TableRow>
                  {/* <StyledTableCell>S.no</StyledTableCell> */}
                  <StyledTableCell align="left">Choice1</StyledTableCell>
                  <StyledTableCell align="left">Choice2</StyledTableCell>
                  <StyledTableCell align="left">Choice3</StyledTableCell>
                  <StyledTableCell align="left">Choice4</StyledTableCell>
                  <StyledTableCell align="left">Correct answer</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {votes.map((elements)=>( */}
                    <TableRow>
                    <TableCell align="left">{votes.choice_1}</TableCell>
                    <TableCell align="left">{votes.choice_2}</TableCell>
                    <TableCell align="left">{votes.choice_3}</TableCell>
                    <TableCell align="left">{!votes.choice_4? <ClearSharpIcon/> : votes.choice_4}</TableCell>
                    <TableCell align="left">{votes.correctAnswer}</TableCell>
                    </TableRow>
                {/* ))} */}
              </TableBody>
        </Table>
        </TableContainer>
        </Paper>
        </Modal.Body>
        <Modal.Footer>
          <Tooltip title="Close">
            <Button
              className="catBt1 p-inherits"
              onClick={() => {
                setShowpoll(false);
              }}
              variant="contained"
            >
              <CloseIcon />
            </Button>
          </Tooltip>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default QuestionTable;



