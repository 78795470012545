module.exports = {
  // server:{
  //     link:"http://208.68.37.50:3700/admin/"
  // },

  // serverforOther:{
  //     link:"http://208.68.37.50:3700/"
  // },

  server:{
    link:"https://app.unionnews.net/admin/"
},

serverforOther:{
    link:"https://app.unionnews.net/"
},

  // serverforOther: { 
  //   link: "http://localhost:3700/",
  // },

  // server: {
  //   link: "http://localhost:3700/admin/",
  // },
};
