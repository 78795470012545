import React from 'react';
import './web.css';

function Webof() {
  return (
    <div className="App">
      <div className="post-container">
        <Header />
        <Image />
        <Description />
        <LikesComments />
      </div>
    </div>
  );
}

function Header() {
  return (
    <div className="header">
      <img src="https://images-eu.ssl-images-amazon.com/images/W/IMAGERENDERING_521856-T2/images/G/31/img22/PB/PC/Gateway/QC/Mixer-186x116._SY116_CB614658577_.jpg" alt="avatar" className="avatar" />
      <div className="username">instagram_user</div>
    </div>
  );
}

function Image() {
  return (
    <div className="image-container">
      <img src="https://wallpaperaccess.com/full/345330.jpg" alt="post" className="post-image" />
    </div>
  );
}

function Description() {
  return (
    <div className="description">
      This is the Instagram post description. It can be multiple lines long.
    </div>
  );
}

function LikesComments() {
  return (
    <div className="likes-comments">
      <div className="likes">100 likes</div>
      {/* <div className="comments">
        <div className="comment">Username: Comment</div>
        <div className="comment">Username: Comment</div>
        <div className="comment">Username: Comment</div>
      </div> */}
    </div>
  );
}

export default Webof;
