import React, { useEffect, useState, useRef } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { useNavigate } from "react-router-dom";
import axios from "axios";
// import Server from "../../server/Server";
import Server from "../server/Server";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "react-bootstrap/Modal";
import AddIcon from "@mui/icons-material/Add";
import Form from "react-bootstrap/Form";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import ReactLoading from "react-loading";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function Adminquestion() {
  const [questions, setUsers] = useState(undefined);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalDoc, setTotaldoc] = useState("");
  const token = localStorage.getItem("token");
  const [searchs, setSearch] = useState("");
  const history = useNavigate();
  const [add, setAdd] = useState(false);
  const [edit, setEdit] = useState(false);
  // const [enteredAminities, setEntered] = useState('');
  const [enteredQuestion, setEnteredQ] = useState("");
  const [option1, setOption1] = useState("");
  const [option2, setOption2] = useState("");
  const [option3, setOption3] = useState("");
  const [questionforedit, setQ] = useState("");
  const [optionforedit, setOptionE] = useState([]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event, newPage) => {
    console.log(event.target.value, "dataaa");
    if (event.target.value === "all") {
      toast.success(`Total number of Faq's : ${totalDoc}`);
      setRowsPerPage(totalDoc);
      setPage(0);
    } else {
      setRowsPerPage(+event.target.value);
      // setPage(0)
    }
  };
  useEffect(() => {
    const fetchDatas = async () => {
      console.log(page, "this is current page");
      const res = await axios.get(Server.server.link + "getQuestionsForEdit", {
        headers: {
          pageNumber: page,
          limit: rowsPerPage,
          search: searchs,
          "x-token": token,
        },
      });
      // console.log("this is current pagaDGFURLEIBCHFaCFKJIbnasdo:CFKLHasndfJnasdfKLJNe")
      if (res.data.code === 203) {
        localStorage.clear();
        history("/");
        toast.error(res.data.message);
      } else {
        setUsers(res.data.data);
        setTotaldoc(res.data.totalDocuments);
        console.log(res.data, "daaaaaaa");
      }
    };
    fetchDatas();
  }, [page, rowsPerPage]);

  const AddAmenitiesPopup = () => {
    setAdd(true);
  };

  const roleChangeHandler = (event) => {
    var a = event.target.value;
    if (a.trim().length === 0) {
      setEnteredQ(a.trim());
      console.log(a.trim(), "the value ", a.trim().length);
    } else {
      setEnteredQ(a);
      console.log(a, "value provided");
    }

    // console.log(event.target.value,"data question")
  };

  const roleChangeHandlers1 = (event) => {
    var a = event.target.value;
    if (a.trim().length === 0) {
      setOption1(a.trim());
      console.log(a.trim(), "the value 1", a.trim().length);
    } else {
      setOption1(a);
      console.log(a, "value 1 provided");
    }
    // (event.target.value);
    // console.log(event.target.value,"data answer")
  };

  const roleChangeHandlers2 = (event) => {
    var a = event.target.value;
    if (a.trim().length === 0) {
      setOption2(a.trim());
      console.log(a.trim(), "the value 2", a.trim().length);
    } else {
      setOption2(a);
      console.log(a, "value 2 provided ");
    }
    // (event.target.value);
    // console.log(event.target.value,"data answer")
  };

  const roleChangeHandlers3 = (event) => {
    var a = event.target.value;
    if (a.trim().length === 0) {
      setOption3(a.trim());
      console.log(a.trim(), "the value 3", a.trim().length);
    } else {
      setOption3(a);
      console.log(a, "value 3 provided ");
    }
  };

  const closecreate = () => {
    setAdd(false);
    // setIdView([])
    setOption3("");
    setOption1("");
    setOption2("");
    setEnteredQ("");
  };

  const addAmenitie = async () => {
    var array = [];
    if (option1 !== "") {
      array.push(option1);
    }

    if (option2 !== "") {
      array.push(option2);
    }

    if (option3 !== "") {
      array.push(option3);
    }

    console.log(array, "this is the array");
    // if(option1 !=="" || option2 =="" && option3===""){

    // }
    if (enteredQuestion === "") {
      toast.error("Please fill the question field whitspaces are not allowed");
    } else if (option1 === "" && option2 === "" && option3 === "") {
      toast.error(
        "Please provide minimum two options whitspaces are not allowed"
      );
    } else if(option1===option2 || option1===option3 || option2===option1){
      toast.error(
        "Please provide minimum two options whitspaces are not allowed"
      );
    }
    else if (array.length < 2) {
      toast.error(
        "Please provide minimum two options whitspaces are not allowed"
      );
    } else {
      axios
        .post(
          Server.server.link + "addQuestionsFromCategory",
          { question: enteredQuestion, options: array },
          { headers: { "x-token": token } }
        )
        .then(async (respo) => {
          if (respo.data.code === 203) {
            toast.error(respo.data.message);
            history("/");
          } else {
            toast.success(respo.data.message);
            setAdd(false);
            setOption3("");
            setOption1("");
            setOption2("");
            setEnteredQ("");
            const res = await axios.get(
              Server.server.link + "getQuestionsForEdit",
              {
                headers: {
                  pageNumber: page,
                  limit: rowsPerPage,
                  "x-token": token,
                  search: searchs,
                },
              }
            );
            if (res.data.code === 203) {
              localStorage.clear();
              history("/");
              toast.error(res.data.message);
            } else {
              setUsers(res.data.data);
              setTotaldoc(res.data.totalDocuments);
              history("/home/adminquestions");
            }
          }
        });
    }
  };

  let a = 1;

  const dataChange = (id) => {
    //adminqid
    setEdit(true);
    try {
      axios
        .get(Server.server.link + "getOneQuestionAdmin", {
          headers: { adminqid: id, "x-token": token },
        })
        .then((res) => {
          if (res.data.code === 203) {
            localStorage.clear();
            history("/");
            toast.error(res.data.message);
          } else if (res.data.code === 201) {
            toast.error(res.data.message);
          } else {
            setQ(res.data.results.question);
            setOptionE(res.data.results.options);
            console.log(questionforedit, "hey q");
          }
          // console.log("this is the response",res)
        });
    } catch (e) {
      toast.error("Something Went Wrong");
    }
    //  console.log("ayasbd",id)
  };
  return (
    <>
      <Stack direction="row">
        <TextField
          label="Search"
          name="Search"
          value={searchs}
          placeholder="Search With question or answer"
        />
        {/* onChange={searType} */}
      </Stack>
      <Stack direction="row">
        <Button className="AddAmenities" onClick={AddAmenitiesPopup}>
          {" "}
          <AddIcon /> Add Questions
        </Button>
      </Stack>
      {!questions ? (
        <div className="hey">
          <ReactLoading
            type={"spinningBubbles"}
            color={"rgb(9 167 167)"}
            height={100}
            width={100}
          />
        </div>
      ) : (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>S.no</StyledTableCell>
                  <StyledTableCell align="left">Question</StyledTableCell>
                  <StyledTableCell align="left" className="tablealignto">
                    Options
                  </StyledTableCell>
                  {/* <StyledTableCell align=""></StyledTableCell>
            <StyledTableCell align=""></StyledTableCell> */}
                  <StyledTableCell align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {questions.map((row) => (
                  <TableRow>
                    <TableCell align="left">{a++}</TableCell>
                    <TableCell align="left">
                      {row.question} <Button>we</Button>
                    </TableCell>
                    {/* <TableCell align="left"> */}
                    <TableCell align="left">
                      {row.options.map((rows) => (
                        <ul>
                          <li> {rows.option}</li>
                        </ul>

                        // <TableCell align="left">

                        // <div className='editdelete'>
                        // <Button onClick={()=>{dataChange({data:{qid:row._id,opId:rows._id,option:rows.option}})}}><DriveFileRenameOutlineIcon/></Button>
                        // <Button>Delete</Button>
                        // </div>

                        // </TableCell>
                      ))}
                    </TableCell>

                    {/* </TableCell> */}
                    {/* <TableCell align="left">
                      {row.option2}
                      </TableCell>

                      <TableCell align="left">
                      {row.option3}
                      </TableCell> */}

                    <TableCell align="center">
                      <Button
                        className="userview"
                        onClick={() => {
                          dataChange(row._id);
                        }}
                      >
                        {" "}
                        <EditRoundedIcon />
                      </Button>
                      <Button className="adminblockicon">
                        <DeleteRoundedIcon />
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={
              totalDoc < 5
                ? [totalDoc, 5, 10, 25, 100]
                : totalDoc === 5
                ? [5, 10, 25, 100]
                : totalDoc < 10 && totalDoc > 5
                ? [5, totalDoc, 10, 25, 100]
                : totalDoc === 10
                ? [5, 10, 25, 100]
                : totalDoc < 25 && totalDoc > 10
                ? [5, 10, totalDoc, 25, 100]
                : totalDoc === 25
                ? [5, 10, 25, 100]
                : totalDoc < 100 && totalDoc > 25
                ? [5, 10, 25, totalDoc, 100]
                : totalDoc === 100
                ? [5, 10, 25, 100]
                : [5, 10, 25, 100, totalDoc]
            }
            // rowsPerPageOptions={[5,10, 25, 100,totalDoc]}
            component="div"
            count={totalDoc}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      )}

      <Modal show={add}>
        <Modal.Header>
          <Modal.Title>Create New Admin Question</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {" "}
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Question</Form.Label>
              <Form.Control
                type="text"
                style={{ marginBottom: "10px" }}
                placeholder="question is required"
                onChange={roleChangeHandler}
                required
              />
              <Form.Label>Options</Form.Label>
              <Form.Control
                type="text"
                onChange={roleChangeHandlers1}
                required
              />
              <Form.Control
                type="text"
                onChange={roleChangeHandlers2}
                required
              />
              <Form.Control
                type="text"
                onChange={roleChangeHandlers3}
                required
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="catBt1 p-inherit"
            onClick={addAmenitie}
            variant="contained"
          >
            <AddIcon />
          </Button>
          <br />
          <Button
            className="catBt1 p-inherit"
            onClick={closecreate}
            variant="contained"
          >
            <CloseIcon />
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={edit}>
        <Modal.Header>
          <Modal.Title>Edit Admin Question</Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <Modal.Body>
            <Form>
              <Form.Group
                className="mb-3"
                controlId="exampleForm.ControlInput1"
              >
                <Form.Label>Question</Form.Label>
                <Form.Control
                  type="text"
                  style={{ marginBottom: "10px" }}
                  placeholder="question is required"
                  onChange={roleChangeHandler}
                  required
                />
                <Form.Label>Options</Form.Label>
                <Form.Control
                  type="text"
                  onChange={roleChangeHandlers1}
                  required
                />
                <Button>Edit</Button>
                <Button>Dele</Button>
                <Form.Control
                  type="text"
                  onChange={roleChangeHandlers2}
                  required
                />
                <Form.Control
                  type="text"
                  onChange={roleChangeHandlers3}
                  required
                />
              </Form.Group>
            </Form>
          </Modal.Body>
        </Modal.Footer>
        <Button
          className="catBt1 p-inherit"
          onClick={() => {
            setEdit(false);
          }}
          variant="contained"
        >
          <CloseIcon />
        </Button>
      </Modal>
    </>
  );
}

export default Adminquestion;
