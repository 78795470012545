import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import Server from '../server/Server';
import { toast } from "react-toastify";
import ReactLoading from "react-loading";
import { useTheme } from "@mui/material/styles";
import { Box, ButtonGroup } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { Tooltip } from "@mui/material";
import { Modal } from "react-bootstrap";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import ImageIcon from "@mui/icons-material/Image";
import DoneIcon from "@mui/icons-material/Done";
import ImageNotSupportedIcon from "@mui/icons-material/ImageNotSupported";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import CloseIcon from "@mui/icons-material/Close";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

function PostReport() {
  const history = useNavigate();
  const { id } = useParams();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalDocs, setTotaldoc] = useState(0);
  const token = localStorage.getItem("token");
  const [posts, setPosts] = useState(undefined);
  const [disableModal, setDisableModal] = useState(false);
  const [diableDeleteId, setDisbaleIdDeleteId] = useState("");
  const [status, setStatus] = useState("");
  const [deleteModal, setDeleteModal] = useState(false);
  const deleteId = (id) => {
    setDeleteModal(true);
    setDisbaleIdDeleteId(id);
  };

  useEffect(()=>{
    fetchDatas()
  },[page, rowsPerPage])
  const fetchDatas = async () => {
    const res = await axios.get(Server.server.link + "reportTable", {
      params: {
        skip: page,
        limit: rowsPerPage,
        postId:id
      },
      headers: {
        "x-token": token,
      },
    });
    if (res.data.code === 203) {
      localStorage.clear();
      history("/");
      toast.error(res.data.message);
    } else {
      setPosts(res.data.data);
      setTotaldoc(res.data.totalDoc);
    }
  }
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event, newPage) => {
    if (event.target.value === "all") {
      toast.success(`Total Amenties : ${totalDocs}`);
      setRowsPerPage(totalDocs);
      setPage(0);
    } else {
      setRowsPerPage(+event.target.value);
      setPage(0);
    }
  };

  const submitStatusChange = async () => {
    await axios
      .post(
        Server.server.link + "disbalePost",
        {},
        {
          params: {
            postId: diableDeleteId,
          },
          headers: {
            "x-token": token,
          },
        }
      )
      .then((res) => {
        if (res.data.code === 201) {
          toast.error(res.data.message);
        } else if (res.data.code === 203) {
          history("/");
          toast.error(res.data.message);
          localStorage.clear();
        } else if (res.data.code === 200) {
          setDisableModal(false);
          setDisbaleIdDeleteId("");
          toast.success(res.data.message);
          fetchDatas();
        }
      });
  };

  const deletePost = async () => {
    await axios
      .post(
        Server.server.link + "deletePost",
        {},
        {
          params: {
            postId: diableDeleteId,
          },
          headers: {
            "x-token": token,
          },
        }
      )
      .then((res) => {
        if (res.data.code === 201) {
          toast.error(res.data.message);
        } else if (res.data.code === 203) {
          history("/");
          toast.error(res.data.message);
          localStorage.clear();
        } else if (res.data.code === 200) {
          setDeleteModal(false);
          setDisbaleIdDeleteId("");
          toast.success(res.data.message);
          fetchDatas();
        }
      });
  };

  const disableId = (id, status) => {
    setDisableModal(true);
    setDisbaleIdDeleteId(id);
    setStatus(status);
  };

  let a=1
  return (
    <>
      {!posts ? (
        <div className="hey">
          <ReactLoading
            type={"spokes"}
            color={"rgb(9 167 167)"}
            height={50}
            width={50}
          />
        </div>
      ) : (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 440 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <StyledTableCell>S.no</StyledTableCell>
                  <StyledTableCell align="left">Reported User</StyledTableCell>
                  <StyledTableCell align="left">Feed Title</StyledTableCell>
                  <StyledTableCell align="left">Reason</StyledTableCell>
                  <StyledTableCell align="center">Action</StyledTableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {posts.map((row) => (
                  <TableRow>
                    <TableCell align="left">{a++}</TableCell>
                    <TableCell align="left">{row.reportedUser}</TableCell>
                    <TableCell align="left">{row.postTitle}</TableCell>
                    <TableCell align="left">{row.reason}</TableCell>
                    <TableCell align="center">
                      <ButtonGroup
                        variant="contained"
                        aria-label="outlined primary button group"
                      >
                        {row.expireStatus === "0" ? (
                          <Tooltip title="Disable the post">
                            <Button
                              className="userview"
                              onClick={() =>
                                disableId(row.postId, row.expireStatus)
                              }
                            >
                              {" "}
                              <ImageIcon />
                            </Button>
                          </Tooltip>
                        ) : (
                          <Tooltip title="Enable the post">
                            <Button
                              className="userview"
                              onClick={() =>
                                disableId(row.postId, row.expireStatus)
                              }
                            >
                              {" "}
                              <ImageNotSupportedIcon />
                            </Button>
                          </Tooltip>
                        )}

                        <Tooltip title="Delete the post">
                          <Button
                            className="adminblockicon"
                            onClick={() => deleteId(row.postId)}
                          >
                            <DeleteRoundedIcon />
                          </Button>
                        </Tooltip>
                      </ButtonGroup>
                    </TableCell> 
                   
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={
              totalDocs < 5
                ? [totalDocs, 5, 10, 25, 100]
                : totalDocs === 5
                ? [5, 10, 25, 100]
                : totalDocs < 10 && totalDocs > 5
                ? [5, totalDocs, 10, 25, 100]
                : totalDocs === 10
                ? [5, 10, 25, 100]
                : totalDocs < 25 && totalDocs > 10
                ? [5, 10, totalDocs, 25, 100]
                : totalDocs === 25
                ? [5, 10, 25, 100]
                : totalDocs < 100 && totalDocs > 25
                ? [5, 10, 25, totalDocs, 100]
                : totalDocs === 100
                ? [5, 10, 25, 100]
                : [5, 10, 25, 100, totalDocs]
            }
            component="div"
            count={totalDocs}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        
        </Paper>
      )}
       <Button onClick={() => history(-1)}>Go back</Button>
      <Modal show={disableModal}>
        <Modal.Body>
          {status === "1"
            ? "Would you like to enable the post ?"
            : "Would you like to disable the post ?"}
        </Modal.Body>
        <Modal.Footer>
          <Tooltip
            title={status === "1" ? "Enable the post" : "Disable the post"}
          >
            <Button
              className="catBt1 p-inherits"
              onClick={submitStatusChange}
              variant="contained"
            >
              <DoneIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Cancel">
            <Button
              className="catBt1 p-inherits"
              onClick={() => {
                setDisableModal(false);
              }}
              variant="contained"
            >
              <CloseIcon />
            </Button>
          </Tooltip>
        </Modal.Footer>
      </Modal>
      <Modal show={deleteModal}>
        <Modal.Body>Would you like to delete this post ?</Modal.Body>
        <Modal.Footer>
          <Tooltip title="Delete Post">
            <Button
              className="catBt1 p-inherits"
              onClick={deletePost}
              variant="contained"
            >
              <DoneIcon />
            </Button>
          </Tooltip>
          <Tooltip title="Cancel">
            <Button
              className="catBt1 p-inherits"
              onClick={() => {
                setDeleteModal(false);
              }}
              variant="contained"
            >
              <CloseIcon />
            </Button>
          </Tooltip>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default PostReport