import { React, useState, useRef } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useNavigate, NavLink } from "react-router-dom";

import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Server from "../server/Server";
import LoginIcon from "@mui/icons-material/Login";
import Button from "react-bootstrap/Button";
import { Row ,Col} from "react-bootstrap";
function Forgot() {
  let ref = useRef();
  const history = useNavigate();
  const [user, setUser] = useState({ email: "", password: "" });
  let name, value;

  const handaleinput = (e) => {
    name = e.target.name;
    value = e.target.value;
    console.log(value, "name");
    setUser({ ...user, [name]: value });
  };

  const clear = async () => {
    setUser({});
    history("/");
  };

  const postdata = async (e) => {
    e.preventDefault();
    var response = await axios
      .post(Server.server.link + "forgot", user)
      .then(async (response) => {
        console.log(response.data);
        if (response.data.code === 201) {
          toast.error(response.data.message);
        } else if (response.data.code === 202) {
          toast.error(response.data.message);
        } else {
          toast.success(response.data.message);
          history("/");
        }
      });
    console.log(response, "response");
  };
  return (
    <>
      <Card variant="outlined" className="loginaa">
        <div>
          <h2 className="appName">Forgot Password</h2>{" "}
          <TextField
            id="outlined-basic"
            name="email"
            className="inputfd"
            label="Email"
            variant="outlined"
            value={user.email}
            onChange={handaleinput}
          />
          <br />
          <Row>
            <Col ></Col>
            <Col sm={3} style={{width: "auto",
    "maxWidth": "30% !important"}}>
            <Button variant="outline-primary" onClick={postdata}>
            Submit
          </Button>
            </Col>
            <Col sm>
           
          <Button
            variant="outline-primary"
            className="logindirect"
            onClick={clear}
          >
            Login
          </Button>
            </Col>
            <Col></Col>
          </Row>
        </div>
      </Card>
    </>
  );
}

export default Forgot;
