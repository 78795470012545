import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Button, Card, Progress, Icon } from "semantic-ui-react";
import Chart from "react-apexcharts";
import { Tooltip } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";
// import Moment from "react-moment";

function UserCard(props) {
  const history = useNavigate();
  const userManagement = () => {
    history("/home/userlist");
  };
  // const [lastMonth,setLastMonth]=useState("")
  var now = moment().valueOf();
  var lastMonth = moment(props.body.lastMonthDate).format("MMMM");
  var thisMonth = moment(now).format("MMMM");
  console.log(props, "bgggg");
  // if(props.body.lastMonthDate){
  // setLastMonth(moment(props.body.lastMonthDate).format('MMMM'))
  // }
  // const moment=Moment;
  // const [users,setUsers] = useState([])
  // setUsers(props)
  console.log(moment(props.body.lastMonthDate).format("MMMM"), "hello...");
  console.log(props, "this is the props");
  return (
    <>
      <Card className="user_count_card" onClick={userManagement}>
        <Card.Content className="user_card_contents">
          <Row>
            <Col sm></Col>
            <Col></Col>
            <Col sm>
              <Icon
                className="usermodule_icon"
                style={{ marginLeft: "20px" }}
                name="users"
              />
            </Col>
          </Row>

          <Card.Header style={{ color: "#ffff" }}>User module</Card.Header>
          <Card.Meta
            style={{
              color: "#ffff",
              width: "auto",
              maxWidth: "245px",
              marginLeft: "13px",
            }}
          >
            Registered users percentage according to last month
          </Card.Meta>
          <Card.Description>
            <Row>
              <Col md={8}>
                <Progress percent={props.body.percentageGrowth} indicating />
              </Col>
              <Col md={4}>
                <h3
                  style={{
                    fontSize: "16px",
                    fontFamily: "fantasy",
                    fontWeight: "300",
                    color: "#ffff",
                  }}
                >
                  {props.body.percentageGrowth}
                </h3>
              </Col>
            </Row>

            <Row>
              <Col md={8}>
                <span className="usersCount">total users</span>
              </Col>

              <Col md={4}>
                <span className="usersCount">{props.body.users}</span>{" "}
              </Col>
              <Col md={8}>
                <span className="usersCounts">{thisMonth}</span>
              </Col>
              {/* {props.body.map((data)=>( */}
              <Col md={4}>
                <span className="usersCounts">{props.body.thisMonth}</span>{" "}
              </Col>
              {/* ))} */}
              <Col md={8}>
                <span className="usersCounts">{lastMonth}</span>
              </Col>
              {/* {props.body.map((data)=>( */}
              <Col md={4}>
                <span className="usersCounts">{props.body.lastMonth}</span>{" "}
              </Col>
              {/* ))} */}
            </Row>
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          {/* <div className="ui two buttons"> */}
          <button class="button-87" role="button" onClick={userManagement}>
            User Management
          </button>
          {/* </div> */}
        </Card.Content>
      </Card>
    </>
  );
}

export default UserCard;
