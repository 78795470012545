import React, { useEffect, useState } from 'react'
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Server from '../server/Server';
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';
import InfiniteScroll from 'react-infinite-scroll-component';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
  }));
  
  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
      {...props}
    />
  ))(({ theme }) => ({
    backgroundColor:
      theme.palette.mode === 'dark'
        ? 'rgba(255, 255, 255, .05)'
        : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  }));
  
  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
  }));

function FaqView() {
  
    const [open, setOpen] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const history = useNavigate();
    const [questions,setQuestions]= useState([])
    const [faq, setFaqs] = useState(undefined);
    const [totalDoc, setTotaldoc] = useState("");
    const [id,setId]= useState("")
    const [expanded, setExpanded] = React.useState(id);
    const [loadend,setLoadend] = useState(false)

    const handleChange = (panel,id) => (event, newExpanded) => {
      setExpanded(newExpanded ? panel : false);
      setId(id)
    };
    const data=true

    useEffect(()=>{
        fetchDatas()
    },[])

    const fetchDatas = async () => {
        console.log(page, "this is current page");
        const res = await axios.get(Server.server.link + "getFaqs", {
          headers: {
            skip: page,
            limit: rowsPerPage,
            search:""
          },
        });
  
        if (res.data.code === 203) {
          localStorage.clear();
          history("/");
          toast.error(res.data.message);
        } else {
          // toast.success(res.data.message)
          setFaqs(res.data.data);
        //   res.data.data.map((res)=>)
          setTotaldoc(res.data.totalDocuments);
          console.log(res.data.totalDocuments, "daaaaaaa");
          // setTotalPages(res.data.totalPages)
        }
      };

    const handleClick = (_id) =>(event, newExpanded) => {
        setId(_id)
    //   setOpen(!open);
      setExpanded(newExpanded ? _id : false);
    };

    const fetchMoreData = async () => {
        console.log(page+1,"jjjjjjjj")
        var a = page+1
        console.log(a,"jjjj")
        setPage(a)
        // setSkip(skip+1)
        const res = await axios.get(Server.server.link + "getFaqs", {
            headers: {
              skip: page,
              limit: rowsPerPage,
              search:""
            },
          });
    
          if (res.data.code === 203) {
            localStorage.clear();
            history("/");
            toast.error(res.data.message);
          } else {
            // toast.success(res.data.message)
            console.log(res.data.data.length,"//////////////")
            if(res.data.data.length<15){
                setLoadend(true)
            }
            var newArray=faq.concat(res.data.data)
            setFaqs(newArray);
          //   res.data.data.map((res)=>)
            setTotaldoc(res.data.totalDocuments);
            console.log(res.data, "daaaaaaa");
            // setTotalPages(res.data.totalPages)
          }
        }
  
    return (
        <>
        
         <List className="headerfaq"
        sx={{ width: '100%', bgcolor: '#f0eded' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">
            How can i help you ?
          </ListSubheader>
        }
      ></List>
        {
            faq ===undefined ?   <div className="hey">
            <ReactLoading
              type={"spokes"}
              color={"rgb(9 167 167)"}
              height={50}
              width={50}
            />
          </div> :
          (
            <InfiniteScroll
          dataLength={faq.length}
          next={fetchMoreData}
          hasMore={loadend ===false}
          loader={<>
          <ReactLoading
            type={"spokes"}
            color={"rgb(9 167 167)"}
            height={50}
            width={50}
          />

          </>}
        >
            {faq.map((data)=>(
                      <Accordion expanded={expanded===data._id} onChange={handleChange(data._id,data._id)}>
                      <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                        <Typography>{data.question}</Typography>
                      </AccordionSummary>
                      {
                        data._id === id ? (
                            <AccordionDetails>
                            <Typography>
                              {data.answer}
                            </Typography>
                          </AccordionDetails>
                        ) :""
                      }
                     
                    </Accordion>
            ))}
      
              {/* <List className='w-100'
            sx={{ width: '100%', bgcolor: '#f0eded' }}
            component="nav"
            aria-labelledby="nested-list-subheader"
          >
          {faq.map((data)=>(
            <>
             <ListItemButton className='questionButton' onClick={()=>handleClick(data._id)}>
              <ListItemIcon>
                <QuestionMarkIcon />
              </ListItemIcon>
              <ListItemText primary={data.question} />
              {open && data._id ===id ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            {
                data._id ===id ? (<Collapse  key={data._id}
                    in={open}
                    timeout='auto'
                    unmountOnExit >
                    <List component="div" disablePadding>
                    <ListItemButton sx={{ pl: 4 }}>
                        <ListItemIcon>
                        <DoneOutlineIcon />
                        </ListItemIcon>
                        <ListItemText primary={data.answer}/>
                    </ListItemButton>
                    </List>
                    </Collapse>) :""
            }
             
            </>
           
            
          ))}
            {/* {faq.map((data)=>(
 
            ))} */}
          
          {/* </List> */}
        </InfiniteScroll>
          
          )
    

        }
       

   
        </>
    );
}

export default FaqView