import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Button, Card, Progress, Icon } from "semantic-ui-react";
import Chart from "react-apexcharts";
import { Tooltip } from "@mui/material";
import moment from "moment";
import PersonOffIcon from "@mui/icons-material/PersonOff";
import { useNavigate } from "react-router-dom";

function PostCard(props) {
  const history = useNavigate();
  console.log(props, "bgggg");
  const postsCount = () => {
    history("/home/posts");
  };
  return (
    <>
      <Card className="user_count_card" onClick={postsCount}>
        <Card.Content className="post_card_contents">
          <Row>
            <Col sm></Col>
            <Col></Col>
            <Col sm>
              <Icon
                className="post_icon"
                style={{ marginLeft: "20px" }}
                name="images"
              />
            </Col>
          </Row>

          <Card.Header style={{ color: "#fff" }}>Post Module</Card.Header>
          <Card.Meta
            style={{
              color: "#ffff",
              width: "auto",
              maxWidth: "245px",
              marginLeft: "13px",
            }}
          >
            Total percentage of users who created posts
          </Card.Meta>
          <Card.Description>
            <Row>
              <Col md={9}>
                <Progress percent={props.body.postCreation} indicating />
              </Col>
              <Col md={3}>
                <h3
                  style={{
                    fontSize: "16px",
                    fontFamily: "fantasy",
                    fontWeight: "300",
                    color: "#fff",
                  }}
                >
                  {props.body.postCreation} %
                </h3>
              </Col>
            </Row>
            <Row className="postCard">
              <Col md={8}>
                <span className="reportedCount">total post</span>
              </Col>

              <Col md={4}>
                <span className="reportedCount">{props.body.totalPosts}</span>{" "}
              </Col>
              {/* <Col md={8}>
                  <span className="usersCounts">Post count</span>
                </Col>

                  <Col md={4}>
                  <span className="usersCounts">10</span>{" "}
                </Col> */}
              <Col md={8}>
                <span className="usersCounts">Poll count</span>
              </Col>

              <Col md={4}>
                <span className="usersCounts">{props.body.pollscount}</span>{" "}
              </Col>
              <Col md={8}>
                <span className="usersCounts">Question count</span>
              </Col>

              <Col md={4}>
                <span className="usersCounts">{props.body.questionCount}</span>{" "}
              </Col>
              {/* <Col md={8}>
                  <span className="usersCounts">test</span>
                </Col>

                  <Col md={4}>
                  <span className="usersCounts">12</span>{" "}
                </Col>

                <Col md={8}>
                  <span className="usersCounts">test</span>
                </Col>

                  <Col md={4}>
                  <span className="usersCounts">test</span>{" "}
                </Col> */}
            </Row>
          </Card.Description>
        </Card.Content>
        <Card.Content extra>
          {/* <div className="ui two buttons"> */}
          <button class="button-87" onClick={postsCount} role="button">
            User Posts
          </button>
          {/* </div> */}
        </Card.Content>
      </Card>
    </>
  );
}

export default PostCard;
